import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import messageSound from '../assets/sound/message.mp3';
import notificationSound from '../assets/sound/notification.mp3';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useSelector } from 'react-redux';
import notificationApi from '../services/notificationApi';
import chatApi from '../services/chatApi';
import { ROLES } from '../constaints/role';
const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_FIRST = process.env.REACT_APP_BASE_URL_FIRST;

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const currentUser = useSelector((state) => state.auth.login?.currentUser);

  const [listNotification, setListNotification] = useState([]);
  const [connectionFirst, setConnectionFirst] = useState(null);
  const [connection, setConnection] = useState(null);
  const [isConnect, setIsConnect] = useState(false);
  const [isConnectFirst, setIsConnectFirst] = useState(false);
  const [numberOfNotification, setNumberOfNotification] = useState(0);

  const [chatSelect, setChatSelect] = useState(-1);
  const chatSelectRef = useRef(chatSelect);

  const [listMessages, setListMessage] = useState([]);
  const [userConnection, setUserConnection] = useState([]);
  const [numberOfMessage, setNumberOfMessage] = useState(0);
  const [haveMess, setHaveMessage] = useState(0);
  const [lastDate, setLastDate] = useState('0');
  const lastDateRef = useRef(lastDate);

  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    // Lắng nghe sự kiện đầu tiên từ người dùng
    const handleUserInteraction = () => {
      setUserInteracted(true);
    };
    document.addEventListener('click', handleUserInteraction, { once: true });
    return () => {
      document.removeEventListener('click', handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        if (
          !isConnectFirst &&
          currentUser !== null &&
          connectionFirst === null &&
          currentUser?.role !== ROLES.ADMIN
        ) {
          const connectionFirst = new HubConnectionBuilder()
            .withUrl(`${BASE_URL_FIRST}/chat`)
            .configureLogging(LogLevel.Information)
            .build();
          if (connectionFirst != null) {
            connectionFirst.on('ReceivedMessage', (data) => {
              if (
                parseInt(chatSelectRef?.current) ===
                parseInt(data?.conversationId)
              ) {
                setListMessage((msg) => [...msg, data]);
                setHaveMessage((prevNumber) => prevNumber + 1);
              }
            });

            connectionFirst.on('HaveMessage', (data) => {
              setHaveMessage((prevNumber) => prevNumber + 1);
            });
          }
          connectionFirst.onclose((e) => {
            setConnectionFirst(null);
            setIsConnectFirst(false);
            setListNotification([]);
          });
          await connectionFirst.start();
          await connectionFirst.invoke(
            'SaveUserConnection',
            currentUser?.userId
          );
          setConnectionFirst(connectionFirst);
          setIsConnectFirst(true);
        }
      } catch (e) {}
    };
    getData();
  }, [currentUser, isConnectFirst]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!isConnect && currentUser !== null) {
          const connection = new HubConnectionBuilder()
            .withUrl(`${BASE_URL}/chat`)
            .configureLogging(LogLevel.Information)
            .build();

          connection.onclose((e) => {
            setConnection(null);
            setIsConnect(false);
            setListNotification([]);
          });

          await connection.start();
          await connection.invoke('SaveUserConnection', currentUser?.userId);
          setConnection(connection);
          setIsConnect(true);
        }
      } catch (e) {
        setTimeout(() => {
          getData();
        }, 3000);
      }
    };
    getData();
    return () => {
      if (connection) {
        connection.stop().then(() => {
          setIsConnect(false);
          setConnection(null);
        });
      }
    };
  }, [currentUser, isConnect]);

  useEffect(() => {
    if (isConnect === true && connection !== null && currentUser !== null) {
      connection.on('ReceivedNotification', (data) => {
        setListNotification((prevNotifications) => [
          data,
          ...prevNotifications,
        ]);
        setNumberOfNotification((prevNumber) => prevNumber + 1);
        try {
          if (userInteracted === true) {
            const sound = new Audio(notificationSound);
            sound.play();
          }
        } catch (error) {}
      });

      connection.on('ReceivedMessage', (data) => {
        if (
          parseInt(chatSelectRef?.current) === parseInt(data?.conversationId)
        ) {
          setListMessage((msg) => [...msg, data]);
        }
        setNumberOfMessage((prevNumber) => prevNumber + 1);
        setHaveMessage((prevNumber) => prevNumber + 1);
        try {
          if (userInteracted === true) {
            const sound = new Audio(messageSound);
            sound.play();
          }
        } catch (error) {}
      });
      connection.on('HaveMessage', (data) => {
        setHaveMessage((prevNumber) => prevNumber + 1);
      });
    }
  }, [isConnect]);

  useEffect(() => {
    chatSelectRef.current = chatSelect;
  }, [chatSelect]);

  useEffect(() => {
    lastDateRef.current = lastDate;
  }, [lastDate]);

  // useEffect(() => {
  //   const getNotification = async () => {
  //     if (currentUser != null) {
  //       try {
  //         let res = await notificationApi.GetAllNotification(
  //           currentUser?.userId
  //         );
  //         setListNotification(res);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }
  //   };
  //   getNotification();
  // }, [currentUser]);

  useEffect(() => {
    const GetNumberMessage = async () => {
      if (currentUser != null) {
        try {
          let res = await chatApi.GetNumberMessage(currentUser?.userId);
          setNumberOfMessage(res);
        } catch (error) {
          console.error(error);
        }
      }
    };
    GetNumberMessage();
  }, [currentUser, haveMess]);

  useEffect(() => {
    const getNotification = async () => {
      if (currentUser != null) {
        try {
          let res = await notificationApi.NumberNotification(
            currentUser?.userId
          );
          setNumberOfNotification(res);
        } catch (error) {
          console.error(error);
        }
      }
    };
    getNotification();
  }, []);

  useEffect(() => {
    const getUserConnect = async () => {
      if (currentUser != null) {
        try {
          let res = await chatApi.GetUserConnect();
          setUserConnection(res);
        } catch (error) {
          console.error(error);
        }
      }
    };
    getUserConnect();
  }, [currentUser, haveMess]);

  useEffect(() => {
    const getListMessages = async () => {
      if (currentUser !== null && chatSelect !== -1) {
        setLastDate('0');
        try {
          let res = await chatApi.GetMessageByConversation(chatSelect, '0');
          setLastDate(res?.nextCursor);
          setListMessage(res?.items?.reverse());
        } catch (err) {}
      }
    };
    getListMessages();
  }, [chatSelect, currentUser]);

  const loadMoreMessages = async () => {
    if (
      currentUser !== null &&
      chatSelectRef !== -1 &&
      parseInt(chatSelectRef?.current) === parseInt(chatSelect) &&
      lastDateRef !== '0'
    ) {
      try {
        let res = await chatApi.GetMessageByConversation(chatSelect, lastDate);
        setLastDate(res?.nextCursor);
        res?.items.map((item) => setListMessage((msg) => [item, ...msg]));
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <ChatContext.Provider
      value={{
        listNotification,
        setListNotification,
        connection,
        connectionFirst,
        setConnectionFirst,
        setConnection,
        isConnect,
        setIsConnect,
        setIsConnectFirst,
        numberOfNotification,
        setNumberOfNotification,
        chatSelect,
        setChatSelect,
        listMessages,
        setListMessage,
        userConnection,
        setUserConnection,
        numberOfMessage,
        setNumberOfMessage,
        loadMoreMessages,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const UseChatState = () => {
  return useContext(ChatContext);
};

export default ChatProvider;
