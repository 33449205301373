import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  Avatar,
  Grid,
} from '@mui/material';
import { teal } from '@mui/material/colors';
import { styled } from '@mui/system';
import userApi from '../../../services/userApi';
import CustomAvatar from '../../../components/CustomAvatar';

const StyledCard = styled(Card)({
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  borderRadius: '15px',
  marginBottom: '20px',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const LockedList = () => {
  const [lockedList, setLockedList] = useState([]);

  useEffect(() => {
    const getLockedList = async () => {
      try {
        const lockedListResponse = await userApi.GetLockedUsers();
        setLockedList(lockedListResponse);
      } catch (error) {
        console.error(error);
      }
    };
    getLockedList();
  }, []);
  const lockedAccounts = [
    { username: 'johndoe', email: 'johndoe@example.com' },
    { username: 'janedoe', email: 'janedoe@example.com' },
    { username: 'johndoe', email: 'johndoe@example.com' },
    { username: 'janedoe', email: 'janedoe@example.com' },
    { username: 'johndoe', email: 'johndoe@example.com' },
    { username: 'janedoe', email: 'janedoe@example.com' },
    { username: 'johndoe', email: 'johndoe@example.com' },
    { username: 'janedoe', email: 'janedoe@example.com' },
    { username: 'johndoe', email: 'johndoe@example.com' },
    { username: 'janedoe', email: 'janedoe@example.com' },
    { username: 'johndoe', email: 'johndoe@example.com' },
    { username: 'janedoe', email: 'janedoe@example.com' },
    { username: 'johndoe', email: 'johndoe@example.com' },
    { username: 'janedoe', email: 'janedoe@example.com' },
    { username: 'johndoe', email: 'johndoe@example.com' },
    { username: 'janedoe', email: 'janedoe@example.com' },
    { username: 'johndoe', email: 'johndoe@example.com' },
    { username: 'janedoe', email: 'janedoe@example.com' },
    { username: 'johndoe', email: 'johndoe@example.com' },
    { username: 'janedoe', email: 'janedoe@example.com' },
    { username: 'johndoe', email: 'johndoe@example.com' },
    { username: 'janedoe', email: 'janedoe@example.com' },
    { username: 'johndoe', email: 'johndoe@example.com' },
    { username: 'janedoe', email: 'janedoe@example.com' },
    { username: 'johndoe', email: 'johndoe@example.com' },
    { username: 'janedoe', email: 'janedoe@example.com' },
  ];

  return (
    <Container maxWidth="md" sx={{ py: 10 }}>
      <Typography
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          mb: 6,
          fontSize: '24px',
        }}
      >
        Tài khoản bị khoá
      </Typography>
      <Typography>
        Đây là danh sách những tài khoản bị khoá do vi phạm điều khoản của chúng
        tôi. Hãy cẩn trọng những tài khoản có tên hoặc email gần giống để tránh
        bị lừa đảo.
      </Typography>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        {lockedList.map((account, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <StyledCard>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                {account?.avatar === null ? (
                  <CustomAvatar name={account?.name} size={50} />
                ) : (
                  <Avatar
                    alt="Profile Picture"
                    src={account?.avatar}
                    sx={{ width: 50, height: 50 }}
                  />
                )}
                <div className="ml-4">
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: '600' }}
                  >
                    {account.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {account.email}
                  </Typography>
                </div>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default LockedList;
