import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Rating,
  Typography,
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import categoryApi from '../../../services/categoryApi';

import HeroSection from '../../../components/HeroSection';
import { truncateText } from '../../../utils/truncateText';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TypographyTitle from '../../../components/Typography/TypographyTitle';
import ListBlog from '../../Admin/Blog/Publish/ListBlog';
import LoadingComponent from '../../../components/LoadingComponent';
import blogApi from '../../../services/blogApi';
import Color from 'color';
import FeaturesSection from '../../../components/FeaturesSection';
import LogoText from '../../../assets/Logo_Text_GJ.png';
import Banner from '../../../assets/7.png';
import projectApi from '../../../services/projectApi';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import profileApi from '../../../services/profileApi';
import FULogo from '../../../assets/Logo_Trường_Đại_học_FPT.png';
import ProjectDescription from '../../../components/ProjectDescription';
import { formatCurrency } from '../../../utils/formatCurrency';

const Home = () => {
  const [listCategory, setListCategory] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation('home');
  const currentUser = useSelector((state) => state.auth.login?.currentUser);
  const [listBlogHomePage, setListBlogHomePage] = useState([]);
  const [projectsHomePage, setProjectsHomePage] = useState([]);
  const [suggestedProjects, setSuggestedProjects] = useState([]);
  const [userSkills, setUserSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const elementsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(
              entry.target.dataset.direction === 'left'
                ? 'fly-in-left'
                : 'fly-in-right'
            );
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    elementsRef.current.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const getCategory = async () => {
      try {
        setLoading(true);
        let params = {
          Top: 4,
          IsHomePage: true,
        };
        const res = await categoryApi.GetAllCategory();
        setListCategory(res);
        const blogResponse = await blogApi.Gets(params);
        setListBlogHomePage(blogResponse);
        const projectsResponse = await projectApi.GetProjectHomePage();
        setProjectsHomePage(projectsResponse);
        if (currentUser) {
          const suggestedProjectsResponse =
            await projectApi.SuggestProjectHomePage();
          setSuggestedProjects(suggestedProjectsResponse);
          const profileResponse = await profileApi.getUserProfile();
          setUserSkills(profileResponse.skills);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    getCategory();
  }, []);

  useEffect(() => {
    if (currentUser != null) {
      const role = currentUser?.role;
      if (role === 'Freelancer') {
        navigate('/');
      } else if (role === 'Recruiter') {
        navigate('/recruiter');
      }
    }
  }, [currentUser, navigate]);

  const handleClick = (id) => {
    navigate(`/category/${id}`);
  };

  const handleClickProject = (id) => {
    navigate(`/detail/${id}`);
  };

  const renderProjects = (projects, title) => (
    <Box mt={8} mb={8}>
      <Typography
        className="mb-8 text-3xl md:text-5xl font-medium tracking-tighter text-center md:text-left"
        sx={{ color: 'var(--text-color)' }}
      >
        {title}
      </Typography>
      <Grid container spacing={4} direction="column">
        {projects.map((project) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={project.id}>
            <Card
              onClick={() => handleClickProject(project.id)}
              className="shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-xl cursor-pointer"
              style={{ width: '80%', maxWidth: '100%', margin: '0 auto' }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className="text-yellow-600 font-medium text-xl"
                >
                  {truncateText(project.title, 50)}
                </Typography>
                <Box className="flex justify-between bg-slate-200 rounded-md p-2 mb-2">
                  <Box className="flex items-center">
                    <Typography
                      variant="h6"
                      className="text-gray-600 font-medium"
                    >
                      {project.city
                        ? `${project.city} | ${project.userName}`
                        : `${project.userName}`}{' '}
                    </Typography>
                    <Rating
                      sx={{
                        scale: '0.9',
                        height: 'inherit',
                      }}
                      name="read-only"
                      value={project?.avgStarOfUser}
                      readOnly
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="text-gray-600 font-medium"
                    >
                      {project.timeAgo}
                    </Typography>
                  </Box>
                </Box>
                <Box mb={2}>
                  {project?.skill.map((skill) => (
                    <Chip
                      key={skill}
                      label={<Typography fontSize={12}>{skill}</Typography>}
                      className="m-1"
                      color={userSkills.includes(skill) ? 'primary' : 'default'}
                      size="small"
                    />
                  ))}
                </Box>
                <Box mb={2}>
                  <Typography>
                    Thù lao: {formatCurrency(project.minBudget)} -{' '}
                    {formatCurrency(project.maxBudget)}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography>
                    {project.totalBids === 0
                      ? 'Chưa có ai tham gia đấu thầu'
                      : `${project.totalBids} người đang tham gia`}
                  </Typography>
                </Box>
                <ProjectDescription description={project.description} />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const backGroundColors = [
    '#00732e',
    '#ff6700',
    '#003912',
    '#4d1727',
    '#687200',
    '#421300',
    '#254200',
    '#8f2900',
    '#687200',
    '#00732e',
    '#be5272',
    '#795548',
  ];

  const lightenColor = (color, amount) => {
    return Color(color).lighten(amount).hex();
  };

  const lightenedColors = backGroundColors.map((color) =>
    lightenColor(color, 0.5)
  );

  const ViewCategory = (item, index) => {
    var backgroundColor = lightenedColors[index % lightenedColors.length];
    if (item?.id !== 4) {
      return (
        <div className="slider" key={index}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              onClick={() => handleClick(item?.id)}
              sx={{
                maxWidth: '100%',
                height: '300px',
                textDecoration: 'none',
                cursor: 'pointer',
                borderRadius: 4,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  transition: '0.3s',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                  background: `radial-gradient(73.44% 83.44% at 90% 70%,${backgroundColor} 0,#fff 100%), #fff`,
                },
                '&:focus': {
                  outline: 'none',
                  boxShadow: '0 0 0 4px rgba(0, 123, 255, 0.5)',
                },
                '&:active': {
                  transform: 'scale(0.95)',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <CardMedia
                sx={{ height: 200, scale: '0.9', borderRadius: 4 }}
                image={item?.image}
              />
              <CardContent sx={{ color: 'var(--text-color)', mt: 1 }}>
                <TypographyTitle title={item?.categoryName} />
              </CardContent>
            </Card>
          </Grid>
        </div>
      );
    }
  };
  return (
    <>
      <HeroSection />
      <Box className="max-width-container mt-3">
        {loading && <LoadingComponent loading={loading} />}

        <Box mb={3}>
          <Typography
            className="mb-8 text-3xl md:text-5xl font-medium tracking-tighter text-center md:text-left"
            sx={{ color: 'var(--text-color)' }}
          >
            {t('popular_category')}
          </Typography>
          <Carousel
            responsive={responsive}
            swipeable={true}
            draggable={true}
            partialVisible={false}
            slidesToSlide={4}
          >
            {listCategory?.map((item, index) => ViewCategory(item, index))}
          </Carousel>
        </Box>

        <Divider />

        <FeaturesSection />

        {renderProjects(projectsHomePage, t('new_projects'))}
        <Divider />
        <Typography className="mb-2 mt-8 text-xl md:text-5xl font-medium tracking-tighter text-center md:text-center">
          {t('partners')}
        </Typography>
        <Box mb={3} className="item-wrapper">
          <div className="item item-1">
            <CardMedia component="img" image={FULogo} alt="Paella dish" />
          </div>
          <div className="item item-2">
            <CardMedia component="img" image={FULogo} alt="Paella dish" />
          </div>
          <div className="item item-3">
            <CardMedia component="img" image={FULogo} alt="Paella dish" />
          </div>
          <div className="item item-4">
            <CardMedia component="img" image={FULogo} alt="Paella dish" />
          </div>
          <div className="item item-5">
            <CardMedia component="img" image={FULogo} alt="Paella dish" />
          </div>
          <div className="item item-6">
            <CardMedia component="img" image={FULogo} alt="Paella dish" />
          </div>
          <div className="item item-7">
            <CardMedia component="img" image={FULogo} alt="Paella dish" />
          </div>
          <div className="item item-8">
            <CardMedia component="img" image={FULogo} alt="Paella dish" />
          </div>
        </Box>
        <Divider />
        {currentUser &&
          suggestedProjects.length > 0 &&
          renderProjects(suggestedProjects, t('suggested_projects'))}

        <Box
          mt={3}
          className="rounded-xl p-4"
          sx={{ backgroundColor: '#fff6c1' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent="left">
                  <CardMedia
                    component="img"
                    image={LogoText}
                    alt="Logo"
                    className="w-full"
                    style={{ maxWidth: '150px' }}
                  />
                </Box>
                <Box
                  p={3}
                  ref={(el) => (elementsRef.current[2] = el)}
                  data-direction="left"
                >
                  <Typography
                    display="flex"
                    alignItems="center"
                    mb={2}
                    className="text-4xl"
                  >
                    {t('improve_income')}
                  </Typography>
                  <Typography display="flex" className="text-xl">
                    {t('improve_income_detail')}
                  </Typography>
                  <Button
                    variant="contained"
                    className="mt-6 bg-white text-red-900 hover:bg-gray-200"
                    size="large"
                    onClick={() => navigate('/payment')}
                  >
                    {t('explore_more')}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  p={3}
                  ref={(el) => (elementsRef.current[3] = el)}
                  data-direction="right"
                >
                  <CardMedia
                    className="rounded-3xl"
                    component="img"
                    height="350"
                    image={Banner}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box mt={3}>
          <Box mb={2}>
            <Button onClick={() => navigate('/blog')}>
              <Typography fontSize={20} fontWeight={600}>
                {t('blog_title')}
              </Typography>
            </Button>
          </Box>

          <ListBlog listBlog={listBlogHomePage} />
        </Box>
        {!currentUser && (
          <Box mt={5}>
            <Box
              sx={{ backgroundColor: '#fff6f2' }}
              className="w-full text-center p-12 rounded-xl shadow-lg"
            >
              <Typography
                variant="h2"
                component="h1"
                className="font-semibold mb-4 text-4xl md:text-5xl"
                sx={{ color: '#404145' }}
              >
                Works your <span className="special-font">way! </span>
                Every day
              </Typography>
              <Button
                variant="contained"
                className="mt-6 bg-white text-red-900 hover:bg-gray-200"
                size="large"
                onClick={() => navigate('/login')}
              >
                Đăng kí ngay
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Home;
