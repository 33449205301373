import React, { useState } from 'react';
import { v4 } from 'uuid';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { toast } from 'react-toastify';
import { Box, Button, TextField, Typography } from '@mui/material';
import LoadingComponent from '../../../../components/LoadingComponent';
import { imageDb } from '../../../../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import profileApi from '../../../../services/profileApi';
import { useTranslation } from 'react-i18next';

const AddAchievement = () => {
  const [title, setTitle] = useState('');
  const currentUser = useSelector((state) => state.auth.login?.currentUser);

  const [fileName, setFileName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('profile');

  const handleUpload = (e) => {
    if (e) {
      if (
        e.name.endsWith('.jpg') ||
        e.name.endsWith('.png') ||
        e.name.endsWith('.webp') ||
        e.name.endsWith('.jpeg')
      ) {
        setLoading(true);
        const imgRef = ref(imageDb, `file/${v4()}`);
        uploadBytes(imgRef, e)
          .then((value) => getDownloadURL(value.ref))
          .then((url) => {
            setFileName(url);
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            toast.error(`${t('upload_fail')}`);
          });
      } else {
      }
    } else {
      toast.error(`${t('upload_fail')}`);
    }
  };

  const handleSubmit = async () => {
    if (
      title.length === '' ||
      title.length > 200 ||
      description.length > 1000 ||
      description === '' ||
      fileName === ''
    ) {
      toast.error(`${t('save_error')}`);
      return;
    }
    try {
      let data = {
        userId: currentUser?.userId,
        fileName: fileName,
        description: description,
        title: title,
      };
      await profileApi.AddPortfolio(data, navigate);
      toast.success(`${t('add_success')}`);
    } catch (error) {
      toast.error(`${t('add_error')}`);
    }
  };

  return (
    <Box p={3} m={3} borderRadius={5} border="1px solid #ccc">
      {loading && <LoadingComponent loading={loading} />}
      <Typography sx={{ fontSize: '2em' }} gutterBottom>
        {t('add_achievement')}
      </Typography>
      <Box mt={2}>
        <Box
          display="flex"
          mt={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontSize="1.5em" variant="subtitle1">
            {t('title')}
          </Typography>
          <Typography fontSize="0.8em">200 {t('character')} </Typography>
        </Box>
        <TextField
          value={title}
          fullWidth
          onChange={(e) => setTitle(e.target.value)}
        />
        <Box
          display="flex"
          mt={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontSize="1.5em" variant="subtitle1">
            {t('description')}
          </Typography>
          <Typography fontSize="0.8em">1000 {t('character')}</Typography>
        </Box>
        <TextField
          value={description}
          multiline
          rows={4}
          fullWidth
          onChange={(e) => setDescription(e.target.value)}
        />
        <Box display="flex" mt={3} alignItems="center">
          <Typography fontSize="1.5em" variant="subtitle1" sx={{ mr: 2 }}>
            {t('image')}
          </Typography>
          <Button
            variant="contained"
            component="label"
            size="small"
            sx={{
              backgroundColor: '#1976d2', // Customize the button color
              color: '#fff', // Button text color
              textTransform: 'none', // Keep text in the button case as is
              fontSize: '1em', // Font size of the button text
              padding: '6px 12px', // Button padding
              '&:hover': {
                backgroundColor: '#115293', // Hover color
              },
            }}
          >
            {t('upload_image')}
            <input
              type="file"
              hidden
              onChange={(e) => handleUpload(e.target.files[0])}
            />
          </Button>
        </Box>

        <img src={fileName} />

        <Box
          mt={2}
          ml="auto"
          display="flex"
          gap={2}
          justifyContent="space-between"
        >
          <Button
            onClick={(e) => navigate('/achievements')}
            variant="contained"
            color="error"
          >
            {t('back')}
          </Button>
          <Button variant="contained" onClick={(e) => handleSubmit()}>
            {t('add_achievement')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddAchievement;
