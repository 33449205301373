import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Header from '../../Recruiter/LayOutRecruiter/Header';
import LoadingComponent from '../../../components/LoadingComponent';
import TypographyHeader from '../../../components/Typography/TypographyHeader';
import PieChart from '../../Admin/HomeAdmin/component/PieChart';
import Statistic from '../../../services/adminApi/statistic';
import { useTranslation } from 'react-i18next';

const HomeRecruiter = () => {
  const [categoryStatistic, setCategoryStatistic] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalProjects, setTotalProjects] = useState(0);
  const [totalPostedProjects, settotalPostedProjects] = useState(0);
  const [totalDoingProjects, settotalDoingProjects] = useState(0);
  // const [totalCompletedProjects, settotalCompletedProjects] = useState(0);
  const [totalPendingProjects, settotalPendingProjects] = useState(0);
  // const [columns, setColumns] = useState();
  const { t } = useTranslation('dashboard');

  useEffect(() => {
    const GetData = async () => {
      try {
        setLoading(true);
        const response = await Statistic.RecruiterDashboard();

        setTotalProjects(response?.data?.totalProjects);
        settotalPostedProjects(response?.data?.totalPostedProjects);
        settotalDoingProjects(response?.data?.totalDoingProjects);
        // settotalCompletedProjects(response?.data?.totalCompletedProjects);
        settotalPendingProjects(response?.data?.totalPendingProjects);

        const mockPieData = response?.data?.projectsPerCate?.map((item) => ({
          id: item?.categoryName,
          label: item?.categoryName,
          value: item?.totalProjects,
        }));
        setCategoryStatistic(mockPieData);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    GetData();
  }, []);

  return (
    <Box sx={style}>
      {loading && <LoadingComponent loading={loading} />}

      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header title={t('dashboard')} subtitle={t('sub_dashboard')} />
      </Box>

      {/* Category======================================================  */}

      <Box
        sx={{
          m: 3,
          borderRadius: '20px',
        }}
      >
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item md={3}>
            <Box
              p={2}
              sx={{
                bgcolor: 'white',
                border: '1px solid #E0E0E0',
                borderRadius: '8px',
              }}
            >
              <Typography fontSize="18px" fontWeight="bold">
                {t('total_project')}
              </Typography>

              <Box mt={2} ml={2} textAlign={'center'}>
                <Typography fontSize="50px" fontWeight="bold" color="#008000">
                  {totalProjects}
                </Typography>
                <Typography>{t('project')}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item md={3}>
            <Box
              p={2}
              sx={{
                bgcolor: 'white',
                border: '1px solid #E0E0E0',
                borderRadius: '8px',
              }}
            >
              <Typography fontSize="18px" fontWeight="bold">
                {t('open_project')}
              </Typography>

              <Box mt={2} ml={2} textAlign={'center'}>
                <Typography fontSize="50px" fontWeight="bold" color="#008000">
                  {totalPostedProjects}
                </Typography>
                <Typography>{t('project')} </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item md={3}>
            <Box
              p={2}
              sx={{
                bgcolor: 'white',
                border: '1px solid #E0E0E0',
                borderRadius: '8px',
              }}
            >
              <Typography fontSize="18px" fontWeight="bold">
                {t('doing_project')}
              </Typography>

              <Box mt={2} ml={2} textAlign={'center'}>
                <Typography fontSize="50px" fontWeight="bold" color="#008000">
                  {totalDoingProjects}
                </Typography>
                <Typography>{t('project')}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item md={3}>
            <Box
              p={2}
              sx={{
                bgcolor: 'white',
                border: '1px solid #E0E0E0',
                borderRadius: '8px',
              }}
            >
              <Typography fontSize="18px" fontWeight="bold">
                {t('pending_project')}
              </Typography>

              <Box mt={2} ml={2} textAlign={'center'}>
                <Typography fontSize="50px" fontWeight="bold" color="#008000">
                  {totalPendingProjects}
                </Typography>
                <Typography>{t('project')}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {categoryStatistic.length > 0 && categoryStatistic && (
        <Box
          sx={{
            border: '1px solid #E0E0E0',
            m: 3,
            bgcolor: 'white',
            borderRadius: '20px',
          }}
        >
          <Box p={3}>
            <TypographyHeader title={t('chart_category')} />
          </Box>
          <Box m={2} height="450px">
            <PieChart data={categoryStatistic} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const style = {
  overflow: 'auto',
  maxHeight: window.innerHeight - 80,
};

export default HomeRecruiter;
