import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import Logo from '../assets/logo_02.png';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation('footer');
  return (
    <Box sx={{ bgcolor: '#595855', p: 6, mt: 10 }} component="footer">
      {' '}
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <img src={Logo} alt="Logo" style={{ width: '150px' }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              className="font-bold"
              color={'#fad702'}
              variant="h6"
              gutterBottom
            >
              Freelancer
            </Typography>
            <Link
              href="/search"
              variant="body2"
              color="#ffffff"
              display="block"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {t('project')}
            </Link>
            <Link
              href="/payment"
              variant="body2"
              color="#ffffff"
              display="block"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {t('payment')}
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              className="font-bold"
              color={'#fad702'}
              variant="h6"
              gutterBottom
            >
              {t('about')}
            </Typography>
            <Link
              href="/about-us"
              variant="body2"
              color="#ffffff"
              display="block"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {t('about_us')}
            </Link>
            <Link
              href="/how-it-works"
              variant="body2"
              color="#ffffff"
              display="block"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {t('how_to_works')}
            </Link>
            <Link
              href="/locked-list"
              variant="body2"
              color="#ffffff"
              display="block"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              Danh sách tài khoản bị khoá
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              className="font-bold"
              color={'#fad702'}
              variant="h6"
              gutterBottom
            >
              {t('terms')}
            </Typography>
            <Link
              href="/terms-condition"
              variant="body2"
              color="#ffffff"
              display="block"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {t('terms_condition')}
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="#ffffff" align="center">
            {'© '}
            {new Date().getFullYear()} GoodJobs. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
