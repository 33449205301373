import React, { useState } from 'react';
import {
  Typography,
  Container,
  Button,
  Grid,
  Box,
  TextField,
  Snackbar,
} from '@mui/material';
import { motion } from 'framer-motion';
import AboutUsImage from '../../../assets/committment.jpg';
import Commitment from '../../../assets/commitment_1.jpg';
import { Element, scroller } from 'react-scroll';

const AboutUs = () => {
  const handleScroll = () => {
    scroller.scrollTo('contact', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="bg-gray-100 py-12">
      <Container maxWidth="lg">
        <div className="text-center mb-12">
          <Typography variant="h3" className="text-gray-800 font-bold text-4xl">
            Về Chúng Tôi
          </Typography>
          <Typography variant="body1" className="text-gray-600 mt-4">
            Chúng tôi luôn cam kết mang lại giá trị tốt nhất cho khách hàng và
            cộng đồng.
          </Typography>
        </div>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <img
                src={AboutUsImage}
                alt="About Us"
                className="rounded-lg shadow-lg w-full h-auto"
              />
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography variant="h4" className="text-gray-800 font-bold mb-4">
                Sứ Mệnh Của Chúng Tôi
              </Typography>
              <Typography variant="body1" className="text-gray-600 mb-6">
                Chúng tôi luôn hướng đến việc xây dựng một nền tảng freelancer
                vững mạnh, mang lại giá trị cho cộng đồng sinh viên và tạo nên
                sự khác biệt trên thị trường việc làm
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className="bg-blue-600"
                onClick={handleScroll}
              >
                Liên Hệ Chúng Tôi
              </Button>
            </motion.div>
          </Grid>
        </Grid>

        {/* Additional Sections */}
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography
                variant="h4"
                className="text-gray-800 font-bold mb-4 text-end"
              >
                Lý do phát triển
              </Typography>
              <Typography variant="body1" className="text-gray-600 mb-6">
                Hiểu được các vấn đề nhức nhối trong giới sinh viên, đặc biệt là
                các sinh viên mới cũng như sinh viên mới ra trường chưa có nhiều
                kinh nghiệm làm việc thực tế. Chúng tôi - đội ngũ phát triển đã
                dành tâm huyết xây dựng những nền móng đầu tiên cho
                GoodJobs.works, nhằm mang lại 1 môi trường dễ tiếp cận nhưng vẫn
                đầy đủ tính năng để bạn có thể làm việc trong 1 môi trường
                freelancer thực thụ
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <img
                src={Commitment}
                alt="About Us"
                className="rounded-lg shadow-lg w-full h-auto"
              />
            </motion.div>
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <img
                src={AboutUsImage}
                alt="About Us"
                className="rounded-lg shadow-lg w-full h-auto"
              />
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography variant="h4" className="text-gray-800 font-bold mb-4">
                Mục tiêu hướng đến
              </Typography>
              <Typography variant="body1" className="text-gray-600 mb-6">
                Trong tương lai gần, chúng tôi định hướng GoodJobs.works trở
                thành một trong những website được nhiều sinh viên biết đến cho
                công việc trở thành freelancer. Chúng tôi hy vọng rằng website
                của chúng tôi sẽ là môi trường đơn giản, dễ tiếp cận và làm quen
                cho các bạn sinh viên và những người chưa có nhiều kinh nghiệm
                làm việc
              </Typography>
            </motion.div>
          </Grid>
        </Grid>

        {/* Additional Sections */}
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography
                variant="h4"
                className="text-gray-800 font-bold mb-4 text-end"
              >
                Về các dịch vụ
              </Typography>
              <Typography variant="body1" className="text-gray-600 mb-6">
                Hiện nay, GoodJobs.works cung cấp cho bạn dịch vụ đăng bài tìm
                người cho công việc và dịch vụ nhận công việc freelance. Chúng
                tôi luôn nỗ lực để có thể mang lại cho bạn nhiều dịch vụ mới hơn
                trong tương lai. Nếu bạn có đề xuất nào, hãy liên hệ cho chúng
                tôi.
              </Typography>
              <Box className="flex justify-end">
                <Button
                  variant="contained"
                  color="primary"
                  className="bg-blue-600"
                  onClick={handleScroll}
                >
                  Liên Hệ Chúng Tôi
                </Button>
              </Box>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <img
                src={Commitment}
                alt="About Us"
                className="rounded-lg shadow-lg w-full h-auto"
              />
            </motion.div>
          </Grid>
        </Grid>
      </Container>
      <Element name="contact" className="mt-20">
        <Container maxWidth="md">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography variant="h4" className="text-center font-bold mb-8">
              Liên hệ với chúng tôi
            </Typography>
            <form onSubmit={handleSubmit} className="space-y-6">
              <TextField
                fullWidth
                label="Họ và tên"
                name="name"
                variant="outlined"
                value={formData.name}
                onChange={handleChange}
                required
                className="bg-white rounded-lg"
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                value={formData.email}
                onChange={handleChange}
                required
                className="bg-white rounded-lg"
              />
              <TextField
                fullWidth
                label="Tin nhắn"
                name="message"
                variant="outlined"
                value={formData.message}
                onChange={handleChange}
                required
                multiline
                rows={4}
                className="bg-white rounded-lg"
              />
              <Grid container justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="bg-blue-600 text-white px-6 py-2 rounded-lg"
                >
                  Gửi Tin Nhắn
                </Button>
              </Grid>
            </form>
          </motion.div>
        </Container>
      </Element>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Cảm ơn bạn đã đóng góp. Chúng tôi sẽ phản hồi lại cho bạn sớm nhất có thể"
      />
    </div>
  );
};

export default AboutUs;
