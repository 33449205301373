import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Typography,
  Box,
  Grid,
  IconButton,
  Paper,
  LinearProgress,
  Link,
  Button,
  Rating,
  Tooltip,
  Card,
  CardMedia,
  CardContent,
  Modal,
} from '@mui/material';
import { School as SchoolIcon } from '@mui/icons-material';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import profileApi from '../../../services/profileApi';
import PaymentIcon from '@mui/icons-material/Payment';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import ReportIcon from '@mui/icons-material/Report';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ReportModal from './component/ReportModal';
import reportApi from '../../../services/reportApi';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import chatApi from '../../../services/chatApi';
import UpdateSkillModal from './component/UpdateSkillModal';
import CustomAvatar from '../../../components/CustomAvatar';
import { formatDate } from '../../../utils/formatDate';
import PhoneNumberVerificationModal from './component/VerifyModal';
import { ROLES } from '../../../constaints/role';
import { truncateText } from '../../../utils/truncateText';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import CancelIcon from '@mui/icons-material/Cancel';
import TypographyTitle from '../../../components/Typography/TypographyTitle';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import RateReviewIcon from '@mui/icons-material/RateReview';
import LoadingComponent from '../../../components/LoadingComponent';
import { useTranslation } from 'react-i18next';

import UpdateQualification from './component/UpdateQualification';

const Profile = () => {
  const { userId } = useParams();
  const currentUser = useSelector((state) => state.auth.login?.currentUser);
  const [profile, setProfile] = useState();
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isSkillModalOpen, setIsSkillModalOpen] = useState(false);
  const [isQualificationModalOpen, setIsQualificationModalOpen] =
    useState(false);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [verifyType, setVerifyType] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation('profile');

  const [portfolio, setPortfolio] = useState();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setPortfolio(null);
  };

  useEffect(() => {
    const getData = async () => {
      let res;
      if (userId) {
        if (parseInt(userId) === currentUser?.userId) {
          setIsOwnProfile(true);
        }
        res = await profileApi.getUserProfileById(userId, navigate);
      } else {
        setIsOwnProfile(true);
        res = await profileApi.getUserProfile();
      }
      setProfile(res);
    };
    getData();
  }, [userId, currentUser, navigate]);

  const handleReport = async (reportData) => {
    await reportApi.createReport(reportData);
    toast.error(`${t('report_user')}`);
  };
  const handleOpenDetail = async (id) => {
    try {
      let res = await profileApi.GetPortfolioById(id);
      setPortfolio(res);
      setOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleContact = async () => {
    let res = await chatApi.CreateNewConversation(currentUser?.userId, userId);
    navigate(`/chat/${res}/${userId}`);
  };

  const ViewPortfolio = () => {
    if (
      (currentUser?.role === ROLES.FREELANCER &&
        profile?.role === ROLES.FREELANCER) ||
      (currentUser?.role === ROLES.RECRUITER &&
        profile?.role === ROLES.FREELANCER)
    ) {
      return (
        <>
          <Paper elevation={3} className="p-4 mb-4">
            <Typography sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
              <EmojiEventsIcon color="primary" sx={{ mr: 1 }} />
              {t('achievement')}
            </Typography>
            <Box className="flex space-x-2 overflow-x-auto">
              <Grid container spacing={2} mt={3}>
                {profile?.mediaFiles?.length > 0 &&
                  profile?.mediaFiles?.map((item) => (
                    <Grid item xs={12} sm={6} key={item.fileName} mb={2}>
                      <Card
                        className="shadow-lg"
                        sx={{
                          '&:hover': {
                            transform: 'scale(1.05)',
                            transition: 'transform 0.3s ease-in-out',
                            cursor: 'pointer',
                          },
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={item.fileName}
                          alt={truncateText(item.title, 50)}
                          loading="lazy"
                          sx={{ height: 200 }}
                          onClick={() => handleOpenDetail(item?.id)}
                        />
                        <CardContent>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {item.title}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Box>
            {profile?.mediaFiles?.length === 0 && (
              <Typography sx={{ fontSize: '1rem' }}>
                {t('no_content')}
              </Typography>
            )}
          </Paper>

          {/* Section 3: Education */}
          <Paper elevation={3} className="p-4 mb-4">
            <div className="flex items-center mb-2">
              <SchoolIcon color="primary" sx={{ mr: 1 }} />
              <Typography sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                {t('education')}
              </Typography>
            </div>
            {profile ? (
              profile?.educations?.length ? (
                profile?.educations !== null &&
                profile?.educations.map((edu, index) => (
                  <Box
                    key={index}
                    mb={2}
                    border="1px solid #ccc"
                    borderRadius={3}
                    p={2}
                  >
                    <Typography sx={{ fontSize: '1rem' }}>
                      {t('university')}: {edu?.universityCollege}
                    </Typography>
                    <Typography sx={{ fontSize: '1.25rem' }}>
                      {t('degree')}: {edu?.degree}
                    </Typography>
                    <Typography sx={{ fontSize: '1rem' }}>
                      {t('time_complete')}:{' '}
                      {`${edu?.start?.year} - ${edu?.end?.year}`}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography sx={{ fontSize: '1rem' }}>
                  {t('no_content')}
                </Typography>
              )
            ) : (
              <LinearProgress />
            )}
          </Paper>

          {/* Section 4: Experience */}
          <Paper elevation={3} className="p-4 mb-4">
            <div className="flex items-center mb-2">
              <WorkIcon color="primary" sx={{ mr: 1 }} />
              <Typography sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                {t('exp')}
              </Typography>
            </div>
            {profile ? (
              profile?.experiences?.length ? (
                profile?.experiences !== null &&
                profile?.experiences.map((exp, index) => (
                  <Box
                    key={index}
                    mb={2}
                    border="1px solid #ccc"
                    borderRadius={5}
                    p={2}
                  >
                    <Typography sx={{ fontSize: '1.25rem' }}>
                      {exp?.title}
                    </Typography>
                    <Typography sx={{ fontSize: '1rem' }}>
                      {t('work_time')}:{' '}
                      {`${exp?.start?.year} - ${exp?.end?.year}`}
                    </Typography>
                    <Typography sx={{ fontSize: '1rem', color: 'gray' }}>
                      {t('short_description')} : {exp?.summary}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography sx={{ fontSize: '1rem' }}>
                  {t('no_content')}
                </Typography>
              )
            ) : (
              <LinearProgress />
            )}
          </Paper>
        </>
      );
    }
  };
  const ViewSkills = () => {
    if (
      (currentUser?.role === ROLES.FREELANCER &&
        profile?.role === ROLES.FREELANCER) ||
      (currentUser?.role === ROLES.RECRUITER &&
        profile?.role === ROLES.FREELANCER)
    ) {
      return (
        <>
          <Paper elevation={3} className="p-4">
            <Grid container>
              <Grid item xs={12} md={11}>
                <Typography
                  sx={{
                    fontSize: '1.25rem',
                    fontWeight: 'bold',
                    paddingTop: '8px',
                  }}
                >
                  {t('skill')}
                </Typography>
              </Grid>
              {isOwnProfile && (
                <Grid item xs={12} md={1}>
                  <IconButton
                    sx={{
                      '& .MuiSvgIcon-root': {
                        transition: 'color 0.3s',
                      },
                      '&:hover .MuiSvgIcon-root': {
                        color: 'blue',
                      },
                    }}
                    onClick={() => setIsSkillModalOpen(true)}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Box className="mt-4 space-y-2">
              {profile?.skills?.length ? (
                profile?.skills !== null &&
                profile?.skills.map((skill, index) => (
                  <Box
                    key={index}
                    className="flex items-center justify-between"
                  >
                    <Typography sx={{ fontSize: '1rem' }}>{skill}</Typography>
                  </Box>
                ))
              ) : (
                <Typography sx={{ fontSize: '1rem' }}>
                  {t('no_content')}
                </Typography>
              )}
            </Box>
          </Paper>
          <Paper elevation={3} className="p-4">
            <Box className="flex justify-between">
              <Typography sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                {t('certificate')}
              </Typography>
              {isOwnProfile && (
                <IconButton
                  sx={{
                    '& .MuiSvgIcon-root': {
                      transition: 'color 0.3s',
                    },
                    '&:hover .MuiSvgIcon-root': {
                      color: 'blue',
                    },
                  }}
                  onClick={() => setIsQualificationModalOpen(true)}
                >
                  <EditIcon />
                </IconButton>
              )}
            </Box>
            <Box className="mt-4">
              {profile?.qualifications?.length ? (
                profile?.qualifications !== null &&
                profile?.qualifications.map((item, index) => (
                  <Box key={index} mb={4}>
                    <Typography
                      component={RouterLink}
                      to={item?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ fontSize: '1rem' }}
                      fontWeight="bold"
                      color="primary"
                    >
                      {item?.organization}
                    </Typography>
                    <Typography
                      component={RouterLink}
                      to={item?.link}
                      target="_blank"
                      sx={{
                        mt: 1,
                        fontSize: '1rem',
                        color: 'primary',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        display: 'block',
                        textDecoration: 'underline',
                      }}
                    >
                      {truncateText(item?.link, 100)}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography sx={{ fontSize: '1rem' }}>
                  {t('no_content')}
                </Typography>
              )}
            </Box>
          </Paper>
        </>
      );
    }
  };

  const handleOpenVerifyModal = async (type) => {
    setVerifyType(type);
    setIsVerifyModalOpen(true);
  };

  return (
    <Box
      className="max-width-container"
      sx={{ paddingX: { xs: 2, sm: 4, md: 6, lg: 8 } }}
    >
      {!profile && <LoadingComponent loading={true} />}
      <Grid container spacing={2}>
        {/* Left Side */}
        <Grid item xs={12} md={8}>
          {/* Section 1 */}
          <Paper elevation={3} className="p-4 mb-4">
            <Box className="flex space-x-4">
              {profile?.avatar === null || profile?.avatar === '' ? (
                <CustomAvatar name={profile?.name} size={100} />
              ) : (
                <Avatar
                  alt="Avatar"
                  src={profile?.avatar}
                  sx={{ height: 100, width: 100 }}
                  className="shadow-lg"
                />
              )}
              <Box className="flex-1 space-y-2">
                <Typography sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                  {profile?.name}
                </Typography>
                <Box className="flex space-x-2">
                  {profile?.skills !== null &&
                    profile?.skills.map((skill, index) => (
                      <Typography
                        key={index}
                        variant="body2"
                        className="bg-gray-200 p-1 rounded"
                      >
                        {skill}
                      </Typography>
                    ))}
                </Box>
                <Box className="flex items-center space-x-1" gap={3}>
                  <Tooltip
                    title={t('rating')}
                    className="flex items-center space-x-1"
                  >
                    <Box className="flex items-center space-x-1">
                      <Rating
                        value={profile?.avgRate ?? 0}
                        precision={0.1}
                        readOnly
                      />
                      <Typography>
                        {profile?.avgRate
                          ? profile?.avgRate
                          : `${t('no_data')}`}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={t('total_rate')}
                    className="flex items-center space-x-1"
                  >
                    <Box className="flex items-center space-x-1">
                      <HowToVoteIcon color="primary" />
                      <Typography>
                        {profile?.totalRate
                          ? profile?.totalRate
                          : `${t('no_rate')}`}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
                <Box display="flex" gap={3}>
                  <Tooltip title={t('total_project')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <WorkOutlineIcon sx={{ color: 'primary.main' }} />
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {profile?.totalProject}
                      </Typography>
                    </Box>
                  </Tooltip>

                  {profile?.address !== null && (
                    <Tooltip title={t('address')}>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <LocationOnIcon sx={{ color: 'primary.main' }} />
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {profile?.address?.city} {profile?.address?.country}
                        </Typography>
                      </Box>
                    </Tooltip>
                  )}
                </Box>
                {!isOwnProfile && currentUser?.role !== ROLES.ADMIN && (
                  <Box className="flex space-x-2 mt-2">
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<ReportIcon />}
                      onClick={() => setIsReportModalOpen(true)}
                    >
                      {t('report')}
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<ChatOutlinedIcon />}
                      onClick={() => handleContact()}
                    >
                      {t('message')}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className="mt-4 space-y-2">
              <Typography>
                <CalendarMonthIcon />
                {t('join_date')}: {formatDate(profile?.createdDate)}
              </Typography>
              <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                About me:
              </Typography>
              <Typography>{profile?.description}</Typography>
            </Box>
          </Paper>

          {/* Section 2: Portfolio */}
          {ViewPortfolio()}

          {/* Section 5: Ratings */}
          <Paper elevation={3} className="p-4">
            <Typography sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
              <RateReviewIcon color="primary" sx={{ mr: 1 }} />
              {t('rating')}
            </Typography>
            <Box className="mt-4 space-y-4">
              {profile ? (
                profile?.ratings?.length ? (
                  profile?.ratings !== null &&
                  profile?.ratings.map((rating, index) => (
                    <Paper key={index} elevation={2} className="p-4">
                      <Box display={'flex'} gap={1}>
                        <Typography sx={{ fontSize: '1rem' }}>
                          <strong>{t('user')}:</strong> {rating?.userRate} -
                        </Typography>
                        <Rating
                          name="read-only"
                          value={rating?.star}
                          readOnly
                        />
                      </Box>

                      <Typography sx={{ fontSize: '1rem' }}>
                        <strong>{t('previous_project')}:</strong>{' '}
                        {rating?.projectName}
                      </Typography>
                      <Box sx={{ fontSize: '1rem' }}>
                        <strong>{t('previous_skill')}:</strong>{' '}
                        {rating?.skillOfProject &&
                          rating?.skillOfProject.map((skill, index) => (
                            <Typography
                              key={index}
                              sx={{ fontSize: '1rem' }}
                              component="span" // Use span to display inline
                            >
                              {index > 0 ? ', ' : ''}
                              {skill}
                            </Typography>
                          ))}
                      </Box>
                      <Typography sx={{ fontSize: '1rem' }}>
                        <strong>{t('rate_comment')}: </strong>
                        {rating?.comment}
                      </Typography>
                    </Paper>
                  ))
                ) : (
                  <Typography sx={{ fontSize: '1rem' }}>
                    {t('no_content')}
                  </Typography>
                )
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Paper>
        </Grid>

        {/* Right Side */}
        <Grid item xs={12} md={4} className="space-y-4">
          <Paper elevation={3} className="p-4">
            <Typography sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
              {t('verify_info')}
            </Typography>
            <Box className="mt-4 space-y-2">
              <Grid container>
                <Grid item xs={12}>
                  <Box className="flex justify-between">
                    <Box className="flex items-center">
                      <PaymentIcon
                        color={profile?.isPaid === true ? 'success' : 'error'}
                      />
                      <Typography
                        sx={{ fontSize: '1rem', marginLeft: '0.5rem' }}
                      >
                        {t('payment')}
                      </Typography>
                    </Box>
                    {profile?.isPaid === true ? (
                      <Box>
                        <CheckCircleIcon color="success" fontSize="medium" />
                      </Box>
                    ) : (
                      <Box>
                        <CancelIcon color="error" fontSize="medium" />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="flex justify-between">
                    <Box className="flex items-center">
                      <PhoneAndroidIcon
                        color={
                          profile?.phoneNumberConfirmed ? 'success' : 'error'
                        }
                      />
                      <Typography
                        sx={{ fontSize: '1rem', marginLeft: '0.5rem' }}
                      >
                        {t('phone_verify')}
                      </Typography>
                    </Box>
                    {isOwnProfile && !profile?.phoneNumberConfirmed && (
                      <Box>
                        <Link
                          href="#"
                          underline="hover"
                          onClick={() => handleOpenVerifyModal('phone')}
                        >
                          <Typography>{t('verify')} </Typography>
                        </Link>
                      </Box>
                    )}
                    {isOwnProfile && profile?.phoneNumberConfirmed && (
                      <Box>
                        <CheckCircleIcon color="success" fontSize="medium" />
                      </Box>
                    )}
                    {!isOwnProfile && !profile?.phoneNumberConfirmed && (
                      <Box>
                        <CancelIcon color="error" fontSize="medium" />
                      </Box>
                    )}
                    {!isOwnProfile && profile?.phoneNumberConfirmed && (
                      <Box>
                        <CheckCircleIcon color="success" fontSize="medium" />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="flex justify-between">
                    <Box className="flex items-center">
                      <EmailIcon
                        color={profile?.emailConfirmed ? 'success' : 'error'}
                      />
                      <Typography
                        sx={{ fontSize: '1rem', marginLeft: '0.5rem' }}
                      >
                        {t('email_verify')}
                      </Typography>
                    </Box>
                    {isOwnProfile && !profile?.emailConfirmed && (
                      <Box>
                        <Link
                          href="#"
                          underline="hover"
                          onClick={() => handleOpenVerifyModal('email')}
                        >
                          <Typography> {t('verify')} </Typography>
                        </Link>
                      </Box>
                    )}
                    {isOwnProfile && profile?.emailConfirmed && (
                      <Box>
                        <CheckCircleIcon color="success" fontSize="medium" />
                      </Box>
                    )}
                    {!isOwnProfile && !profile?.emailConfirmed && (
                      <Box>
                        <CancelIcon color="error" fontSize="medium" />
                      </Box>
                    )}
                    {!isOwnProfile && profile?.emailConfirmed && (
                      <Box>
                        <CheckCircleIcon color="success" fontSize="medium" />
                      </Box>
                    )}
                  </Box>
                </Grid>

                {isOwnProfile &&
                  !profile?.phoneNumberConfirmed &&
                  !profile?.emailConfirmed && (
                    <Grid item xs={12}>
                      <Box
                        border="1px solid"
                        className="p-4 mt-2 bg-[#FFF8E1]"
                        borderRadius={2}
                        borderColor={'orange'}
                      >
                        <Typography>{t('verify_note')}</Typography>
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </Box>
          </Paper>
          {ViewSkills()}
        </Grid>
      </Grid>
      <ReportModal
        name={profile?.name}
        userId={profile?.id}
        open={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
        onReport={handleReport}
        type="user"
      />
      <UpdateSkillModal
        openSkill={isSkillModalOpen}
        onCloseSkill={() => setIsSkillModalOpen(false)}
        profile={profile}
        setProfile={setProfile}
      />
      <PhoneNumberVerificationModal
        verifyType={verifyType}
        openVerify={isVerifyModalOpen}
        onCloseVerify={() => setIsVerifyModalOpen(false)}
      />
      <UpdateQualification
        profile={profile}
        setProfile={setProfile}
        open={isQualificationModalOpen}
        onClose={() => setIsQualificationModalOpen(false)}
      />

      <Modal open={open} onClose={handleClose}>
        <Box sx={style} gap={2} className="rounded-xl">
          <Box flex={1} borderRadius={5} border="1px solid #ccc">
            <img alt="avatar" src={portfolio?.fileName} />
          </Box>
          <Box flex={0.8}>
            <TypographyTitle title={portfolio?.title} />
            <Typography mt={2}> {portfolio?.description}</Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
const style = {
  overflow: 'auto',
  maxHeight: window.innerHeight - 80,
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
export default Profile;
