import React, { useState } from 'react';
import {
  Box,
  Container,
  Divider,
  LinearProgress,
  Typography,
  Alert,
  Button,
  Snackbar,
  Stack,
  Modal,
  TextField,
} from '@mui/material';
import ProjectDescription from '../../../components/ProjectDescription';
import TypographyTitle from '../../../components/Typography/TypographyTitle';
import { formatDate } from '../../../utils/formatDate';
import projectApi from '../../../services/projectApi';
import { formatCurrency } from '../../../utils/formatCurrency';
import ShowListSkeleton from '../../../components/Skeleton/ShowListSkeleton';
import { styled } from '@mui/system';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { toast } from 'react-toastify';

const styleDelete = {
  position: 'absolute',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '8px',
  textAlign: 'center',
};
const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Textarea = styled('textarea')(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === 'dark' ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === 'dark' ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

const ProjectList = ({
  listProject,
  setReload,
  handleSeach,
  search,
  handleChange,
  handleTabChange,
  statusId,
  setListProject,
}) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [reasonReject, setReasonReject] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenModal = (projectId) => {
    setReasonReject('');
    setSelectedProjectId(projectId);
    setOpenModal(true);
  };

  const handleOpenModalDelete = (projectId) => {
    setSelectedProjectId(projectId);
    setOpenDelete(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setReasonReject('');
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      await projectApi.DeleteProjectAdmin(selectedProjectId);
      removeProject(selectedProjectId);
      setSelectedProjectId(null);
      setOpenDelete(false);
      setReload((prev) => !prev);
    } catch (error) {
      toast.error('Xoá dự án thất bại');
    }
  };

  const removeProject = (projectId) => {
    setListProject((prev) => {
      const updatedItems = prev?.items.filter((item) => item.id !== projectId);
      return {
        ...prev,
        items: updatedItems,
      };
    });
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleApprove = async (id, statusId) => {
    let data = {
      statusId: statusId,
      projectId: id,
    };

    try {
      await projectApi.ApproveProject(data);
      setOpen(true);
      removeProject(id);
      setReload((prev) => !prev);
    } catch (error) {
      console.error(error);
    }
  };

  const handleReject = async () => {
    if (!reasonReject) return;
    let data = {
      statusId: 5,
      projectId: selectedProjectId,
      rejectReason: reasonReject,
    };
    try {
      await projectApi.RejectProject(data);
      setOpenModal(false);
      setOpen(true);
      removeProject(selectedProjectId);
      setReload((prev) => !prev);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {Object.keys(listProject).length === 0 ? (
        <ShowListSkeleton />
      ) : (
        <Box className="p-4" sx={{ width: '100%', overflow: 'auto' }}>
          <Box className="mb-4">
            <div className="relative w-full flex justify-left">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-b-2 border-gray-300"></span>
              </div>
              <div className="relative flex justify-center space-x-1 bg-gray-100 rounded-full p-1 shadow">
                <button
                  onClick={() => handleTabChange(1)}
                  className={`px-4 py-2 rounded-full transition-all duration-300 ${
                    statusId === 1
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 text-black'
                  }`}
                >
                  Chờ duyệt
                </button>
                <button
                  onClick={() => handleTabChange(5)}
                  className={`px-4 py-2 rounded-full transition-all duration-300 ${
                    statusId === 5
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 text-black'
                  }`}
                >
                  Từ chối
                </button>
                <button
                  onClick={() => handleTabChange(2)}
                  className={`px-4 py-2 rounded-full transition-all duration-300 ${
                    statusId === 2
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 text-black'
                  }`}
                >
                  Đang mở
                </button>
                <button
                  onClick={() => handleTabChange(3)}
                  className={`px-4 py-2 rounded-full transition-all duration-300 ${
                    statusId === 3
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 text-black'
                  }`}
                >
                  Đang tiến hành
                </button>
                <button
                  onClick={() => handleTabChange(9)}
                  className={`px-4 py-2 rounded-full transition-all duration-300 ${
                    statusId === 9
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 text-black'
                  }`}
                >
                  Đang kiểm
                </button>
                <button
                  onClick={() => handleTabChange(6)}
                  className={`px-4 py-2 rounded-full transition-all duration-300 ${
                    statusId === 6
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 text-black'
                  }`}
                >
                  Đã hoàn thành
                </button>
              </div>
            </div>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography sx={{ fontSize: '1.5rem', fontWeight: '600' }}>
              Danh sách dự án
            </Typography>
          </Box>
          <Box display="flex" mb={3} gap={2}>
            <TextField
              sx={{
                width: '50%',
                bgcolor: 'white',
              }}
              size="small"
              id="outlined-basic"
              label="Tìm kiếm "
              value={search}
              onChange={handleChange}
              variant="outlined"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSeach}
              sx={{
                color: '#fff',
                fontSize: '12px',
              }}
            >
              <SearchOutlinedIcon /> Tìm kiếm
            </Button>
          </Box>
          <Box>
            {listProject === undefined && <LinearProgress />}
            {listProject?.items?.length === 0 && (
              <Container maxWidth="md" style={{ marginTop: '20px' }}>
                <Alert severity="info">Hiện tại chưa có bản ghi nào .</Alert>
              </Container>
            )}
            {listProject?.items?.length !== 0 &&
              listProject?.items?.map(
                (project, index) =>
                  project?.projectStatus?.id !== 0 && (
                    <Box key={index} className="project-item">
                      <Box
                        sx={{
                          position: 'relative',
                          padding: '10px',
                          backgroundColor: '#fff',
                          '&:hover': {
                            backgroundColor: '#f0f0f0',
                          },
                          cursor: 'pointer',
                        }}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '-7px',
                            left: 0,
                            padding: '4px 8px',
                            backgroundColor:
                              project?.projectStatus?.statusColor,
                            color: 'black',
                            borderTopRightRadius: '20px',
                            borderBottomRightRadius: '20px',
                            border: '1px solid #ccc',
                          }}
                        >
                          <Typography className="font-bold" fontSize={'10px'}>
                            {project?.projectStatus?.statusName}
                          </Typography>
                        </Box>
                        <Box display="flex" mt={1}>
                          <Box>
                            <TypographyTitle
                              title={project?.title}
                              color="#3366FF"
                            />

                            <Typography fontWeight="bold" fontSize="14px">
                              Ngân sách : {formatCurrency(project?.minBudget)}-{' '}
                              {formatCurrency(project?.maxBudget)}
                            </Typography>
                            <Typography fontWeight="bold" fontSize="14px">
                              Thời gian : {project?.duration} ngày
                            </Typography>
                          </Box>
                        </Box>
                        <Box mt={1}>
                          <Box m={2}>
                            <ProjectDescription
                              description={project?.description}
                            />
                          </Box>
                          <Typography fontWeight="bold" fontSize="14px">
                            Kỹ năng yêu cầu
                          </Typography>
                          <Box display="flex" flexWrap="wrap">
                            {project?.skill?.map((item, index) => (
                              <Box
                                key={index}
                                sx={{
                                  '&:hover': { backgroundColor: '#f0f8ff' },
                                }}
                                className="mt-2 ml-2 inline-block rounded-3xl border border-sky-500 p-2"
                              >
                                <Typography fontSize="15px">
                                  {' '}
                                  {item}{' '}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: 1,
                          }}
                        >
                          <Typography className="self-center" fontSize="12px">
                            Ngày tạo : {formatDate(project?.createdDate)}
                          </Typography>
                          <Stack direction="row" spacing={2}>
                            {project?.statusId === 1 && (
                              <>
                                <Button
                                  onClick={() =>
                                    handleApprove(
                                      project?.id,
                                      project?.projectStatus?.id
                                    )
                                  }
                                  variant="outlined"
                                  size="small"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  Duyệt{' '}
                                  <ThumbUpAltOutlinedIcon
                                    style={{ paddingBottom: 4, paddingLeft: 4 }}
                                  />
                                </Button>
                                <Button
                                  onClick={() => handleOpenModal(project.id)}
                                  variant="outlined"
                                  color="warning"
                                  size="small"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  Từ chối
                                  <ThumbDownAltOutlinedIcon
                                    style={{ paddingBottom: 4, paddingLeft: 4 }}
                                  />
                                </Button>
                              </>
                            )}
                            {(project?.statusId === 1 ||
                              project?.statusId === 2) && (
                              <>
                                <Button
                                  onClick={() =>
                                    handleOpenModalDelete(project?.id)
                                  }
                                  variant="outlined"
                                  color="error"
                                  size="small"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  Xoá
                                  <DeleteOutlineOutlinedIcon
                                    style={{ paddingBottom: 4, paddingLeft: 4 }}
                                  />
                                </Button>
                              </>
                            )}
                          </Stack>
                        </Box>
                      </Box>
                      <Divider />
                    </Box>
                  )
              )}
            <Snackbar
              open={open}
              autoHideDuration={5000}
              onClose={handleClose}
              message="Cập nhật trạng thái dự án thành công"
            />
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <TypographyTitle title="Lý do từ chối" />
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
                  Cung cấp lý do từ chối để người đăng bài chỉnh sửa lại project
                  của họ phù hợp hơn.
                </Typography>
                <Textarea
                  aria-label="minimum height"
                  minRows={3}
                  value={reasonReject}
                  onChange={(e) =>
                    setReasonReject(e.target.value.slice(0, 200))
                  }
                  placeholder="Giới hạn 200 kí tự..."
                />
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button
                    onClick={handleCloseModal}
                    color="secondary"
                    sx={{ mr: 1 }}
                  >
                    Hủy bỏ
                  </Button>
                  <Button variant="contained" onClick={handleReject}>
                    Gửi
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Box>
          <Modal
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="delete-modal-title"
            aria-describedby="delete-modal-description"
          >
            <Box sx={styleDelete}>
              <DeleteOutlineOutlinedIcon color="error" sx={{ fontSize: 64 }} />
              <TypographyTitle title="Xác nhận" />

              <Typography sx={{ mt: 2, mb: 4 }}>
                Bạn có chắc chắn muốn xoá bài viết ?
              </Typography>
              <Button
                variant="outlined"
                color="error"
                onClick={(e) => handleConfirmDelete()}
                sx={{ mr: 2 }}
              >
                Chắc chắn
              </Button>
              <Button variant="outlined" onClick={handleCloseDelete}>
                Không
              </Button>
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
};

export default ProjectList;
