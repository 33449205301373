import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const months = [
  'Tháng 1',
  'Tháng 2',
  'Tháng 3',
  'Tháng 4',
  'Tháng 5',
  'Tháng 6',
  'Tháng 7',
  'Tháng 8',
  'Tháng 9',
  'Tháng 10',
  'Tháng 11',
  'Tháng 12',
];

const years = Array.from(new Array(50), (val, index) => index + 1975);

const DateSelector = ({
  startMonth,
  setStartMonth,
  startYear,
  setStartYear,
  endMonth,
  setEndMonth,
  endYear,
  setEndYear,
  handleChange,
  showMonths = true,
}) => {
  const { t } = useTranslation('project');

  return (
    <Box display="flex" gap={3}>
      {showMonths && (
        <>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t('month_start')} </InputLabel>
            <Select
              value={startMonth}
              onChange={(e) => {
                setStartMonth(e.target.value);
                handleChange();
              }}
            >
              {months.map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      <FormControl fullWidth margin="normal">
        <InputLabel>{t('year_start')} </InputLabel>
        <Select
          value={startYear}
          onChange={(e) => {
            setStartYear(e.target.value);
            handleChange();
          }}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {showMonths && (
        <>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t('month_end')} </InputLabel>
            <Select
              value={endMonth}
              onChange={(e) => {
                setEndMonth(e.target.value);
                handleChange();
              }}
            >
              {months.map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      <FormControl fullWidth margin="normal">
        <InputLabel>{t('year_end')} </InputLabel>
        <Select
          value={endYear}
          onChange={(e) => {
            setEndYear(e.target.value);
            handleChange();
          }}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DateSelector;
