import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  Divider,
  Skeleton,
  Modal,
} from '@mui/material';
import DateSelector from './component/DateSelector';
import profileApi from '../../../services/profileApi';
import { toast } from 'react-toastify';
import { School as SchoolIcon } from '@mui/icons-material';
import WorkIcon from '@mui/icons-material/Work';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';
import TypographyTitle from '../../../components/Typography/TypographyTitle';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const ExperienceEducation = () => {
  const [profile, setProfile] = useState(null);

  const [experience, setExperience] = useState({
    company: '',
    title: '',
    startMonth: '',
    startYear: '',
    endMonth: '',
    endYear: '',
    description: '',
  });

  const [education, setEducation] = useState({
    universityCollege: '',
    degree: '',
    startYear: '',
    endYear: '',
    country: '',
  });

  const [isExperienceButtonDisabled, setIsExperienceButtonDisabled] =
    useState(true);
  const [isEducationButtonDisabled, setIsEducationButtonDisabled] =
    useState(true);
  const [editIndex, setEditIndex] = useState(null);
  const [editingEducationIndex, setEditingEducationIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [openEdu, setOpenEdu] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [eduId, setEduId] = useState(null);
  const [expId, setExpId] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation('profile');

  const handleOpenEdu = () => setOpenEdu(true);
  const handleCloseEdu = () => setOpenEdu(false);

  const handleOpenDelete = (type, id) => {
    if (type === 'edu') {
      setEduId(id);
    }
    if (type === 'exp') {
      setExpId(id);
    }
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setEduId(null);
    setExpId(null);
    setOpenDelete(false);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let res = await profileApi.getUserProfile();
        setProfile(res);
      } catch (error) {
        console.error(`${t('load_fail')}`);
        toast.error(`${t('load_fail')}`);
      }
    };
    getData();
  }, []);

  const handleExperienceChange = (key, value) => {
    setExperience((prev) => ({ ...prev, [key]: value }));
  };

  const handleEducationChange = (key, value) => {
    setEducation((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    const isExperienceFilled = Object.values(experience).every(
      (val) => val !== ''
    );
    setIsExperienceButtonDisabled(!isExperienceFilled);
  }, [experience]);

  useEffect(() => {
    const isEducationFilled = Object.values(education).every(
      (val) => val !== ''
    );
    setIsEducationButtonDisabled(!isEducationFilled);
  }, [education]);

  const handleExperienceSave = async (e) => {
    e.preventDefault();

    const newExperience = {
      title: experience.title,
      company: experience.company,
      start: {
        month: experience.startMonth,
        year: experience.startYear,
      },
      end: {
        month: experience.endMonth,
        year: experience.endYear,
      },
      summary: experience.description,
    };

    let updatedExperiences;
    if (editIndex !== null) {
      updatedExperiences = profile.experiences.map((exp, index) =>
        index === editIndex ? newExperience : exp
      );
    } else {
      updatedExperiences = profile.experiences
        ? [...profile.experiences, newExperience]
        : [newExperience];
    }

    try {
      await profileApi.updateExperience(updatedExperiences, 'update');
      setProfile((prevProfile) => ({
        ...prevProfile,
        experiences: updatedExperiences,
      }));
      setExperience({
        company: '',
        title: '',
        startMonth: '',
        startYear: '',
        endMonth: '',
        endYear: '',
        description: '',
      });
      setEditIndex(null);
      toast.success(`${t('update_success')}`);
    } catch (error) {
      console.error(`${t('update_error')}`);
      toast.error(`${t('update_error')}`);
    }
  };

  const handleEducationSave = async (e) => {
    e.preventDefault();
    if (parseInt(education.startYear) >= parseInt(education.endYear)) {
      toast.error(`${t('input_data')}`);
      return;
    }
    const newEducation = {
      universityCollege: education.universityCollege,
      degree: education.degree,
      start: {
        month: null,
        year: parseInt(education.startYear),
      },
      end: {
        month: null,
        year: parseInt(education.endYear),
      },
      country: education.country,
    };

    let updatedEducation;
    if (editingEducationIndex !== null) {
      updatedEducation = profile.educations.map((edu, index) =>
        index === editingEducationIndex ? newEducation : edu
      );
    } else {
      updatedEducation = profile.educations
        ? [...profile.educations, newEducation]
        : [newEducation];
    }

    try {
      await profileApi.updateEducation(updatedEducation, 'update');
      setProfile((prevProfile) => ({
        ...prevProfile,
        educations: updatedEducation,
      }));
      setEducation({
        universityCollege: '',
        degree: '',
        startYear: '',
        endYear: '',
        country: '',
      });
      setEditingEducationIndex(null);
      toast.success(`${t('update_success')}`);
    } catch (error) {
      console.error(`${t('update_error')}`);
      toast.error(`${t('update_error')}`);
    }
  };

  const handleEditEducation = (index) => {
    const edu = profile.educations[index];
    setEducation({
      universityCollege: edu.universityCollege,
      degree: edu.degree,
      startYear: edu.start.year,
      endYear: edu.end.year,
      country: edu.country,
    });
    setEditingEducationIndex(index);
    handleOpenEdu();
  };

  const handleAddEducation = () => {
    setEducation({
      universityCollege: '',
      degree: '',
      startYear: '',
      endYear: '',
      country: '',
    });
    setEditingEducationIndex(null);
    handleOpenEdu();
  };

  const handleConfirmDelete = () => {
    if (eduId !== null) {
      handleDeleteEducation(eduId);
    }
    if (expId !== null) {
      handleDeleteExperience(expId);
    }
    handleCloseDelete();
  };

  const handleDeleteExperience = async (index) => {
    const updatedExperiences = profile.experiences.filter(
      (_, expIndex) => expIndex !== index
    );

    try {
      await profileApi.updateExperience(updatedExperiences, 'delete');
      setProfile((prevProfile) => ({
        ...prevProfile,
        experiences: updatedExperiences,
      }));
      toast.success(`${t('remove_success')}`);
    } catch (error) {
      console.error(`${t('remove_fail')}`);
      toast.error(`${t('remove_fail')}`);
    }
  };

  const handleDeleteEducation = async (index) => {
    const updatedEducations = profile.educations.filter(
      (_, eduIndex) => eduIndex !== index
    );

    try {
      await profileApi.updateEducation(updatedEducations, 'delete');
      setProfile((prevProfile) => ({
        ...prevProfile,
        educations: updatedEducations,
      }));
      toast.success(`${t('remove_success')}`);
    } catch (error) {
      console.error(`${t('remove_fail')}`);
      toast.error(`${t('remove_fail')}`);
    }
  };

  const handleEditExperience = (index) => {
    const exp = profile.experiences[index];
    setExperience({
      company: exp.company,
      title: exp.title,
      startMonth: exp.start?.month,
      startYear: exp.start?.year,
      endMonth: exp.end?.month,
      endYear: exp.end?.year,
      description: exp.summary,
    });
    setEditIndex(index);
    handleOpen();
  };

  const handleAddExperience = () => {
    setExperience({
      company: '',
      title: '',
      startMonth: '',
      startYear: '',
      endMonth: '',
      endYear: '',
      description: '',
    });
    setEditIndex(null);
    handleOpen();
  };

  const renderEducations = (educations) => {
    if (!profile) {
      return (
        <Box mb={2} border="1px solid #ccc" borderRadius={5} p={2}>
          <Skeleton
            animation="wave"
            height={10}
            width="10%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="20%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="30%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            sx={{ height: 190 }}
            animation="wave"
            variant="rectangular"
          />
        </Box>
      );
    } else if (educations?.length === 0 || !educations || educations === null) {
      return <Typography>{t('no_content')}</Typography>;
    } else {
      return educations?.map((edu, index) => (
        <Box key={index} mb={2} border="1px solid #ccc" borderRadius={5} p={2}>
          <Typography variant="subtitle1">{edu.degree}</Typography>
          <Typography variant="body2">
            <SchoolIcon color="primary" sx={{ mr: 1 }} />
            {edu.universityCollege}
          </Typography>
          <Typography variant="body2">
            <LocationOnIcon color="primary" sx={{ mr: 1 }} /> {edu.country}
          </Typography>
          <Typography variant="body2">
            <AccessTimeOutlinedIcon color="primary" sx={{ mr: 1 }} />
            {`${edu.start.year} - ${edu.end.year}`}
          </Typography>

          <Button variant="outlined" onClick={() => handleEditEducation(index)}>
            {t('edit')}
          </Button>
          <Button
            variant="outlined"
            sx={{ marginLeft: 2 }}
            color="error"
            onClick={() => handleOpenDelete('edu', index)}
          >
            {t('remove')}
          </Button>
        </Box>
      ));
    }
  };

  const renderExperiences = (experiences) => {
    if (!profile) {
      return (
        <Box mb={2} border="1px solid #ccc" borderRadius={5} p={2}>
          <Skeleton
            animation="wave"
            height={10}
            width="10%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="20%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="30%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            sx={{ height: 190 }}
            animation="wave"
            variant="rectangular"
          />
        </Box>
      );
    } else if (
      experiences?.length === 0 ||
      !experiences ||
      experiences === null
    ) {
      return <Typography>{t('no_content')}</Typography>;
    } else {
      return experiences?.map((exp, index) => (
        <Box key={index} mb={2} border="1px solid #ccc" borderRadius={5} p={2}>
          <Typography variant="subtitle1">{exp.title}</Typography>
          <Typography variant="body2">
            <ApartmentOutlinedIcon color="primary" sx={{ mr: 1 }} />{' '}
            {exp.company}
          </Typography>
          <Typography variant="body2">
            <AccessTimeOutlinedIcon color="primary" sx={{ mr: 1 }} />
            {`${exp.start.month} ${exp.start.year} - ${exp.end.month} ${exp.end.year}`}
          </Typography>
          <Typography variant="body2">{exp.summary}</Typography>
          <Button
            variant="outlined"
            onClick={() => handleEditExperience(index)}
          >
            {t('edit')}
          </Button>
          <Button
            variant="outlined"
            sx={{ marginLeft: 2 }}
            color="error"
            onClick={() => handleOpenDelete('exp', index)}
          >
            {t('remove')}
          </Button>
        </Box>
      ));
    }
  };

  return (
    <Box p={3} m={3} borderRadius={5} border="1px solid #ccc">
      <Typography sx={{ fontSize: '2em' }} gutterBottom>
        {t('exp_edu')}
      </Typography>

      <Box mb={2}>
        <Typography sx={{ fontSize: '1.5em' }} variant="h6">
          <WorkIcon sx={{ mr: 1, mb: 1 }} />
          {t('exp')}
        </Typography>

        {renderExperiences(profile?.experiences)}
        <Button
          variant="contained"
          onClick={handleAddExperience}
          sx={{ mt: 2 }}
        >
          {t('add_exp')}
        </Button>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box mb={2}>
        <Typography sx={{ fontSize: '1.5em' }} variant="h6">
          <SchoolIcon sx={{ mr: 1, mb: 1 }} />
          {t('edu')}
        </Typography>
        {renderEducations(profile?.educations)}

        <Button variant="contained" onClick={handleAddEducation} sx={{ mt: 2 }}>
          {t('add_edu')}
        </Button>
      </Box>
      {/* Modal Experience ================================================================= */}

      <Modal open={open} onClose={handleClose}>
        <Box sx={style} className="rounded-xl">
          <Box component="form" onSubmit={handleExperienceSave}>
            <Box textAlign="center" mb={2}>
              <Typography fontSize="30px" fontWeight="bold">
                {editIndex !== null ? `${t('update')}` : `${t('add_exp')}`}
              </Typography>
            </Box>
            <Divider mb={2} />
            <TextField
              label={t('company')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={experience.company}
              onChange={(e) =>
                handleExperienceChange('company', e.target.value)
              }
            />
            <DateSelector
              startMonth={experience.startMonth}
              setStartMonth={(val) => handleExperienceChange('startMonth', val)}
              startYear={experience.startYear}
              setStartYear={(val) => handleExperienceChange('startYear', val)}
              endMonth={experience.endMonth}
              setEndMonth={(val) => handleExperienceChange('endMonth', val)}
              endYear={experience.endYear}
              setEndYear={(val) => handleExperienceChange('endYear', val)}
              handleChange={() => {}}
              showMonths={true}
            />
            <TextField
              label={t('title')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={experience.title}
              onChange={(e) => handleExperienceChange('title', e.target.value)}
            />
            <TextField
              label={t('short_description')}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              value={experience.description}
              onChange={(e) =>
                handleExperienceChange('description', e.target.value)
              }
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isExperienceButtonDisabled}
              sx={{
                backgroundColor: isExperienceButtonDisabled
                  ? 'gray'
                  : 'primary.main',
                '&:hover': {
                  backgroundColor: isExperienceButtonDisabled
                    ? 'gray'
                    : 'primary.dark',
                },
                mt: 2,
              }}
            >
              {editIndex !== null ? `${t('update')}` : `${t('add_exp')}`}
            </Button>
            <Button
              variant="outlined"
              sx={{ mt: 2, marginLeft: 2 }}
              onClick={handleClose}
            >
              {t('cancel_change')}
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Modal delete  */}
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box sx={style1}>
          <DeleteOutlineOutlinedIcon color="error" sx={{ fontSize: 64 }} />
          <TypographyTitle title="Xác nhận" />

          <Typography sx={{ mt: 2, mb: 4 }}>
            Bạn có chắc chắn muốn xoá bài viết ?
          </Typography>
          <Button
            variant="outlined"
            color="error"
            onClick={handleConfirmDelete}
            sx={{ mr: 2 }}
          >
            Chắc chắn
          </Button>
          <Button variant="outlined" onClick={handleCloseDelete}>
            Không
          </Button>
        </Box>
      </Modal>
      {/* Modal Education ================================================================= */}
      <Modal open={openEdu} onClose={handleCloseEdu}>
        <Box sx={style} className="rounded-xl">
          <Box textAlign="center" mb={2}>
            <Typography fontSize="30px" fontWeight="bold">
              {editingEducationIndex !== null
                ? `${t('update')}`
                : `${t('add_edu')}`}
            </Typography>
          </Box>
          <Divider mb={2} />
          <Box component="form" onSubmit={handleEducationSave}>
            <TextField
              label={t('university')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={education.universityCollege}
              onChange={(e) =>
                handleEducationChange('universityCollege', e.target.value)
              }
            />
            <TextField
              label={t('country')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={education.country}
              onChange={(e) => handleEducationChange('country', e.target.value)}
            />
            <TextField
              label={t('degree')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={education.degree}
              onChange={(e) => handleEducationChange('degree', e.target.value)}
            />
            <DateSelector
              startMonth=""
              setStartMonth={() => {}}
              startYear={education.startYear}
              setStartYear={(val) => handleEducationChange('startYear', val)}
              endMonth=""
              setEndMonth={() => {}}
              endYear={education.endYear}
              setEndYear={(val) => handleEducationChange('endYear', val)}
              handleChange={() => {}}
              showMonths={false}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isEducationButtonDisabled}
              sx={{
                backgroundColor: isEducationButtonDisabled
                  ? 'gray'
                  : 'primary.main',
                '&:hover': {
                  backgroundColor: isEducationButtonDisabled
                    ? 'gray'
                    : 'primary.dark',
                },
                mt: 2,
              }}
            >
              {editingEducationIndex !== null
                ? `${t('update')}`
                : `${t('add_edu')}`}
            </Button>
            <Button
              variant="outlined"
              sx={{ mt: 2, marginLeft: 2 }}
              onClick={handleCloseEdu}
            >
              {t('cancel_change')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const style1 = {
  position: 'absolute',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '8px',
  textAlign: 'center',
};
export default ExperienceEducation;
