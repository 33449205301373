import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  TextField,
  Tooltip,
} from '@mui/material';
import { Close, Edit, Delete } from '@mui/icons-material';
import profileApi from '../../../../services/profileApi';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const UpdateQualification = ({ profile, setProfile, open, onClose }) => {
  const [qualification, setQualification] = useState({
    link: '',
    organization: '',
    summary: 'Summary',
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [editIndex, setEditIndex] = useState(null);

  const handleChange = (key, value) => {
    setQualification((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    const isFilled = Object.values(qualification).every((val) => val !== '');
    setIsButtonDisabled(!isFilled);
  }, [qualification]);

  const handleReset = () => {
    setQualification({
      link: '',
      organization: '',
      summary: 'Summary',
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const newQualification = {
      link: qualification.link,
      organization: qualification.organization,
      summary: qualification.summary,
    };

    let updatedQualifications;
    if (editIndex !== null) {
      updatedQualifications = profile.qualifications.map((exp, index) =>
        index === editIndex ? newQualification : exp
      );
    } else {
      updatedQualifications = profile.qualifications
        ? [...profile.qualifications, newQualification]
        : [newQualification];
    }

    try {
      await profileApi.updateQualification(updatedQualifications);
      setProfile((prevProfile) => ({
        ...prevProfile,
        qualifications: updatedQualifications,
      }));
      setQualification({
        link: '',
        organization: '',
        summary: 'Summary',
      });
      setEditIndex(null);
      toast.success('Chỉnh sửa chứng chỉ thành công');
    } catch (error) {
      console.error('Error response from server:', error.response);
      if (error.response && error.response.data) {
        toast.error(
          error.response.data.message ||
            'Something went wrong. Please try again.'
        );
      } else {
        toast.error('Something went wrong. Please try again.');
      }
    }
  };

  const handleEdit = (index) => {
    const qualification = profile.qualifications[index];
    setQualification({
      link: qualification.link,
      organization: qualification.organization,
      summary: qualification.summary,
    });
    setEditIndex(index);
  };

  const handleDelete = async (index) => {
    const updatedQualifications = profile.qualifications.filter(
      (_, expIndex) => expIndex !== index
    );

    try {
      await profileApi.updateQualification(updatedQualifications);
      setProfile((prevProfile) => ({
        ...prevProfile,
        qualifications: updatedQualifications,
      }));
      toast.success('Xoá chứng chỉ thành công');
    } catch (error) {
      console.error('Error response from server:', error.response);
      if (error.response && error.response.data) {
        toast.error(
          error.response.data.message ||
            'Something went wrong. Please try again.'
        );
      } else {
        toast.error('Something went wrong. Please try again.');
      }
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        className: 'bg-white rounded-lg shadow-lg',
      }}
    >
      <DialogTitle>
        <div className="flex justify-between items-center">
          <Typography
            variant="h6"
            className="font-semibold text-gray-700 text-xl"
          >
            Cập nhật chứng chỉ của bạn
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {profile?.qualifications?.length === 0 && (
            <Typography
              variant="body1"
              className="font-bold text-gray-800 mt-2"
            >
              Chứng chỉ của bạn sẽ hiển thị ở đây
            </Typography>
          )}
          {profile?.qualifications?.map((qualification, index) => (
            <ListItem key={index} className="bg-gray-100 rounded-lg my-2 p-3">
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    className="font-bold text-gray-800 truncate"
                  >
                    {qualification.organization}
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="body2"
                    className="text-gray-600 break-all"
                  >
                    {qualification.link}
                  </Typography>
                }
              />
              <Box className="ml-2 flex items-center">
                <IconButton
                  onClick={() => handleEdit(index)}
                  size="small"
                  className="text-blue-500"
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => handleDelete(index)}
                  size="small"
                  className="text-red-500"
                >
                  <Delete />
                </IconButton>
              </Box>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogContent dividers>
        <Box className="space-y-4">
          <Tooltip title="Nhập tên chứng chỉ của bạn">
            <TextField
              fullWidth
              label="Tên chứng chỉ"
              value={qualification.organization}
              onChange={(e) => handleChange('organization', e.target.value)}
              variant="outlined"
            />
          </Tooltip>
          <Tooltip title="Nhập đường dẫn thông tin chứng chỉ của bạn">
            <TextField
              fullWidth
              label="Đường dẫn"
              placeholder="https://example.com"
              value={qualification.link}
              onChange={(e) => handleChange('link', e.target.value)}
              variant="outlined"
            />
          </Tooltip>
          <TextField
            fullWidth
            label="Summary"
            value={qualification.summary}
            onChange={(e) => handleChange('summary', e.target.value)}
            variant="outlined"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleReset}
          variant="contained"
          className="text-white"
          disabled={isButtonDisabled}
          sx={{
            backgroundColor: isButtonDisabled ? 'gray' : 'bg-green-600',
            '&:hover': {
              backgroundColor: isButtonDisabled ? 'gray' : 'primary.dark',
            },
          }}
        >
          Làm mới
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={isButtonDisabled}
          className=" text-white"
          sx={{
            backgroundColor: isButtonDisabled ? 'gray' : 'bg-blue-600',
            '&:hover': {
              backgroundColor: isButtonDisabled ? 'gray' : 'primary.dark',
            },
          }}
        >
          {editIndex !== null ? 'Lưu thay đổi' : 'Thêm chứng chỉ'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateQualification;
