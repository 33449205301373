import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import profileApi from '../../../services/profileApi';
import { toast } from 'react-toastify';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import IconButton from '@mui/material/IconButton';
import { imageDb } from '../../../firebase/firebaseConfig';
import { v4 } from 'uuid';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useTranslation } from 'react-i18next';

function ProfileSetting() {
  const [profile, setProfile] = useState(null);
  const [name, setName] = useState('');
  const [avatar, setAvatar] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [description, setDescription] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState('');
  const [listCity, setListCity] = useState([]);
  const [state, setState] = useState('');
  const [listDistrict, setListDistrict] = useState([]);
  const { t } = useTranslation('profile');

  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    const getCity = async () => {
      let cityID = listCity.filter((item) => item.full_name === city)[0]?.id;
      let res = await profileApi.GetAllDistrict(cityID);
      setListDistrict(res);
    };
    getCity();
  }, [city]);

  useEffect(() => {
    const getData = async () => {
      try {
        let res = await profileApi.getUserProfile();
        let resC = await profileApi.GetAllCity();
        setListCity(resC);
        setProfile(res);
        setAvatar(res?.avatar || '');
        setCity(res?.address?.city || '');
        setState(res?.address?.state || '');
        setName(res.name || '');
        setEmail(res.email || '');
        setPhoneNumber(res.phoneNumber || '');
        setDescription(res.description || '');

        if (res?.address?.city || res?.address?.state !== '') {
          let cityID = resC.filter((item) => item.full_name === city)[0]?.id;
          let res = await profileApi.GetAllDistrict(cityID);
          setListDistrict(res);
        }
      } catch (error) {
        console.error(`${t('load_fail')}`);
        toast.error(`${t('load_fail')}`);
      }
    };
    getData();
  }, []);

  const initialData = useMemo(
    () => ({
      name: profile?.name || '',
      email: profile?.email || '',
      avatar: profile?.avatar || '',
      city: profile?.address?.city || '',
      state: profile?.address?.state || '',
      phoneNumber: profile?.phoneNumber || '',
      description: profile?.description || '',
    }),
    [profile]
  );

  const checkIfChanged = useCallback(() => {
    return (
      name !== initialData.name ||
      email !== initialData.email ||
      avatar !== initialData.avatar ||
      city !== initialData.city ||
      state !== initialData.state ||
      phoneNumber !== initialData.phoneNumber ||
      description !== initialData.description
    );
  }, [name, email, avatar, city, state, initialData, phoneNumber, description]);

  useEffect(() => {
    setIsButtonDisabled(!checkIfChanged());
  }, [checkIfChanged]);

  const handleSave = (e) => {
    e.preventDefault();
    handleProfile();
    handleAddress();
    setIsButtonDisabled(true);
  };
  const handleAddress = async () => {
    try {
      const dataAddress = {
        userId: profile?.id,
        city: city,
        state: state,
        country: 'Việt Nam',
      };
      await profileApi.UpdateAddress(dataAddress);
    } catch (error) {
      toast.error(`${t('update_error')}`);
    }
  };

  const handleProfile = async () => {
    try {
      const data = {
        name: name,
        email: email,
        avatar: avatar || '',
        phoneNumber: phoneNumber || '',
        description: description || '',
        taxCode: profile?.taxCode || '',
        isCompany: profile?.isCompany || false,
        skills: profile?.skills || [],
      };
      await profileApi.updateProfile(data);
      toast.success(`${t('update_success')}`);
    } catch (error) {
      toast.error(`${t('update_error')}`);
    }
  };
  const handleUpload = (e) => {
    if (e) {
      if (
        e.name.endsWith('.jpg') ||
        e.name.endsWith('.png') ||
        e.name.endsWith('.webp') ||
        e.name.endsWith('.jpeg')
      ) {
        setLoading(true);
        const imgRef = ref(imageDb, `file/${v4()}`);
        const uploadTask = uploadBytesResumable(imgRef, e);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error(error);
            toast.error(`${t('upload_fail')}`);
            setLoading(false);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setAvatar(url);
              setLoading(false);
              setUploadProgress(0);
            });
          }
        );
      } else {
        toast.error(`${t('image_fail')}`);
      }
    } else {
      toast.error(`${t('image_fail')}`);
    }
  };

  const handleChangeCity = (event) => {
    setCity(event.target.value);
    setState('');
  };
  const handleChangeDistrict = (event) => {
    setState(event.target.value);
  };

  return (
    <Box
      p={3}
      m={3}
      borderRadius={5}
      border="1px solid #ccc"
      component="form"
      onSubmit={handleSave}
    >
      {/* {loading && <LoadingComponent loading={loading} />} */}

      <Typography sx={{ fontSize: '2em' }} variant="h4" gutterBottom>
        {t('profile')}
      </Typography>
      <Box display="flex" alignItems="flex-start" gap={3} marginBottom={4}>
        <Box
          mt={2}
          flex={1}
          justifyContent="center"
          alignItems="center"
          position="relative"
          textAlign="center"
          sx={{
            width: '320px',
            height: '320px',
          }}
        >
          {avatar === '' ? (
            <Skeleton
              variant="rectangular"
              sx={{ width: '100%', height: '100%', borderRadius: '8px' }}
            />
          ) : (
            <img
              src={avatar}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '8px',
                objectFit: 'cover',
              }}
              alt="Avatar"
            />
          )}

          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="rgba(0, 0, 0, 0.5)"
            color="white"
            borderRadius={'8px'}
            sx={{
              opacity: 0,
              transition: 'opacity 0.3s',
              '&:hover': {
                cursor: 'pointer',
                opacity: 1,
              },
            }}
          >
            <input
              style={{ display: 'none' }}
              id="icon-button-file"
              type="file"
              onChange={(e) => handleUpload(e.target.files[0])}
            />
            <label htmlFor="icon-button-file">
              <IconButton
                color="inherit"
                aria-label="upload picture"
                component="span"
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </Box>
          {loading && (
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor="rgba(255, 255, 255, 0.7)"
              zIndex={1}
            >
              <CircularProgress
                variant="determinate"
                value={uploadProgress}
                size={60}
                thickness={4}
              />
            </Box>
          )}
        </Box>

        <Box flex={2}>
          <Box mb={2}>
            <TextField
              label={t('name')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              disabled
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>

          <Box mb={2}>
            <TextField
              label={t('phone')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" gap={3} mb={2} mt={1}>
        <Box flex={1}>
          <TextField label={t('country')} value="Việt Nam" disabled />
        </Box>
        <Box flex={1}>
          <FormControl sx={{ width: '76%' }}>
            <InputLabel id="city-select-label">{t('city')}</InputLabel>
            <Select
              label={t('city')}
              labelId="city-select-label"
              id="city-select"
              value={city}
              onChange={(event) => handleChangeCity(event)}
            >
              {listCity.length > 0 &&
                listCity.map((item, index) => (
                  <MenuItem key={item?.full_name} value={item?.full_name}>
                    {item?.full_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box flex={1}>
          <FormControl sx={{ width: '76%' }}>
            <InputLabel id="district-select-label">{t('ward')}</InputLabel>
            <Select
              label={t('ward')}
              labelId="district-select-label"
              value={state}
              onChange={(event) => {
                handleChangeDistrict(event);
              }}
            >
              {listDistrict.length > 0 &&
                listDistrict.map((item, index) => (
                  <MenuItem key={index} value={item?.full_name}>
                    {item?.full_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Divider mb={2} />
      <TextField
        label={t('describe_yourself')}
        placeholder={t('describe_yourself_placeholder')}
        variant="outlined"
        fullWidth
        multiline
        maxRows={3}
        margin="normal"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={isButtonDisabled}
        sx={{
          backgroundColor: isButtonDisabled ? 'gray' : 'primary.main',
          '&:hover': {
            backgroundColor: isButtonDisabled ? 'gray' : 'primary.dark',
          },
        }}
      >
        {t('save')}
      </Button>
    </Box>
  );
}

export default ProfileSetting;
