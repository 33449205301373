import React, { useEffect, useState } from 'react';
import projectApi from '../../../services/projectApi';
import ProjectList from '../component/projectList';
import { Box, Pagination } from '@mui/material';
import { useParams } from 'react-router-dom';
import LoadingComponent from '../../../components/LoadingComponent';

const ListProject = () => {
  const { status } = useParams();
  const [listProject, setListProject] = useState({});
  const [reload, setReload] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState('');
  const [statusId, setStatusId] = useState(parseInt(status));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      let pageSize = 5;
      let params = {
        Keyword: search,
        StatusId: statusId,
        IsDeleted: false,
        PageIndex: pageIndex,
        PageSize: 5,
      };
      setLoading(true);
      let res = await projectApi.GetAllProjectPending(params);
      setTotalPages(Math.ceil(res.totalItemsCount / pageSize));
      setListProject(res);
      setLoading(false);
    };
    getData();
  }, [pageIndex, statusId]);

  useEffect(() => {
    if (listProject?.items?.length == 3) {
      const getData = async () => {
        let pageSize = 5;
        let params = {
          Keyword: search,
          StatusId: statusId,
          IsDeleted: false,
          PageIndex: pageIndex,
          PageSize: 5,
        };
        setLoading(true);
        let res = await projectApi.GetAllProjectPending(params);
        setTotalPages(Math.ceil(res.totalItemsCount / pageSize));
        setListProject(res);
        setLoading(false);
      };
      getData();
    }
  }, [reload, listProject]);

  const handlePageChange = (event, value) => {
    setPageIndex(value);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const handleTabChange = (newStatusId) => {
    setStatusId(newStatusId);
    setPageIndex(1);
  };
  const handleSeach = async () => {
    setPageIndex(1);
    let params = {
      Keyword: search,
      StatusId: statusId,
      IsDeleted: false,
      PageIndex: 1,
      PageSize: 5,
    };
    let res = await projectApi.GetAllProjectPending(params);
    setTotalPages(Math.ceil(res?.totalItemsCount / 5));
    setListProject(res);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      {loading && <LoadingComponent loading={loading} />}

      <ProjectList
        listProject={listProject}
        setListProject={setListProject}
        setReload={setReload}
        handleSeach={handleSeach}
        search={search}
        handleChange={handleChange}
        handleTabChange={handleTabChange}
        statusId={statusId}
      />
      <Box p={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={pageIndex}
          onChange={handlePageChange}
          color="primary"
          showFirstButton
          showLastButton
        />
      </Box>
    </div>
  );
};

export default ListProject;
