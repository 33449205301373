import React, { useState, useEffect } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  TablePagination,
  Divider,
} from '@mui/material';
import {
  Info,
  AttachMoney,
  DateRange,
  AccountCircle,
  Receipt,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import paymentApi from '../../../services/paymentApi';
import { SentimentDissatisfied } from '@mui/icons-material';
import { formatDate } from '../../../utils/formatDate';

const PaymentHistory = () => {
  const [listPayment, setListPayment] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        let res = await paymentApi.getUserTransaction(page + 1, rowsPerPage);
        setListPayment(res.items);
        setTotalRecords(res.totalItemsCount);
      } catch (error) {
        console.error('Error fetching payment history:', error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewDetails = (payment) => {
    setSelectedPayment(payment);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedPayment(null);
  };

  if (loading) {
    return (
      <Box className="flex justify-center items-center h-96">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="p-6">
      {listPayment && listPayment.length > 0 ? (
        <>
          <TableContainer component={Paper} className="shadow-md rounded-lg">
            <Table>
              <TableHead className="bg-blue-500">
                <TableRow>
                  <TableCell className="text-white font-semibold">
                    Mã đơn mua
                  </TableCell>
                  <TableCell className="text-white font-semibold">
                    Loại hàng
                  </TableCell>
                  <TableCell className="text-white font-semibold">
                    Giá trị
                  </TableCell>
                  <TableCell className="text-white font-semibold">
                    Tên người thanh toán
                  </TableCell>
                  <TableCell className="text-white font-semibold">
                    Thời gian thanh toán
                  </TableCell>
                  <TableCell className="text-white font-semibold">
                    Hành động
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listPayment.map((payment) => (
                  <TableRow key={payment.id} className="hover:bg-gray-100">
                    <TableCell>{payment.orderCode}</TableCell>
                    <TableCell>{payment.type}</TableCell>
                    <TableCell>
                      {payment.totalMoney.toLocaleString('vi-VN')} VND
                    </TableCell>
                    <TableCell>{payment.counterAccountName}</TableCell>
                    <TableCell>
                      {formatDate(payment.transactionDateTime)}
                    </TableCell>
                    <TableCell>
                      <button
                        className="text-blue-500 hover:underline"
                        onClick={() => handleViewDetails(payment)}
                      >
                        Xem chi tiết
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage="Rows per page:"
            className="mt-4"
          />
        </>
      ) : (
        <Box className="flex flex-col items-center justify-center h-64 bg-white shadow-md rounded-lg p-6">
          <SentimentDissatisfied
            className="text-blue-500"
            style={{ fontSize: 50 }}
          />
          <Typography variant="h6" className="mt-4 text-gray-700 font-semibold">
            Chưa có lịch sử thanh toán
          </Typography>
          <Typography variant="body2" className="text-gray-500 mt-2">
            Bạn chưa thực hiện bất kỳ thanh toán nào.
          </Typography>
        </Box>
      )}

      {/* Drawer for payment details */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box className="w-80 p-4">
          <Box className="flex justify-between items-center mb-4">
            <Typography variant="h6">Chi tiết thanh toán</Typography>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {selectedPayment && (
            <List>
              <ListItem>
                <ListItemIcon>
                  <Receipt />
                </ListItemIcon>
                <ListItemText
                  primary="Mã đơn mua"
                  secondary={selectedPayment.orderCode}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AttachMoney />
                </ListItemIcon>
                <ListItemText
                  primary="Giá trị"
                  secondary={`${selectedPayment.totalMoney.toLocaleString(
                    'vi-VN'
                  )} VND`}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText
                  primary="Tên người thanh toán"
                  secondary={selectedPayment.counterAccountName}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText
                  primary="Loại hàng"
                  secondary={selectedPayment.type}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DateRange />
                </ListItemIcon>
                <ListItemText
                  primary="Thời gian thanh toán"
                  secondary={formatDate(selectedPayment.transactionDateTime)}
                />
              </ListItem>
              <Divider />
              <ListItemText primary="Email" secondary={selectedPayment.email} />
              <ListItemText
                primary="Số tài khoản"
                secondary={selectedPayment.counterAccountNumber}
              />
              <ListItemText
                primary="ID ngân hàng"
                secondary={selectedPayment.counterAccountBankId}
              />
              <ListItemText
                primary="Nội dung thanh toán"
                secondary={selectedPayment.description}
              />
            </List>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default PaymentHistory;
