import axiosClient from '../utils/axiosClient';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const paymentApi = {
  createPayment: async (data) => {
    try {
      const response = await axiosClient.post(
        `${BASE_URL}/api/Payment/Create`,
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  createPaymentProject: async (data) => {
    try {
      const response = await axiosClient.post(
        `${BASE_URL}/api/Payment/CreateBuyProject`,
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getUserTransaction: async (PageIndex, PageSize) => {
    try {
      const response = await axiosClient.get(
        `${BASE_URL}/api/Payment/UserTransactions?PageIndex=${PageIndex}&PageSize=${PageSize}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getAdminTransaction: async (data) => {
    try {
      const response = await axiosClient.get(
        `${BASE_URL}/api/Payment/Transactions`,
        {
          params: data,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default paymentApi;
