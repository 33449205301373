import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import reportApi from '../../../../services/reportApi';
import { useTranslation } from 'react-i18next';

const ReportModal = ({
  name,
  userId,
  open,
  onClose,
  onReport,
  type,
  projectId,
  bid,
}) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [description, setDescription] = useState('');
  const [proId, setProId] = useState(projectId);
  const [bidId, setBidId] = useState(bid);
  const { t } = useTranslation('project');

  useEffect(() => {
    setBidId(bid);
  }, [bid]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await reportApi.getReportCategoryByUser(type);
        setCategories(response);
      } catch (error) {
        console.error(`${t('load_fail')}`);
      }
    };

    fetchCategories();
  }, [type]);

  const handleReport = () => {
    onReport({
      ReportCategoryId: selectedCategory,
      Description: description,
      userReportedId: parseInt(userId),
      ProjectId: proId,
      BidId: bidId,
    });
    onClose();
  };

  const renderTextField = () => {
    switch (type) {
      case 'user':
        return (
          <TextField
            label={t('user_name')}
            variant="outlined"
            fullWidth
            disabled
            value={name}
            sx={{ mb: 2 }}
          />
        );
      case 'project':
        return (
          <TextField
            label={t('project_id')}
            variant="outlined"
            disabled
            fullWidth
            value={proId}
            onChange={(e) => setProId(e.target.value)}
            sx={{ mb: 2 }}
          />
        );
      case 'bid':
        return (
          <TextField
            label={t('bid_id')}
            variant="outlined"
            disabled
            fullWidth
            value={bidId}
            onChange={(e) => setBidId(e.target.value)}
            sx={{ mb: 2 }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          variant="h6"
          mb={2}
          className="text-2xl font-bold"
          gutterBottom
        >
          {t('report')}
        </Typography>
        {renderTextField()}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="category-label">{t('reason')}</InputLabel>
          <Select
            labelId="category-label"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            label={t('reason')}
          >
            {categories?.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label={t('description')}
          placeholder={t('describe')}
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleReport}>
          {t('submit')}
        </Button>
      </Box>
    </Modal>
  );
};

export default ReportModal;
