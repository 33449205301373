import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="modal-title"
          variant="h6"
          component="h2"
          sx={{ fontSize: '1.875rem' }}
        >
          {title}
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          {description}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <PriorityHighIcon color="warning" />
          Bạn chỉ nên thực hiện hành động này khi người dùng thực sự đã vi phạm
          điều khoản chính sách
        </Typography>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Button color="warning" onClick={onConfirm}>
            Xác nhận
          </Button>
          <Button onClick={onClose}>Hủy bỏ</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
