import { Box, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { UseChatState } from '../../../providers/ConnectContext';
import chatApi from '../../../services/chatApi';
import ListUser from './ListUser';
import BoxChat from './BoxChat';

const Chat = () => {
  const { conversationId, userId } = useParams();
  const currentUser = useSelector((state) => state.auth.login?.currentUser);
  const [user, setUser] = useState();
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [image, setImage] = useState('');

  const {
    chatSelect,
    setChatSelect,
    listMessages,
    setListMessage,
    userConnection,
    loadMoreMessages,
  } = UseChatState();

  useEffect(() => {
    const getUser = async () => {
      let res = await chatApi.GetInfo(userId);
      setUser(res);
    };
    getUser();
  }, [chatSelect, conversationId]);

  useEffect(() => {
    setChatSelect(conversationId);
  }, []);

  const hanldeSelectChat = async (conversationId, userId, senderId, isRead) => {
    setChatSelect(conversationId);
    if (senderId !== currentUser?.userId && isRead === 0) {
      await chatApi.markToRead(conversationId);
    }
    navigate(`/chat/${conversationId}/${userId}`);
  };

  const handleSendMessage = async (message) => {
    if (message.trim() === '' && image === '') return;

    if (image !== '') {
      let data = {
        conversationId: chatSelect,
        senderId: currentUser?.userId,
        messageText: '',
        messageType: 2,
        file: image,
        isRead: 0,
        sendDate: new Date().toISOString(),
      };
      setListMessage((msg) => [...msg, data]);
      await chatApi.SendMessage(data);
      setImage('');
    } else {
      let data = {
        conversationId: chatSelect,
        senderId: currentUser?.userId,
        messageText: message,
        messageType: 1,
        isRead: 0,
        sendDate: new Date().toISOString(),
      };
      setListMessage((msg) => [...msg, data]);
      await chatApi.SendMessage(data);
      setMessage('');
    }
  };

  return (
    <Box display="flex">
      <Box flex="1.2">
        <ListUser
          listUser={userConnection}
          currentUser={currentUser}
          hanldeSelectChat={hanldeSelectChat}
        />
      </Box>
      <Divider variant="middle" orientation="vertical" flexItem />
      <Box flex="3">
        <BoxChat
          messages={listMessages}
          currentUser={currentUser}
          user={user}
          handleSendMessage={handleSendMessage}
          setMessage={setMessage}
          message={message}
          loadMoreMessages={loadMoreMessages}
          setImage={setImage}
          image={image}
        />
      </Box>
    </Box>
  );
};

export default Chat;
