import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Container,
  Divider,
  LinearProgress,
  Typography,
  Alert,
  IconButton,
  Snackbar,
  Chip,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ProjectDescription from '../../../components/ProjectDescription';
import TypographyTitle from '../../../components/Typography/TypographyTitle';
import { formatCurrency } from '../../../utils/formatCurrency';
import FavoriteBorderTwoToneIcon from '@mui/icons-material/FavoriteBorderTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import { useSelector } from 'react-redux';
import projectApi from '../../../services/projectApi';
import { formatDate } from '../../../utils/formatDate';
import MapsUgcRoundedIcon from '@mui/icons-material/MapsUgcRounded';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';

const ShowList = ({ listProject, setReloadList }) => {
  const currentUser = useSelector((state) => state.auth.login?.currentUser);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleDetail = (id) => {
    navigate(`/detail/${id}`);
  };

  const handleAddFavorite = async (userId, projectId) => {
    try {
      const response = await projectApi.AddFavorite({ userId, projectId });
      setSnackbar({
        open: true,
        message: response.message,
        severity: 'success',
      });
      setReloadList((prev) => !prev);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to add project to favorites. Please try again.',
        severity: 'error',
      });
      console.error(error);
    }
  };

  const handleDeleteFavorite = async (userId, projectId) => {
    try {
      const response = await projectApi.DeleteFavorite({ userId, projectId });
      setSnackbar({
        open: true,
        message: response.message,
        severity: 'success',
      });
      setReloadList((prev) => !prev);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to remove project from favorites. Please try again.',
        severity: 'error',
      });
      console.error(error);
    }
  };

  const handleCopyLink = async (pid) => {
    navigator.clipboard.writeText(`${window.location.origin}/detail/${pid}`);
    setSnackbar({
      open: true,
      message: 'Đã sao chép đường dẫn',
      severity: 'success',
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Container maxWidth="lg">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          variant="filled"
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Typography variant="h5" gutterBottom fontSize={'1.25rem'}>
        Tổng số dự án: {listProject?.totalItemsCount}
      </Typography>
      <Divider />
      {(listProject === undefined || listProject == null) && <LinearProgress />}

      {listProject?.items?.length === 0 && (
        <Container maxWidth="md" style={{ marginTop: '20px' }}>
          <Alert severity="info">Hiện tại chưa có bản ghi nào.</Alert>
        </Container>
      )}

      {listProject?.items?.length !== 0 &&
        listProject?.items?.map((project, index) => (
          <Card
            key={index}
            variant="outlined"
            sx={{ mt: 3 }}
            className="rounded-lg bg-gray-50 shadow-lg"
          >
            <CardContent
              onClick={() => handleDetail(project?.id)}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#dddddd',
                },
              }}
            >
              <Box className="flex items-center">
                <TypographyTitle title={project?.title} color="#d48a04" />
                <Chip
                  label={
                    <Typography fontSize={'0.75rem'}>
                      {project?.projectStatus?.statusName}
                    </Typography>
                  }
                  style={{
                    backgroundColor: project?.projectStatus?.statusColor,
                    marginLeft: 8,
                  }}
                />
              </Box>
              <Typography variant="body2" color="textSecondary">
                Ngân sách: {formatCurrency(project?.minBudget)} -{' '}
                {formatCurrency(project?.maxBudget)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Thời gian: {project?.duration} ngày
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Tổng đấu thầu: {project?.totalBids} - Trung bình:{' '}
                {formatCurrency(project?.averageBudget)}
              </Typography>

              <ProjectDescription description={project?.description} />

              {project?.skill?.length > 0 && (
                <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1 }}>
                  Kỹ năng yêu cầu
                </Typography>
              )}

              {project?.skill?.length > 0 &&
                project?.skill?.map((item, index) => (
                  <Chip
                    key={index}
                    label={item}
                    variant="outlined"
                    color="primary"
                    size="small"
                    sx={{ mr: 1, mt: 1 }}
                  />
                ))}
              <Box className="flex justify-between items-center">
                <Typography sx={{ mt: 2 }} color="textSecondary">
                  Ngày tạo: {formatDate(project?.createdDate)}
                </Typography>
                <Typography className="self-end" color="textSecondary">
                  {project?.timeAgo}
                </Typography>
              </Box>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: 'space-evenly' }}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  cursor: 'pointer',
                  padding: '0px 12px 0px 12px',
                  '&:hover': {
                    backgroundColor: '#dadada',
                    borderRadius: '8px',
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  project.isFavorite
                    ? handleDeleteFavorite(currentUser?.userId, project?.id)
                    : handleAddFavorite(currentUser?.userId, project?.id);
                }}
              >
                {currentUser?.userId && (
                  <IconButton disabled>
                    {project.isFavorite ? (
                      <FavoriteTwoToneIcon sx={{ color: '#fad702' }} />
                    ) : (
                      <FavoriteBorderTwoToneIcon />
                    )}
                  </IconButton>
                )}
                <Typography variant="body2" color="textSecondary">
                  {project.isFavorite
                    ? 'Bỏ khỏi yêu thích'
                    : 'Thêm vào yêu thích'}
                </Typography>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                onClick={() => handleDetail(project?.id)}
                sx={{
                  cursor: 'pointer',
                  padding: '0px 12px 0px 12px',
                  '&:hover': {
                    backgroundColor: '#dadada',
                    borderRadius: '8px',
                  },
                }}
              >
                <IconButton disabled>
                  <MapsUgcRoundedIcon />
                </IconButton>
                <Typography variant="body2" color="textSecondary">
                  Đấu thầu
                </Typography>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                sx={{
                  cursor: 'pointer',
                  padding: '0px 12px 0px 12px',
                  '&:hover': {
                    backgroundColor: '#dadada',
                    borderRadius: '8px',
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopyLink(project?.id);
                }}
              >
                <IconButton disabled>
                  <ScreenShareOutlinedIcon />
                </IconButton>
                <Typography variant="body2" color="textSecondary">
                  Sao chép đường dẫn dự án
                </Typography>
              </Box>
            </CardActions>
          </Card>
        ))}
    </Container>
  );
};

export default ShowList;
