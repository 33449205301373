import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Modal,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Header from '../../Recruiter/LayOutRecruiter/Header';
import LoadingComponent from '../../../components/LoadingComponent';
import blogApi from '../../../services/blogApi';
import DataGrids from './DataGrids';
import { formatDateTime } from '../../../utils/formatDate';
import { useNavigate } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import TypographyTitle from '../../../components/Typography/TypographyTitle';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { truncateText } from '../../../utils/truncateText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const style = {
  position: 'absolute',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '8px',
  textAlign: 'center',
};
const style1 = {
  position: 'absolute',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '8px',
};

const ViewBlog = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listBlog, setListBlog] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [relateId, setReleateId] = useState(0);

  const [open, setOpen] = useState(false);
  const [openRelate, setOpenRelate] = useState(false);
  const [listRelate, setListRelate] = useState([]);
  const [cursor, setCursor] = useState('');
  const [BlogDetail, setBlogDetail] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setDeleteItemId(null);
  };
  const handleCloseRelate = () => {
    setOpenRelate(false);
    setReleateId(0);
    setListRelate([]);
    setCursor('');
  };

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      let res = await blogApi.GetAllBlog(1, 100);
      setListBlog(res?.items);
    };
    getData();
    setLoading(false);
  }, []);

  const columns = [
    {
      field: 'author',
      headerName: 'Tác giả',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
    },
    {
      field: 'categoryName',
      headerName: 'Chủ đề ',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'title',
      headerName: 'Tiêu đề',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
    },
    {
      field: 'createDate',
      headerName: 'Ngày tạo',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params) => (
        <>
          <Box mt={2}>
            <Typography> {formatDateTime(params?.row?.createDate)} </Typography>
          </Box>
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Hành động',
      flex: 1,
      headerAlign: 'center',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Box display={'flex'} alignItems="center" justifyContent="center">
          <IconButton onClick={(e) => handleEdit(params?.row?.blogId)}>
            <Typography color="blue">
              <EditOutlinedIcon />
            </Typography>
          </IconButton>
          <IconButton onClick={(e) => handleDelete(params?.row?.blogId)}>
            <Typography color="red">
              <DeleteOutlineOutlinedIcon />
            </Typography>
          </IconButton>
        </Box>
      ),
    },
    {
      field: 'a',
      headerName: 'Công khai',
      flex: 1,
      headerAlign: 'center',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Box display={'flex'} alignItems="center" justifyContent="center">
          <Switch
            checked={params?.row?.isPublished}
            value={params?.row?.isPublished}
            onChange={(e) => handleUpdatePublish(params?.row?.blogId)}
          />
        </Box>
      ),
    },
    {
      field: 'b',
      headerName: 'Bài viết liên quan',
      flex: 1,
      headerAlign: 'center',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Box display={'flex'} alignItems="center" justifyContent="center">
          <Tooltip title="Thêm bài viết liên quan">
            <Button
              color="success"
              onClick={(e) => handleOpenRelate(params?.row?.blogId)}
            >
              <AddCircleOutlineOutlinedIcon />
            </Button>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleOpenRelate = async (blogId) => {
    setReleateId(blogId);
    await getListBlogRealate(blogId);
    setOpenRelate(true);
  };

  const getListBlogRealate = async (blogId) => {
    if (blogId !== 0) {
      let response = await blogApi.Other(blogId, cursor);
      setCursor(response?.nextCursor);

      let res1 = await blogApi.GetBlogById(blogId);
      setBlogDetail(res1);
      const updatedList = response?.items.map((item) => {
        let related = res1?.relateds?.find(
          (rel) => rel?.blogId === item?.blogId
        );
        if (related) {
          return { ...item, selected: true };
        }
        return item;
      });
      setListRelate(updatedList);
    }
  };

  const handleGetMoreBlog = async () => {
    if (relateId !== 0 && cursor !== '') {
      let response = await blogApi.Other(relateId, cursor);
      setCursor(response?.nextCursor);
      response?.items.forEach((item) => {
        setListRelate((prev) => [...prev, item]);
      });
    }
  };

  const handleEdit = (id) => {
    navigate(`/update-blog/${id}`);
  };

  const handleUpdatePublish = async (id) => {
    await blogApi.UpdatePublish(id);
    updateItem(id);
  };

  const updateItem = (blogId) => {
    setListBlog((prev) =>
      prev.map((item) =>
        item.blogId === blogId
          ? { ...item, isPublished: !item.isPublished }
          : item
      )
    );
  };

  const handleDelete = (id) => {
    setDeleteItemId(id);
    setOpen(true);
  };

  const handleCreate = () => {
    navigate('/create-blog');
  };

  const handleConfirm = async () => {
    if (deleteItemId == null) {
      return;
    }
    await blogApi.DeleteBlog(deleteItemId);
    removeItem(deleteItemId);
    setDeleteItemId(null);
    setOpen(false);
  };

  const removeItem = (blogId) => {
    setListBlog((prev) => prev.filter((item) => item.blogId !== blogId));
  };

  const FeatureCard = ({ feature, onSelect }) => (
    <div onClick={onSelect}>
      <Card
        sx={{
          width: 250,
          bgcolor: feature.selected ? '#FFFFCC' : 'white',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <CardContent>
          <Tooltip title={feature?.blogName}>
            <Typography variant="h5" component="div">
              {truncateText(feature.blogName, 50)}
            </Typography>
          </Tooltip>
          <Typography fontSize="10px">
            <AccessTimeIcon /> {feature?.dateString}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );

  const handleClickIcon = (index) => {
    const updatedList = [...listRelate];
    updatedList[index].selected = !updatedList[index].selected;
    setListRelate(updatedList);
  };

  const handleAddRelate = async () => {
    let selectedBlogs = listRelate.filter((item) => item.selected);

    let data = {
      blogId: relateId,
      relatedBlogId: selectedBlogs.map((item) => item.blogId),
    };
    await blogApi.AddRelated(data);
    handleCloseRelate();
  };

  return (
    <Box className="p-4" sx={{ width: '100%', overflow: 'auto' }}>
      {loading && <LoadingComponent loading={loading} />}
      <Box display="flex" alignItems="center" mb={3}>
        <Header
          title="Danh sách bài viết"
          subtitle="Danh sách các bài viết trong trang web"
        />
        <Box ml="auto">
          <Tooltip title="Tạo bài viết" arrow>
            <Button
              sx={{
                bgcolor: '#28a745',
                color: '#fff',
                fontSize: '12px',
                '&:hover': { bgcolor: '#00CC00' },
              }}
              onClick={() => handleCreate()}
              startIcon={<AddIcon />}
            >
              Tạo bài viết
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Box>
        <DataGrids row={listBlog} column={columns} />
      </Box>

      <Modal open={openRelate} onClose={handleCloseRelate}>
        <Box sx={style1}>
          <Box textAlign="center">
            <TypographyTitle title="Bài viết liên quan" />
          </Box>
          <Box
            display="flex"
            mt={3}
            mb={3}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography> Tiêu đề : {BlogDetail?.title}</Typography>
              <Typography> Thời gian : {BlogDetail?.createTime}</Typography>
            </Box>
            <Button variant="contained" onClick={handleAddRelate}>
              Thêm bài viết liên quan
            </Button>
          </Box>
          <Divider />
          <Box sx={style2}>
            <Grid container spacing={2}>
              {listRelate &&
                listRelate?.length &&
                listRelate?.map((item, index) => (
                  <Grid item xs={12} sm={6} md={3} key={item?.blogId}>
                    <FeatureCard
                      feature={item}
                      onSelect={() => handleClickIcon(index)}
                    />
                  </Grid>
                ))}
            </Grid>
            <Box
              mt={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {cursor === '' || cursor === null ? null : (
                <Button variant="outlined" onClick={handleGetMoreBlog}>
                  Xem thêm
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box sx={style}>
          <DeleteOutlineOutlinedIcon color="error" sx={{ fontSize: 64 }} />
          <TypographyTitle title="Xác nhận" />

          <Typography sx={{ mt: 2, mb: 4 }}>
            Bạn có chắc chắn muốn xoá bài viết ?
          </Typography>
          <Button
            variant="outlined"
            color="error"
            onClick={handleConfirm}
            sx={{ mr: 2 }}
          >
            Chắc chắn
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Không
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ViewBlog;

const style2 = {
  p: 4,
  overflow: 'auto',
  maxHeight: '400px',
};
