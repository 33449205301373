import React, { useEffect, useRef, useState } from 'react';
import ListBlog from './ListBlog';
import { Box, Button, Typography } from '@mui/material';
import Footer from '../../../../components/Footer';
import blogApi from '../../../../services/blogApi';
import LoadingComponent from '../../../../components/LoadingComponent';
import ListBlogNew from './ListBlogNew';
import TopBarFreelancer from '../../../Freelancer/LayOut/TopBarFreelancer';

const HomeBlog = () => {
  const [listBlogHomePage, setListBlogHomePage] = useState([]);
  const [listBlogHot, setListBlogHot] = useState([]);
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState(4);
  const numberRef = useRef(number);

  useEffect(() => {
    numberRef.current = number; // Update the ref whenever chatSelect changes
  }, [number]);

  useEffect(() => {
    const getListBlog = async () => {
      try {
        setLoading(true);
        let params = {
          Top: number,
          IsHomePage: true,
        };
        const res = await blogApi.Gets(params);
        setListBlogHomePage(res);

        let params1 = {
          Top: number,
          isHot: true,
        };
        const res1 = await blogApi.Gets(params1);
        setListBlogHot(res1);

        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getListBlog();
  }, []);

  const handleGetMore = async () => {
    try {
      setLoading(true);
      setNumber(number + 4);
      let params1 = {
        Top: number + 4,
        isHot: true,
      };
      const res1 = await blogApi.Gets(params1);
      setListBlogHomePage(res1);

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box>
      <TopBarFreelancer />
      {loading && <LoadingComponent loading={loading} />}

      <Box p={4}>
        <Typography fontSize="30px" fontWeight="bold" mb={2}>
          Bài viết nổi bật
        </Typography>
        <ListBlog listBlog={listBlogHot} />

        <Typography mt={5} fontSize="30px" fontWeight="bold" mb={2}>
          Bài viết mới nhất
        </Typography>
        <ListBlogNew listBlog={listBlogHomePage} />
        <Box mt={5} display="flex" justifyContent="center">
          <Button variant="outlined" onClick={handleGetMore}>
            Xem thêm{' '}
          </Button>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default HomeBlog;
