import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Paper,
  Typography,
  IconButton,
  Divider,
  SpeedDial,
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { ROLES } from '../constaints/role';
import { UseChatState } from '../providers/ConnectContext';
import chatApi from '../services/chatApi';
import SendMessage from '../pages/Common/Chat/SendMessage';
import ListMessageAdmin from '../pages/Common/ChatWithAdmin/ListMessageAdmin';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import ChatIcon from '@mui/icons-material/Chat';
import BotIcon from '@mui/icons-material/SmartToy'; // Icon for chatbot
import { useLocation } from 'react-router-dom';

const actions = [
  {
    icon: <ChatIcon />,
    name: 'Chat với Admin',
    handler: 'handleFabClick',
  },
  { icon: <BotIcon />, name: 'Chat với Chatbot', handler: 'handleChatWithBot' },
];

const ChatBox = styled(Paper)({
  position: 'fixed',
  bottom: '1rem',
  right: '6rem',
  width: '400px',
  height: '450px',
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  zIndex: 1000,
});

const ChatWithAdmin = () => {
  const [user, setUser] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isChatOpenChatBot, setIsChatOpenChatBot] = useState(false);
  const currentUser = useSelector((state) => state.auth.login?.currentUser);
  // const [conversationId, setConversationId] = useState(-1);
  const [message, setMessage] = useState('');
  const [image, setImage] = useState('');
  const location = useLocation();
  const currentPath = location.pathname;
  const [chatBot, setChatBot] = useState(null);
  const {
    chatSelect,
    setChatSelect,
    listMessages,
    setListMessage,
    loadMoreMessages,
  } = UseChatState();

  useEffect(() => {
    if (currentUser !== null && user === null) {
      const getAdmin = async () => {
        try {
          let res = await chatApi.GetInfo(32);
          setUser(res);
        } catch (error) {
          console.error(error);
        }
      };
      getAdmin();
    }
  }, []);

  useEffect(() => {
    if (currentUser !== null && chatBot === null) {
      const getAdmin = async () => {
        try {
          let res = await chatApi.GetInfo(72);
          setChatBot(res);
        } catch (error) {
          console.error(error);
        }
      };
      getAdmin();
    }
  }, []);

  const handleFabClick = async () => {
    try {
      let res = await chatApi.CreateNewConversation(currentUser?.userId, 32);
      setChatSelect(res);
      setIsChatOpenChatBot(false);
      setIsChatOpen((prev) => !prev);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChatWithBot = async () => {
    try {
      let res = await chatApi.CreateNewConversation(currentUser?.userId, 72);
      setChatSelect(res);
      setIsChatOpen(false);
      setIsChatOpenChatBot((prev) => !prev);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSendMessage = async (message) => {
    if (message.trim() === '' && image === '') return;

    if (image !== '') {
      let data = {
        conversationId: chatSelect,
        senderId: currentUser?.userId,
        messageText: '',
        messageType: 2,
        file: image,
        isRead: 0,
        sendDate: new Date().toISOString(),
      };
      setListMessage((msg) => [...msg, data]);
      await chatApi.SendMessage(data);
      setImage('');
    } else {
      let data = {
        conversationId: chatSelect,
        senderId: currentUser?.userId,
        messageText: message,
        messageType: 1,
        isRead: 0,
        sendDate: new Date().toISOString(),
      };
      setListMessage((msg) => [...msg, data]);
      await chatApi.SendMessage(data);
      setMessage('');
    }
  };

  const handleChatClose = () => {
    setIsChatOpen(false);
  };
  const handleChatCloseChatBot = () => {
    setIsChatOpenChatBot(false);
  };

  const handleSendMessageChatBot = async () => {
    if (message.trim() === '') return;
    let data = {
      conversationId: chatSelect,
      senderId: currentUser?.userId,
      messageText: message,
      messageType: 1,
      isRead: 1,
      sendDate: new Date().toISOString(),
    };
    setListMessage((msg) => [...msg, data]);
    setMessage('');
    await chatApi.AskMessage(data);
  };

  return (
    <>
      {currentUser &&
        currentUser?.role !== ROLES.ADMIN &&
        !currentPath.startsWith('/chat') && (
          <>
            <SpeedDial
              sx={{
                position: 'fixed',
                bottom: '6rem',
                right: '1rem',
                color: 'var(--text-color)',
                '&:hover': {
                  color: '#fad702',
                },
                transform: 'scale(0.9)',
              }}
              ariaLabel="Chat options"
              icon={<SpeedDialIcon sx={{ height: '30px', width: '30px' }} />}
            >
              {actions &&
                actions?.map((action) => (
                  <SpeedDialAction
                    key={action?.name}
                    icon={action?.icon}
                    tooltipTitle={action?.name}
                    onClick={() => {
                      if (action?.handler === 'handleFabClick')
                        handleFabClick();
                      if (action?.handler === 'handleChatWithBot')
                        handleChatWithBot();
                    }}
                  />
                ))}
            </SpeedDial>

            {isChatOpenChatBot === true && (
              <ChatBox>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={1}
                  >
                    <BotIcon />
                    <Typography ml={2} fontSize="18px" fontWeight="bold">
                      ChatBot
                    </Typography>
                  </Box>
                  <IconButton onClick={handleChatCloseChatBot}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Divider />
                <ListMessageAdmin
                  user={chatBot}
                  loadMoreMessages={loadMoreMessages}
                  messages={listMessages}
                  currentUser={currentUser}
                />

                <SendMessage
                  setMessage={setMessage}
                  message={message}
                  handleSendMessage={handleSendMessageChatBot}
                  setImage={setImage}
                  image={image}
                />
              </ChatBox>
            )}

            {isChatOpen === true && (
              <ChatBox>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={1}
                  >
                    <Avatar
                      alt="Admin"
                      src={user?.avatar}
                      sx={{ width: 20, height: 20 }}
                    />
                    <Typography ml={2} fontSize="18px" fontWeight="bold">
                      {user?.name}
                    </Typography>
                  </Box>
                  <IconButton onClick={handleChatClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Divider />
                <ListMessageAdmin
                  user={user}
                  loadMoreMessages={loadMoreMessages}
                  messages={listMessages}
                  currentUser={currentUser}
                />

                <SendMessage
                  setMessage={setMessage}
                  message={message}
                  handleSendMessage={handleSendMessage}
                  setImage={setImage}
                  image={image}
                />
              </ChatBox>
            )}
          </>
        )}
    </>
  );
};

export default ChatWithAdmin;
