import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Badge,
  Box,
  Container,
  IconButton,
  Menu,
  Popover,
  Toolbar,
  Typography,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logOutSuccess } from '../../../redux/authSlice';
import profileApi from '../../../services/profileApi';
import LanguageSelector from '../../../components/language-selector';
import { UseChatState } from '../../../providers/ConnectContext';
import LogoText from '../../../assets/Logo_Text_GJ.png';

import ListUser from '../../Common/Chat/ListUser';
import chatApi from '../../../services/chatApi';
import CustomAvatar from '../../../components/CustomAvatar';
import { useTranslation } from 'react-i18next';

const TopBarAdmin = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const currentUser = useSelector((state) => state.auth.login?.currentUser);
  const [profile, setProfile] = useState();
  const [anchorElMessage, setAnchorElMessage] = useState(null);
  const { t } = useTranslation('topbar');

  const hanldCloseAll = () => {
    setAnchorElUser(null);
    setAnchorElMessage(null);
  };

  const {
    connection,
    connectionFirst,
    userConnection,
    numberOfMessage,
    setNumberOfMessage,
    setChatSelect,
  } = UseChatState();

  useEffect(() => {
    if (currentUser) {
      const getData = async () => {
        const res = await profileApi.getUserProfile();
        setProfile(res);
      };
      getData();
    }
  }, [currentUser]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = async () => {
    try {
      dispatch(logOutSuccess());
      localStorage.clear();
      if (connection != null) {
        await connection.stop();
      }
      if (connectionFirst != null) {
        await connectionFirst.stop();
      }
      navigate('/login');
    } catch (error) {}
  };

  const handleMessageClick = (event) => {
    setNumberOfMessage(0);
    setAnchorElMessage(event.currentTarget);
  };

  const handleMessageClose = () => {
    setAnchorElMessage(null);
  };

  const hanldeSelectChat = async (conversationId, userId, senderId, isRead) => {
    setChatSelect(conversationId);
    if (senderId !== currentUser?.userId && isRead === 0) {
      await chatApi.markToRead(conversationId);
    }
    hanldCloseAll();
    navigate(`/chat/${conversationId}/${userId}`);
  };

  return (
    <>
      <AppBar position="fixed" sx={{ bgcolor: 'white', zIndex: 1201 }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
            <Box
              component="a"
              onClick={() => navigate('/admin')}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                mr: 2,
              }}
            >
              <img
                src={LogoText}
                alt="GoodJobs Logo"
                style={{
                  height: '30px',
                }}
              />
            </Box>
            <Box display="flex" gap={2} alignItems="center">
              <IconButton onClick={handleMessageClick}>
                <Badge badgeContent={numberOfMessage} color="error">
                  <Tooltip title={t('message')}>
                    <MessageRoundedIcon />
                  </Tooltip>
                </Badge>
              </IconButton>

              <Popover
                id="message-popover"
                open={Boolean(anchorElMessage)}
                anchorEl={anchorElMessage}
                onClose={handleMessageClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box p={2}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className="font-bold text-xl">
                      {t('message')}
                    </Typography>
                  </Box>
                  <ListUser
                    listUser={userConnection}
                    hanldeSelectChat={hanldeSelectChat}
                    currentUser={currentUser}
                  />
                </Box>
              </Popover>

              <LanguageSelector />

              <Typography sx={{ fontWeight: 'bold', color: 'black' }}>
                {profile?.name}
              </Typography>

              <Tooltip title={t('account')}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {profile?.avatar === null ? (
                    <CustomAvatar name={profile?.name} />
                  ) : (
                    <Avatar alt="Profile Picture" src={profile?.avatar} />
                  )}
                </IconButton>
              </Tooltip>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleLogOut}>
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    <LogoutIcon sx={{ mr: 1 }} />
                    {t('log_out')}
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default TopBarAdmin;
