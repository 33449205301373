import { Box } from '@mui/material';
import React from 'react';
import AppBarChat from './AppBarChat';
import ListMessages from './ListMessages';
import SendMessage from './SendMessage';

const BoxChat = ({
  messages,
  currentUser,
  user,
  loadMoreMessages,
  handleSendMessage,
  setMessage,
  message,
  setImage,
  image,
}) => {
  return (
    <Box>
      <AppBarChat user={user} />
      <ListMessages
        user={user}
        loadMoreMessages={loadMoreMessages}
        messages={messages}
        currentUser={currentUser}
      />
      <Box mt={1}>
        <SendMessage
          setMessage={setMessage}
          message={message}
          handleSendMessage={handleSendMessage}
          setImage={setImage}
          image={image}
        />
      </Box>
    </Box>
  );
};

export default BoxChat;
