import { toast } from 'react-toastify';
import axiosClient from '../utils/axiosClient';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const profileApi = {
  getUserProfile: () => {
    try {
      const response = axiosClient.get(`${BASE_URL}/api/Users/Profile`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  getUserProfileById: async (userId, navigate) => {
    try {
      const response = await axiosClient.get(`${BASE_URL}/api/Users/GetUser`, {
        params: { uid: userId },
        headers: { accept: '*/*' },
      });
      if (response.id === 0) {
        navigate('/*');
      }
      return response;
    } catch (error) {
      if (error.response?.status === 400) {
        navigate('/*');
      }
      if (error.response?.status === 404) {
        navigate('/*');
      }
    }
  },

  changePassword: async (oldPassword, newPassword, newPasswordConfirm) => {
    try {
      const response = await axiosClient.put(
        `${BASE_URL}/api/Users/ChangePassword`,
        {
          oldPassword,
          newPassword,
          newPasswordConfirm,
        }
      );
      return response;
    } catch (error) {
      console.error(
        'Error during changePassword API call:',
        error.response || error.message
      );
      throw error;
    }
  },

  updateProfile: async (data) => {
    try {
      const response = await axiosClient.post(
        `${BASE_URL}/api/Users/Update`,
        data
      );
      return response;
    } catch (error) {
      console.error(
        'Error during updateProfile API call:',
        error.response || error.message
      );
      throw error;
    }
  },

  updateEducation: async (data) => {
    try {
      const response = await axiosClient.put(
        `${BASE_URL}/api/Users/UpdateEducation`,
        data
      );
      return response;
    } catch (error) {
      console.error(
        'Error during updateEducation API call:',
        error.response || error.message
      );
      throw error;
    }
  },

  updateExperience: async (data) => {
    try {
      const response = await axiosClient.put(
        `${BASE_URL}/api/Users/UpdateExperience`,
        data
      );
      return response;
    } catch (error) {
      console.error(
        'Error during updateEducation API call:',
        error.response || error.message
      );
      throw error;
    }
  },

  updateQualification: async (data) => {
    try {
      const response = await axiosClient.put(
        `${BASE_URL}/api/Users/UpdateQualification`,
        data
      );
      return response;
    } catch (error) {
      console.error(
        'Error during updateEducation API call:',
        error.response || error.message
      );
      throw error;
    }
  },

  submitRating: async (data) => {
    try {
      const response = await axiosClient.post(
        `${BASE_URL}/api/Ratings/Rate`,
        data
      );
      return response;
    } catch (error) {
      console.error(
        'Error during submitRating API call:',
        error.response || error.message
      );
      throw error;
    }
  },

  getUserProjectByStatus: async ({ statusId, userId, pageIndex, pageSize }) => {
    try {
      const params = {
        statusId: statusId,
        userId: userId,
        PageIndex: pageIndex,
        PageSize: pageSize,
      };

      const response = await axiosClient.get(
        `${BASE_URL}/api/Projects/GetByStatus`,
        { params }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  verifyEmail: async (data) => {
    try {
      const response = await axiosClient.post(
        `${BASE_URL}/api/Users/SendConfirmEmail?link=${data}`
      );
      return response;
    } catch (error) {
      console.error(
        'Error during verify API call:',
        error.response || error.message
      );
      throw error;
    }
  },
  GetAllPortfolio: async (userId) => {
    try {
      const response = await axiosClient.get(
        `${BASE_URL}/api/Users/Portfolios?userId=${userId}`
      );
      return response;
    } catch (error) {
      console.error(
        'Error during getAllPortfolio API call:',
        error.response || error.message
      );
      throw error;
    }
  },
  AddPortfolio: async (data, navigate) => {
    try {
      await axiosClient.post(`${BASE_URL}/api/Users/AddPortfolio`, data);
      navigate('/achievements');
    } catch (error) {
      throw error;
    }
  },
  GetPortfolioById: async (id) => {
    try {
      let res = await axiosClient.get(
        `${BASE_URL}/api/Users/GetPortfolio?id=${id}`
      );
      return res;
    } catch (error) {
      toast.error('Thất bại ');
    }
  },
  DeletePortfolio: async (id) => {
    try {
      let res = await axiosClient.delete(
        `${BASE_URL}/api/Users/DeletePortfolio?id=${id}`
      );
      return res;
    } catch (error) {
      toast.error('Thất bại ');
    }
  },
  UpdatePortfolio: async (data, navigate) => {
    try {
      let res = await axiosClient.put(
        `${BASE_URL}/api/Users/UpdatePortfolio`,
        data
      );
      navigate('/achievements');
      return res;
    } catch (error) {
      throw error;
    }
  },
  UpdateAddress: async (data) => {
    try {
      let res = await axiosClient.post(
        `${BASE_URL}/api/Users/UpdateAddress`,
        data
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  GetAllCity: async () => {
    try {
      let res = await axios.get(`https://esgoo.net/api-tinhthanh/1/0.htm`);
      return res?.data?.data;
    } catch (error) {
      toast.error('Thất bại ');
    }
  },
  GetAllDistrict: async (cityId) => {
    try {
      let res = await axios.get(
        `https://esgoo.net/api-tinhthanh/2/${cityId}.htm`
      );
      return res?.data?.data;
    } catch (error) {
      toast.error('Thất bại ');
    }
  },
};

export default profileApi;
