import {
  Avatar,
  Box,
  Tooltip,
  Typography,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { formatDate } from '../../../utils/formatDate';
import CustomAvatar from '../../../components/CustomAvatar';
import '../../../assets/css/zoom.css';

const ListMessages = ({ user, messages, currentUser, loadMoreMessages }) => {
  const height = window.innerHeight - 200;

  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const [count, setCount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [image, setImage] = useState('');
  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const handleScroll = async () => {
    if (messagesContainerRef.current.scrollTop === 0 && !isFetching) {
      setIsFetching(true);
      await loadMoreMessages();
      setCount(count + 1);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isFetching]);

  useEffect(() => {
    if (count === 0) {
      scrollToBottom();
    }
  }, [messages, scrollToBottom]);

  const handleImageClick = (link) => {
    setImage(link);
    setIsFullscreen(!isFullscreen);
  };

  return (
    <Box
      p={1}
      ref={messagesContainerRef}
      sx={{ height: height, overflowY: 'auto' }}
    >
      {isFetching && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            mt: 2,
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      )}
      {messages.map((message, index) =>
        message.senderId === currentUser.userId ? (
          <Box mt={2} key={index}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Tooltip title={formatDate(new Date(message?.sendDate))}>
                <Box display="flex" alignItems="center" gap={2}>
                  {message?.messageType === 2 ? (
                    <img
                      onClick={(e) => handleImageClick(message?.file)}
                      src={message?.file}
                      style={{
                        maxWidth: 200,
                        cursor: 'pointer',
                        maxHeight: 200,
                      }}
                    />
                  ) : (
                    <Box bgcolor="#EEEEEE" borderRadius="10px" p={1}>
                      <Typography>{message.messageText}</Typography>
                    </Box>
                  )}

                  {currentUser?.avatar === null ||
                  currentUser?.avatar === '' ? (
                    <CustomAvatar name={currentUser?.name} />
                  ) : (
                    <Avatar alt="Avatar" src={currentUser?.avatar} />
                  )}
                </Box>
              </Tooltip>
            </Box>
          </Box>
        ) : (
          <Box mt={2} key={index}>
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <Tooltip title={formatDate(new Date(message?.sendDate))}>
                <Box display="flex" alignItems="center" gap={2}>
                  {user?.avatar === null || user?.avatar === '' ? (
                    <CustomAvatar name={user?.name} />
                  ) : (
                    <Avatar alt="Avatar" src={user?.avatar} />
                  )}
                  {message?.messageType === 2 ? (
                    <img
                      onClick={(e) => handleImageClick(message?.file)}
                      src={message?.file}
                      style={{
                        maxWidth: 200,
                        cursor: 'pointer',
                        maxHeight: 200,
                      }}
                    />
                  ) : (
                    <Box bgcolor="#EEEEEE" borderRadius="10px" p={1}>
                      <Typography>{message.messageText}</Typography>
                    </Box>
                  )}
                </Box>
              </Tooltip>
            </Box>
          </Box>
        )
      )}
      <div ref={messagesEndRef} />
      {isFullscreen && (
        <div className="fullscreen" onClick={handleImageClick}>
          <img src={image} alt="Fullscreen" />
        </div>
      )}
    </Box>
  );
};

export default ListMessages;
