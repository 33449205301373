import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import projectApi from '../../../services/projectApi';
import ShowList from '../../Recruiter/ListProjectRecruiter/ShowList';
import categoryApi from '../../../services/categoryApi';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useSelector } from 'react-redux';
import ShowListSkeleton from '../../../components/Skeleton/ShowListSkeleton';
import { useTranslation } from 'react-i18next';

const Filter = () => {
  const currentUser = useSelector((state) => state.auth.login?.currentUser);
  const { idCate } = useParams();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [listProject, setListProject] = useState(null);
  const [listCategory, setListCategory] = useState([]);
  const [categoryId, setCategoryId] = useState(parseInt(idCate));
  const [listSkill, setListSkill] = useState([]);
  const [listSkillSelected, setListSkillSelected] = useState([]);
  const [duration, setDuration] = useState(0);
  const [minBudget, setMinBudget] = useState(0);
  const [maxBudget, setMaxBudget] = useState(0);
  const [reloadList, setReloadList] = useState(false);
  const { t } = useTranslation('project_detail');
  const validCategoryIds = listCategory?.map((item) => item.id) || [];
  const selectedCategoryId = validCategoryIds.includes(categoryId)
    ? categoryId
    : 0;
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (!firstLoad) {
      setFirstLoad(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [firstLoad]);

  useEffect(() => {
    const getData = async () => {
      let params = {
        PageIndex: page,
        PageSize: 5,
        CategoryId: categoryId === 0 ? null : categoryId,
        MinBudget: minBudget === 0 ? null : minBudget,
        MaxBudget: maxBudget === 0 ? null : maxBudget,
        Duration: duration === 0 ? null : duration,
      };
      const res = await projectApi.SearchHomePage(
        params,
        listSkillSelected,
        currentUser?.userId
      );
      setListProject(res);
      setTotalPage(Math.ceil(res?.totalItemsCount / 5));
    };

    getData();
  }, [idCate, page, reloadList]);

  useEffect(() => {
    const getData = async () => {
      let res = await categoryApi.GetAllCategory();
      setListCategory(res);
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        let res = await categoryApi.GetByCategoryId(categoryId);
        setListSkill(res?.items);
      } catch (error) {}
    };
    getData();
  }, [categoryId]);

  const handleSelect = (item) => {
    if (listSkillSelected.includes(item.skillName)) {
      setListSkillSelected(
        listSkillSelected.filter((skillName) => skillName !== item.skillName)
      );
    } else {
      setListSkillSelected([...listSkillSelected, item.skillName]);
    }
  };

  const handleChangeCategory = (id) => {
    setListSkillSelected([]);
    setCategoryId(id);
  };

  const handleFilter = async () => {
    let params = {
      PageIndex: 1,
      PageSize: 5,
      CategoryId: categoryId === 0 ? null : categoryId,
      MinBudget: minBudget === 0 ? null : minBudget,
      MaxBudget: maxBudget === 0 ? null : maxBudget,
      Duration: duration === 0 ? null : duration,
    };
    const res = await projectApi.SearchHomePage(
      params,
      listSkillSelected,
      currentUser?.userId
    );
    setListProject(res);
    setPage(1);
    setTotalPage(Math.ceil(res?.totalItemsCount / 5));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleClear = () => {
    setListSkillSelected([]);
    setCategoryId(0);
    setDuration(0);
    setMinBudget(0);
    setMaxBudget(0);
    setReloadList(!reloadList);
  };

  return (
    <>
      <Box display="flex" mt={3} ml={3}>
        <Box flex="1" ml={3} mr={3}>
          <Box bgcolor="#F9FAFB" borderRadius="8px" p={4} boxShadow={1}>
            <Typography
              variant="h6"
              fontWeight="bold"
              display="flex"
              alignItems="center"
              gutterBottom
              sx={{ mb: 3 }}
            >
              <FilterListIcon sx={{ mr: 1 }} /> {t('filters')}
            </Typography>

            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                {t('category')}
              </Typography>
              <Select
                fullWidth
                sx={{ bgcolor: '#FFFFFF', mb: 2, borderRadius: '8px' }}
                value={selectedCategoryId}
                onChange={(e) => handleChangeCategory(e.target.value)}
                displayEmpty
              >
                <MenuItem value={0}>{t('all')}</MenuItem>
                {listCategory
                  ?.filter((item) => item.id !== 4)
                  .map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.categoryName}
                    </MenuItem>
                  ))}
              </Select>
            </Box>

            {listSkill?.length > 0 && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  {t('list_skill')}
                </Typography>
                <Grid container spacing={2}>
                  {listSkill.map((item) => (
                    <Grid item xs={6} key={item.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={listSkillSelected.includes(item.skillName)}
                            onChange={() => handleSelect(item)}
                            color="primary"
                            sx={{
                              '& .MuiSvgIcon-root': { fontSize: 20 },
                              mr: 1,
                            }}
                          />
                        }
                        label={item.skillName}
                        sx={{ mb: 1 }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                {t('time')}
              </Typography>
              <OutlinedInput
                type="number"
                value={duration}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value >= 0) {
                    setDuration(value);
                  }
                }}
                sx={{
                  bgcolor: '#FFFFFF',
                  mb: 2,
                  borderRadius: '8px',
                  '&:hover': { borderColor: '#000000' },
                  '& .MuiOutlinedInput-input': { padding: '12px 16px' },
                }}
                endAdornment={
                  <InputAdornment position="end">{t('day')}</InputAdornment>
                }
                fullWidth
              />
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                {t('budget_min')}
              </Typography>
              <OutlinedInput
                type="number"
                value={minBudget}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value >= 0) {
                    setMinBudget(value);
                  }
                }}
                sx={{
                  bgcolor: '#FFFFFF',
                  mb: 2,
                  borderRadius: '8px',
                  '&:hover': { borderColor: '#000000' },
                  '& .MuiOutlinedInput-input': { padding: '12px 16px' },
                }}
                endAdornment={
                  <InputAdornment position="end">VND</InputAdornment>
                }
                fullWidth
              />
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                {t('budget_max')}
              </Typography>
              <OutlinedInput
                type="number"
                value={maxBudget}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value >= 0) {
                    setMaxBudget(value);
                  }
                }}
                sx={{
                  bgcolor: '#FFFFFF',
                  mb: 2,
                  borderRadius: '8px',
                  '&:hover': { borderColor: '#000000' },
                  '& .MuiOutlinedInput-input': { padding: '12px 16px' },
                }}
                endAdornment={
                  <InputAdornment position="end">VND</InputAdornment>
                }
                fullWidth
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  bgcolor: '#0055D4',
                  '&:hover': {
                    bgcolor: '#003BB3',
                  },
                }}
                onClick={() => handleFilter()}
              >
                {t('filter')}
              </Button>
              <Button onClick={() => handleClear()}>
                {t('remove_filter')}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box flex="3">
          {!listProject && <ShowListSkeleton />}
          <ShowList listProject={listProject} setReloadList={setReloadList} />
          <Box
            mt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Pagination
              count={totalPage}
              defaultPage={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Filter;
