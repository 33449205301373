import {
  Box,
  Button,
  Grid,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Header from '../../Recruiter/LayOutRecruiter/Header';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import PieChart from './component/PieChart';
import Statistic from '../../../services/adminApi/statistic';
import LoadingComponent from '../../../components/LoadingComponent';
import LineChart from './component/LineChart';
import TypographyHeader from '../../../components/Typography/TypographyHeader';
import TypographyTitle from '../../../components/Typography/TypographyTitle';
import {
  formatDateReport,
  formatDateTracking,
} from '../../../utils/formatDate';
import DataGrids from './component/DataGrids';
import categoryApi from '../../../services/categoryApi';
import LineChartRevenue from './component/LineChartRevenue';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useTranslation } from 'react-i18next';
const HomeAdmin = () => {
  const [categoryStatistic, setCategoryStatistic] = useState([]);
  const [totalCategory, setTotalCategory] = useState(0);
  const [totalProject, setTotalProject] = useState(0);
  const [freelacerCount, setFreelacerCount] = useState(0);
  const [recruiterCount, setRecruiterCount] = useState(0);
  const [totalUser, setTotalUser] = useState(0);

  const [userStatistic, setUserStatistic] = useState([]);
  const [loading, setLoading] = useState(false);

  const [newUserStatistic, setNewUserStatistic] = useState([]);

  const [typeStatistic, setTypeStatistic] = useState('1');
  const [StatisticUsers, setStatisticUsers] = useState([]);
  const [StatisticSkills, setStatisticSkills] = useState([]);
  const [StatisticProjects, setStatisticProjects] = useState([]);

  const [time, setTime] = useState(new Date());
  const [statisticRevenue, setStatisticRevenue] = useState([]);
  const { t } = useTranslation('dashboard');

  // const [columns, setColumns] = useState();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const GetData = async () => {
      if (typeStatistic === '1') {
        let res = await Statistic.StatisticUsers(1, 10);
        setStatisticUsers(res?.data?.items);
      }
      if (typeStatistic === '2') {
        let res = await Statistic.StatisticSkills(1, 10);
        setStatisticSkills(res?.data?.items);
      }
      if (typeStatistic === '3') {
        let res = await Statistic.StatisticProjects(1, 10);
        setStatisticProjects(res?.data?.items);
      }
    };
    GetData();
  }, [typeStatistic]);

  const columnUser = [
    {
      field: 'userName',
      headerName: `${t('user')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },
    {
      field: 'role',
      headerName: `${t('role')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },
    {
      field: 'totalCompletedProjects',
      headerName: `${t('total_project_completed')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },

    {
      field: 'totalPositiveRatings',
      headerName: `${t('rating_positive')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },
    {
      field: 'totalNegativeRatings',
      headerName: `${t('rating_negative')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },
  ];

  const columnSkill = [
    {
      field: 'skillName',
      headerName: `${t('skill')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },
    {
      field: 'categoryName',
      headerName: `${t('category')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },
    {
      field: 'totalApprovedProject',
      headerName: `${t('total_project')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },

    {
      field: 'totalUsers',
      headerName: `${t('total_user')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },
  ];

  const columnProject = [
    {
      field: 'categoryName',
      headerName: `${t('category')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },
    {
      field: 'totalProjects',
      headerName: `${t('total_project')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },
    {
      field: 'minimumBudget',
      headerName: `${t('budget_min')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },

    {
      field: 'maximumBudget',
      headerName: `${t('budget_max')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },

    {
      field: 'averageBudget',
      headerName: `${t('budget_avg')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },

    {
      field: 'minimumDuration',
      headerName: `${t('duration_min')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },

    {
      field: 'maximumDuration',
      headerName: `${t('duration_max')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },
    {
      field: 'averageDuration',
      headerName: `${t('duration_avg')}`,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>{params.value}</Box>
      ),
    },
  ];

  useEffect(() => {
    const getData = async () => {
      let res = await categoryApi.GetByStatus();
      setTotalCategory(res?.totalItemsCount);
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      let resTracking = await Statistic.Tracking(formatDateTracking(time));
      ConvertDataLineChartRevenue(resTracking);
    };
    getData();
  }, [time]);

  useEffect(() => {
    const GetData = async () => {
      try {
        const response = await Statistic.CategoriesPieChartData();
        const mockPieData = response?.data.map((item) => ({
          id: item?.categoryName,
          label: item?.categoryName,
          value: item?.totalProjects,
        }));
        let total = 0;
        response?.data?.forEach((item) => (total += item?.totalProjects));
        setTotalProject(total);
        setCategoryStatistic(mockPieData);

        let res = await Statistic.UsersPieChartData();
        setUserStatistic(res?.data?.data);
        setFreelacerCount(res?.data?.freelacerCount);
        setRecruiterCount(res?.data?.recruiterCount);
        setTotalUser(res?.data?.totalUser);
        let resLine = await Statistic.NewUserData();
        ConvertDataLineChart(resLine?.data);
      } catch (error) {
        console.error(error);
      }
    };
    GetData();
  }, []);
  const ConvertDataLineChartRevenue = (data) => {
    const mockLineData = [
      {
        id: `${t('revenue')}`,
        data: data.map((item) => ({
          x: formatDateTracking(item?.endDate),
          y: item?.totalMoney,
        })),
      },
    ];
    setStatisticRevenue(mockLineData);
  };

  const ConvertDataLineChart = (data) => {
    const mockLineData = [
      {
        id: `${t('total_users')}`,
        data: data.map((item) => ({
          x: item.formattedDate,
          y: item.totalUserCount,
        })),
      },
      {
        id: `${t('total_freelancer')}`,
        data: data.map((item) => ({
          x: item.formattedDate,
          y: item.freelancerCount,
        })),
      },
      {
        id: `${t('total_recruiter')}`,
        data: data.map((item) => ({
          x: item.formattedDate,
          y: item.recruiterCount,
        })),
      },
    ];
    setNewUserStatistic(mockLineData);
  };

  const handleOpen = () => setOpen(true);

  const handleDownload = async (isChatGpt) => {
    try {
      setLoading(true);
      const response = await Statistic.ExportStatistic(isChatGpt);
      const url = window.URL.createObjectURL(
        new Blob([response], { type: response.type })
      );
      const link = document.createElement('a');
      link.href = url;
      var date = new Date();

      link.setAttribute(
        'download',
        `${t('report_date')}` + formatDateReport(date.toString()) + '.xlsx'
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Box sx={style}>
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header title={t('dashboard')} subtitle={t('sub_dashboard')} />
        <Box>
          <Button
            sx={{
              backgroundColor: '#3e4396',
              color: '#e0e0e0',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 20px',
            }}
            onClick={handleOpen}
          >
            <DownloadOutlinedIcon sx={{ mr: '10px' }} />
            {t('export_report')}
          </Button>
        </Box>
      </Box>
      {/* Doanh thu  */}
      <Box
        m={5}
        sx={{
          height: '500px',
          bgcolor: 'white',
          borderRadius: '20px',
        }}
      >
        <Box pt={3} pl={3} display="flex" justifyContent="space-between">
          <TypographyHeader title={t('chart_revenue')} />
          <Box
            sx={{
              border: '1px solid #E0E0E0',
              mr: 5,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <CalendarMonthIcon />
            <DatePicker
              selected={time}
              onChange={(date) => {
                if (date === '' || date === null) {
                  setTime(new Date());
                } else {
                  setTime(date);
                }
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            height: '450px',
          }}
        >
          <LineChartRevenue
            data={statisticRevenue}
            type={t('chart_revenue_month')}
          />
        </Box>
      </Box>

      {/* Category======================================================  */}

      <Box
        sx={{
          m: 3,
        }}
      >
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item md={4}>
            <Box
              p={2}
              sx={{
                bgcolor: 'white',
                border: '1px solid #E0E0E0',
                borderRadius: '8px',
              }}
            >
              <Typography fontSize="18px" fontWeight="bold">
                {t('total_category')}
              </Typography>

              <Box mt={2} ml={2} textAlign={'center'}>
                <Typography fontSize="50px" fontWeight="bold" color="#008000">
                  {totalCategory}
                </Typography>
                <Typography>{t('category_lower')}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item md={4}>
            <Box
              p={2}
              sx={{
                bgcolor: 'white',
                border: '1px solid #E0E0E0',
                borderRadius: '8px',
              }}
            >
              <Typography fontSize="18px" fontWeight="bold">
                {t('total_project')}
              </Typography>

              <Box mt={2} ml={2} textAlign={'center'}>
                <Typography fontSize="50px" fontWeight="bold" color="#008000">
                  {totalProject}
                </Typography>
                <Typography>{t('project')}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          m: 3,
          bgcolor: 'white',
          borderRadius: '20px',
        }}
      >
        <Box p={3}>
          <TypographyHeader title={t('chart_category')} />
        </Box>
        <Box m={2} height="450px">
          <PieChart data={categoryStatistic} />
        </Box>
      </Box>

      {/* User======================================================  */}
      <Box
        sx={{
          m: 3,
        }}
      >
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item md={4}>
            <Box
              p={2}
              sx={{
                bgcolor: 'white',
                border: '1px solid #E0E0E0',
                borderRadius: '8px',
              }}
            >
              <Typography fontSize="18px" fontWeight="bold">
                {t('total_users')}
              </Typography>

              <Box mt={2} ml={2} textAlign={'center'}>
                <Typography fontSize="50px" fontWeight="bold" color="#008000">
                  {totalUser}
                </Typography>
                <Typography>{t('users')} </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item md={4}>
            <Box
              p={2}
              sx={{
                bgcolor: 'white',
                border: '1px solid #E0E0E0',
                borderRadius: '8px',
              }}
            >
              <Typography fontSize="18px" fontWeight="bold">
                {t('total_recruiter')}
              </Typography>

              <Box mt={2} ml={2} textAlign={'center'}>
                <Typography fontSize="50px" fontWeight="bold" color="#008000">
                  {recruiterCount}
                </Typography>
                <Typography>{t('users')} </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item md={4}>
            <Box
              p={2}
              sx={{
                bgcolor: 'white',
                border: '1px solid #E0E0E0',
                borderRadius: '8px',
              }}
            >
              <Typography fontSize="18px" fontWeight="bold">
                {t('total_freelancer')}
              </Typography>

              <Box mt={2} ml={2} textAlign={'center'}>
                <Typography fontSize="50px" fontWeight="bold" color="#008000">
                  {freelacerCount}
                </Typography>
                <Typography>{t('users')} </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          m: 3,
          bgcolor: 'white',
          borderRadius: '20px',
        }}
      >
        <Box p={3}>
          <TypographyHeader title={t('role_chart')} />
        </Box>
        <Box m={2} height="450px">
          <PieChart data={userStatistic} />
        </Box>
      </Box>

      <Box
        m={5}
        sx={{
          height: '500px',
          bgcolor: 'white',
          borderRadius: '20px',
        }}
      >
        <Box pt={3} pl={3}>
          <TypographyHeader title={t('new_user_chart')} />
        </Box>
        <Box
          sx={{
            height: '450px',
          }}
        >
          <LineChart data={newUserStatistic} type={t('last_30_days')} />
        </Box>
      </Box>

      <Box
        m={5}
        sx={{
          bgcolor: 'white',
          borderRadius: '20px',
        }}
      >
        <Box pt={3} pl={3}>
          <TypographyHeader title={t('dash_board')} />
        </Box>
        <Box m={3}>
          <Select
            sx={{ bgcolor: '#FFFFFF', mt: 2 }}
            value={typeStatistic}
            onChange={(e) => setTypeStatistic(e.target.value)}
          >
            <MenuItem value="1">{t('dashboard_user')}</MenuItem>
            <MenuItem value="2">{t('dashboard_skill')}</MenuItem>
            <MenuItem value="3">{t('dashboard_project')} </MenuItem>
          </Select>
          <Box
            sx={{
              mt: 3,
              height: '480px',
            }}
          >
            {typeStatistic === '1' && (
              <>
                <DataGrids row={StatisticUsers} column={columnUser} />
              </>
            )}
            {typeStatistic === '2' && (
              <>
                <DataGrids row={StatisticSkills} column={columnSkill} />
              </>
            )}
            {typeStatistic === '3' && (
              <>
                <DataGrids row={StatisticProjects} column={columnProject} />
              </>
            )}
          </Box>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          {loading && <LoadingComponent loading={loading} />}
          <Box textAlign="center" mb={2}>
            <Typography color="blue">
              {' '}
              <DownloadOutlinedIcon fontSize="large" />
            </Typography>
            <TypographyTitle title={t('export_report')} />
          </Box>
          <Box
            bgcolor="#FFFF66"
            p={2}
            border="1px solid #110000"
            borderRadius="20px"
          >
            <Typography>
              <Typography fontWeight="bold">{t('attend')}:</Typography>
              {t('attend_detail')}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Button
              onClick={(e) => handleDownload(false)}
              sx={{
                flexGrow: 1,
                bgcolor: '#6666FF',
                marginRight: '8px',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textTransform: 'none',
                '&:hover': {
                  bgcolor: '#5050CC',
                },
              }}
            >
              <DownloadOutlinedIcon sx={{ marginRight: '8px' }} />
              {t('export_report')}
            </Button>
            <Button
              onClick={(e) => handleDownload(true)}
              variant="outlined"
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textTransform: 'none',
                '&:hover': {
                  bgcolor: '#f0f0f0',
                  borderColor: '#6666FF',
                  color: '#6666FF',
                },
              }}
            >
              <DownloadOutlinedIcon sx={{ marginRight: '8px' }} />
              {t('export_ChatGPT')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

const style = {
  p: 4,
  overflow: 'auto',
  maxHeight: window.innerHeight - 80,
};

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default HomeAdmin;
