import React from 'react';
import { Container, Typography, Card, CardContent } from '@mui/material';

const Terms = () => {
  return (
    <Container maxWidth="md" className="mt-8 mb-16">
      <Card className="shadow-lg border border-gray-200">
        <CardContent className="p-8">
          <Typography
            variant="h4"
            component="h1"
            className="text-center mb-8 font-bold"
            sx={{ fontSize: '2.5rem' }}
          >
            Điều Khoản Sử Dụng
          </Typography>

          <Typography
            variant="h6"
            component="h2"
            className="mt-6 mb-4 font-semibold"
            sx={{ fontSize: '1.5rem' }}
          >
            Giới Thiệu
          </Typography>
          <Typography variant="body1" className="mb-6">
            Chào mừng bạn đến với Goodjobs. Bằng việc truy cập hoặc sử dụng nền
            tảng của chúng tôi, bạn đồng ý tuân thủ và bị ràng buộc bởi các điều
            khoản và điều kiện sau đây. Vui lòng đọc kỹ vì chúng quản lý việc sử
            dụng dịch vụ của bạn.
          </Typography>

          <Typography
            variant="h6"
            component="h2"
            className="mt-6 mb-4 font-semibold"
            sx={{ fontSize: '1.5rem' }}
          >
            Chính Sách Tài Khoản
          </Typography>
          <Typography variant="body1" className="mb-6">
            <strong>Yêu Cầu Một Tài Khoản:</strong> Để đảm bảo an toàn và bảo
            mật cho người dùng, cũng như ngăn chặn các hoạt động lừa đảo, chúng
            tôi nghiêm ngặt áp dụng chính sách một tài khoản. Mỗi người dùng chỉ
            được phép đăng ký bằng một địa chỉ email duy nhất. Chính sách này
            được thiết kế để bảo vệ dữ liệu cá nhân của bạn và đảm bảo rằng mọi
            tương tác trên nền tảng đều là xác thực và minh bạch.
          </Typography>
          <Typography variant="body1" className="mb-6">
            <strong>Trách Nhiệm Tài Khoản:</strong> Bạn chịu trách nhiệm bảo mật
            thông tin đăng nhập tài khoản của mình. Bạn đồng ý thông báo ngay
            cho chúng tôi về bất kỳ việc sử dụng tài khoản trái phép nào hoặc
            bất kỳ vi phạm bảo mật nào khác. Goodjobs sẽ không chịu trách nhiệm
            cho bất kỳ tổn thất hoặc thiệt hại nào phát sinh từ việc bạn không
            bảo vệ thông tin tài khoản của mình.
          </Typography>
          <Typography variant="body1" className="mb-6">
            <strong>Đình Chỉ và Hủy Tài Khoản:</strong> Chúng tôi có quyền đình
            chỉ hoặc hủy tài khoản vi phạm chính sách này. Trong các trường hợp
            phát hiện nhiều tài khoản, chúng tôi có thể thực hiện các biện pháp
            hợp nhất tài khoản hoặc xóa bỏ tài khoản trùng lặp. Vi phạm liên tục
            có thể dẫn đến việc đình chỉ tài khoản vĩnh viễn.
          </Typography>
          <Typography variant="body1" className="mb-6">
            <strong>Đăng Ký Tài Khoản:</strong> Người dùng có thể đăng ký tài
            khoản thông qua Google hoặc đăng ký trực tiếp tại website. Khi bạn
            sử dung dịch vụ đăng ký bằng Google, bạn đã chấp nhận các điều khoản
            sử dụng của chúng tôi.
          </Typography>

          <Typography
            variant="h6"
            component="h2"
            className="mt-6 mb-4 font-semibold"
            sx={{ fontSize: '1.5rem' }}
          >
            Hành Vi Người Dùng
          </Typography>
          <Typography variant="body1" className="mb-6">
            Bằng việc sử dụng nền tảng của chúng tôi, bạn đồng ý hành động một
            cách chuyên nghiệp và tôn trọng. Hành vi quấy rối, phân biệt đối xử
            và bất kỳ hình thức lạm dụng nào đều bị nghiêm cấm. Chúng tôi mong
            muốn tất cả người dùng tuân thủ các hướng dẫn này để duy trì một môi
            trường tích cực và hiệu quả.
          </Typography>

          <Typography
            variant="h6"
            component="h2"
            className="mt-6 mb-4 font-semibold"
            sx={{ fontSize: '1.5rem' }}
          >
            Phí Dịch Vụ
          </Typography>
          <Typography variant="body1" className="mb-6">
            Goodjobs không thu bất kì phí dịch vụ nào, tất cả các dịch vụ hiện
            có trong website hoàn toàn miễn phí. Tuy nhiên, trong tương lai sẽ
            có các tính năng với dịch vụ phát sinh, bạn đồng ý chịu trách nhiệm
            về việc thanh toán các dịch vụ nếu được thông báo từ email hoặc
            thông báo trên website
          </Typography>

          <Typography
            variant="h6"
            component="h2"
            className="mt-6 mb-4 font-semibold"
            sx={{ fontSize: '1.5rem' }}
          >
            Điều Khoản Thanh Toán
          </Typography>
          <Typography variant="body1" className="mb-6">
            Các khoản thanh toán trên nền tảng của chúng tôi được xử lý thông
            qua các nhà cung cấp thanh toán bên thứ ba an toàn. Bạn đồng ý tuân
            thủ các điều khoản thanh toán được quy định bởi các nhà cung cấp
            này. Chúng tôi không chịu trách nhiệm về bất kỳ vấn đề nào phát sinh
            từ việc xử lý thanh toán, bao gồm cả sự chậm trễ hoặc sai sót.
          </Typography>

          <Typography
            variant="h6"
            component="h2"
            className="mt-6 mb-4 font-semibold"
            sx={{ fontSize: '1.5rem' }}
          >
            Sở Hữu Trí Tuệ
          </Typography>
          <Typography variant="body1" className="mb-6">
            Tất cả nội dung, bao gồm nhưng không giới hạn ở văn bản, đồ họa,
            logo và phần mềm, là tài sản của Goodjobs hoặc các nhà cung cấp nội
            dung của chúng tôi. Bạn không được sao chép, phân phối hoặc tạo ra
            các sản phẩm phái sinh từ nội dung này mà không có sự cho phép bằng
            văn bản.
          </Typography>

          <Typography
            variant="h6"
            component="h2"
            className="mt-6 mb-4 font-semibold"
            sx={{ fontSize: '1.5rem' }}
          >
            Bảo Mật Thông Tin
          </Typography>
          <Typography variant="body1" className="mb-6">
            Bạn đồng ý giữ bí mật tất cả thông tin liên quan đến dự án, khách
            hàng và người dùng. Việc tiết lộ bất kỳ thông tin bảo mật nào mà
            không được ủy quyền là bị cấm và có thể dẫn đến hành động pháp lý.
          </Typography>

          <Typography
            variant="h6"
            component="h2"
            className="mt-6 mb-4 font-semibold"
            sx={{ fontSize: '1.5rem' }}
          >
            Giải Quyết Tranh Chấp
          </Typography>
          <Typography variant="body1" className="mb-6">
            Trong trường hợp có tranh chấp, bạn đồng ý trước tiên cố gắng giải
            quyết vấn đề một cách không chính thức bằng cách liên hệ với đội ngũ
            hỗ trợ của chúng tôi. Nếu tranh chấp không thể được giải quyết một
            cách không chính thức, nó sẽ được giải quyết thông qua trọng tài
            ràng buộc theo quy định của pháp luật.
          </Typography>

          <Typography
            variant="h6"
            component="h2"
            className="mt-6 mb-4 font-semibold"
            sx={{ fontSize: '1.5rem' }}
          >
            Thay Đổi Điều Khoản
          </Typography>
          <Typography variant="body1" className="mb-6">
            Goodjobs có quyền sửa đổi các điều khoản này bất kỳ lúc nào. Mọi
            thay đổi sẽ được thông báo đến người dùng thông qua nền tảng. Việc
            bạn tiếp tục sử dụng nền tảng sau khi các thay đổi đã được thực hiện
            có nghĩa là bạn chấp nhận các điều khoản mới.
          </Typography>

          <Typography
            variant="h6"
            component="h2"
            className="mt-6 mb-4 font-semibold"
            sx={{ fontSize: '1.5rem' }}
          >
            Thông Tin Liên Hệ
          </Typography>
          <Typography variant="body1">
            Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc nào về các điều khoản này,
            vui lòng liên hệ với chúng tôi tại goodjobs.works
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Terms;
