import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider, Tooltip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../../../components/Footer';
import { formatDateTime } from '../../../../utils/formatDate';
import blogApi from '../../../../services/blogApi';
import LoadingComponent from '../../../../components/LoadingComponent';
import BlogDescription from '../../../../components/BlogDescription';
import TopBarFreelancer from '../../../Freelancer/LayOut/TopBarFreelancer';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { truncateText } from '../../../../utils/truncateText';
import TypographyTitle from '../../../../components/Typography/TypographyTitle';

const BlogDetail = () => {
  const { blogId } = useParams();
  const [blog, setBlog] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getBlog = async () => {
      setLoading(true);
      let res = await blogApi.GetBlogById(blogId);
      setBlog(res);
      setLoading(false);
    };
    getBlog();
  }, []);

  const handleNavigation = (id) => {
    navigate(`/blog-detail/${id}`);
    window.location.reload();
  };

  return (
    <Box>
      {loading && <LoadingComponent loading={loading} />}
      <TopBarFreelancer />
      <Box display="flex" m={5}>
        <Box flex="3.5">
          <Box alignItems={'center'} justifyContent="center">
            <Typography fontSize="30px" fontWeight="bold" mb={3}>
              {' '}
              {blog?.title}
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography> Tác giả : {blog?.author} </Typography>
              <Typography>
                {' '}
                Ngày tạo : {formatDateTime(blog?.createDate)}{' '}
              </Typography>
            </Box>
            <Divider />
            <Box mt={5}>
              <BlogDescription description={blog?.description} />
            </Box>
          </Box>
        </Box>
        <Box flex="1.5">
          <Box bgcolor="#FFFFCC" ml={3} p={3} borderRadius="10px">
            <TypographyTitle title={'Bài viết liên quan'} />
            {blog?.relateds.length > 0 &&
              blog?.relateds.map((blog, index) => (
                <div key={index}>
                  <Divider />
                  <Box key={index} mb={2} mt={1} display="flex">
                    <img
                      src={blog?.image}
                      style={{
                        width: '50%',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleNavigation(blog?.blogId)}
                    />
                    <Box
                      sx={{
                        mt: 1,
                        ml: 2,
                        cursor: 'pointer',
                      }}
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                    >
                      <Tooltip title={blog?.blogName}>
                        <Typography
                          fontWeight="bold"
                          onClick={() => handleNavigation(blog?.blogId)}
                          style={{ cursor: 'pointer' }}
                        >
                          {truncateText(blog?.blogName, 50)}
                        </Typography>
                      </Tooltip>

                      <Typography mt={2} fontSize="10px">
                        <AccessTimeIcon /> {blog?.dateString}
                      </Typography>
                    </Box>
                  </Box>
                </div>
              ))}
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default BlogDetail;
