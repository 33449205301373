import { useRoutes } from 'react-router-dom';
import {
  ADMIN_PATH,
  FREELANCER_PATH,
  PUBLIC_PATH,
  RECRUITER_PATH,
} from '../constaints/path';
import Login from '../pages/Public/Login';
import Profile from '../pages/Common/Profile';
import Setting from '../pages/Common/Profile/Setting';
import ChangePassword from '../pages/Common/Profile/ChangePassword';
import ExperienceEducation from '../pages/Common/Profile/ExperienceEducation';
import ProfileSetting from '../pages/Common/Profile/ProfileSetting';
import ResetPasswordPage from '../pages/Public/ResetPassword';
import NotFoundPage from '../components/NotFoundPage';
import UnAuthorized from '../components/UnAuthorized';
import { ROLES } from '../constaints/role';
import RequireAuth from '../components/RequireAuth';
import { Suspense } from 'react';
import HomeRecruiter from '../pages/Recruiter/Home/HomeRecruiter';
import LayOutRecruiter from '../pages/Recruiter/LayOutRecruiter/LayOutRecruiter';
import Home from '../pages/Freelancer/Home/Home';
import LayOutFreelancer from '../pages/Freelancer/LayOut/LayOutFreelancer';
import ListProjectRecruiter from '../pages/Recruiter/ListProjectRecruiter/ListProjectRecruiter';
import CreateProject from '../pages/Recruiter/CreateProject/CreateProject';
import Detail from '../pages/Common/Detail/Detail';
import UpdateProject from '../pages/Recruiter/UpdateProject/UpdateProject';
import Search from '../pages/Common/ListProject/Search';
import Filter from '../pages/Common/ListProject/Filter';
import HomeAdmin from '../pages/Admin/HomeAdmin/HomeAdmin';
import LayOutAdmin from '../pages/Admin/LayOutAdmin/LayOutAdmin';
import ListUsers from '../pages/Admin/List/ListUsers';
import ListReport from '../pages/Admin/List/ListReport';
import TopBarFreelancer from '../pages/Freelancer/LayOut/TopBarFreelancer';
import Chat from '../pages/Common/Chat/Chat';
import ListProject from '../pages/Admin/List/ListProject';
import ViewBlog from '../pages/Admin/Blog/ViewBlog';
import CurrentProject from '../pages/Common/Profile/CurrentProject';
import ListCategory from '../pages/Admin/List/ListCategory';
import CreateBlog from '../pages/Admin/Blog/CreateBlog';
import UpdateBlog from '../pages/Admin/Blog/UpdateBlog';
import BlogDetail from '../pages/Admin/Blog/Publish/BlogDetail';
import HomeBlog from '../pages/Admin/Blog/Publish/HomeBlog';
import ListSkill from '../pages/Admin/List/ListSkill';
import FavoriteProjectsPage from '../pages/Common/FavoriteList/FavoriteProjectsPage';
import Payment from '../pages/Public/Payment';
import Achievement from '../pages/Common/Profile/Achievement';
import AddAchievement from '../pages/Common/Profile/component/AddAchievement';
import UpdateAchievement from '../pages/Common/Profile/component/UpdateAchievement';
import LoadingComponent from '../components/LoadingComponent';
import PaymentNotification from '../pages/Public/PaymentNotification';
import BuyProject from '../pages/Recruiter/BuyProject/BuyProject';
import HowItWorks from '../pages/Public/Guide/HowItWorks';
import AboutUs from '../pages/Public/Guide/AboutUs';
import PaymentHistory from '../pages/Common/Profile/PaymentHistory';
import ListPaymentHistory from '../pages/Admin/List/ListPaymentHistory';
import Review from '../pages/Common/Profile/Review';
import LockedList from '../pages/Public/Guide/LockedList';
import Terms from '../pages/Public/Guide/Terms';

export default function Router() {
  let router = useRoutes([
    {
      path: PUBLIC_PATH.LOGIN,
      element: <Login />,
    },
    {
      path: PUBLIC_PATH.RESET,
      element: <ResetPasswordPage />,
    },
    {
      path: PUBLIC_PATH.BLOG,
      element: <HomeBlog />,
    },
    {
      path: PUBLIC_PATH.BLOG_DETAIL,
      element: <BlogDetail />,
    },
    {
      path: PUBLIC_PATH.HOW_IT_WORKS,
      element: <LayOutFreelancer />,
      children: [
        {
          path: PUBLIC_PATH.HOW_IT_WORKS,
          element: <HowItWorks />,
        },
      ],
    },
    {
      path: PUBLIC_PATH.ABOUT_US,
      element: <LayOutFreelancer />,
      children: [
        {
          path: PUBLIC_PATH.ABOUT_US,
          element: <AboutUs />,
        },
      ],
    },
    {
      path: PUBLIC_PATH.LOCKED_LIST,
      element: <LayOutFreelancer />,
      children: [
        {
          path: PUBLIC_PATH.LOCKED_LIST,
          element: <LockedList />,
        },
      ],
    },
    {
      path: PUBLIC_PATH.TERMS,
      element: <LayOutFreelancer />,
      children: [
        {
          path: PUBLIC_PATH.TERMS,
          element: <Terms />,
        },
      ],
    },
    {
      path: PUBLIC_PATH.HOME,
      element: <LayOutFreelancer />,
      children: [
        {
          path: PUBLIC_PATH.HOME,
          element: (
            <Suspense fallback={<LoadingComponent loading={true} />}>
              <Home />
            </Suspense>
          ),
        },
        {
          path: PUBLIC_PATH.SEARCH_PROJECT,
          element: (
            <Suspense fallback={<LoadingComponent loading={true} />}>
              <Search />
            </Suspense>
          ),
        },
        {
          path: PUBLIC_PATH.LIST_PROJECT,
          element: (
            <Suspense fallback={<LoadingComponent loading={true} />}>
              <Search />
            </Suspense>
          ),
        },
        {
          path: PUBLIC_PATH.SEARCH_CATEGORY_PROJECT,
          element: (
            <Suspense fallback={<LoadingComponent loading={true} />}>
              <Filter />
            </Suspense>
          ),
        },
        {
          path: PUBLIC_PATH.DETAIL,
          element: (
            <Suspense fallback={<LoadingComponent loading={true} />}>
              <Detail />
            </Suspense>
          ),
        },

        {
          path: PUBLIC_PATH.PAYMENT,
          element: (
            <Suspense fallback={<LoadingComponent loading={true} />}>
              <Payment />
            </Suspense>
          ),
        },
        {
          path: PUBLIC_PATH.PAYMENT_DETAILS,
          element: (
            <Suspense fallback={<LoadingComponent loading={true} />}>
              <PaymentNotification />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: '/unauthorized',
      element: <UnAuthorized />,
    },
    {
      path: PUBLIC_PATH.NOT_FOUND,
      element: <NotFoundPage />,
    },
    {
      path: FREELANCER_PATH.LAYOUT,
      element: <LayOutFreelancer />,
      children: [
        {
          element: (
            <RequireAuth
              allowedRoles={[ROLES.FREELANCER, ROLES.RECRUITER, ROLES.ADMIN]}
            />
          ),
          children: [
            {
              path: FREELANCER_PATH.PROFILE,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <Profile />
                </Suspense>
              ),
            },
            {
              path: PUBLIC_PATH.USER_PROFILE,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <Profile />
                </Suspense>
              ),
            },
            {
              path: FREELANCER_PATH.FAVORITE_LIST,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <FavoriteProjectsPage />
                </Suspense>
              ),
            },
          ],
        },
      ],
    },
    {
      path: FREELANCER_PATH.LAYOUT,
      element: <TopBarFreelancer />,
      children: [
        {
          element: (
            <RequireAuth
              allowedRoles={[ROLES.FREELANCER, ROLES.RECRUITER, ROLES.ADMIN]}
            />
          ),
          children: [
            {
              path: FREELANCER_PATH.CHAT,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <Chat />
                </Suspense>
              ),
            },
          ],
        },
      ],
    },
    {
      path: FREELANCER_PATH.LAYOUT,
      element: <Setting />,
      children: [
        {
          element: <RequireAuth allowedRoles={[ROLES.FREELANCER]} />,
          children: [
            {
              path: FREELANCER_PATH.PROFILE_SETTING,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <TopBarFreelancer />
                  <ProfileSetting />
                </Suspense>
              ),
            },
            {
              path: FREELANCER_PATH.CHANGE_PASSWORD,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <TopBarFreelancer />
                  <ChangePassword />
                </Suspense>
              ),
            },
            {
              path: FREELANCER_PATH.EXPERIENCE_EDUCATION,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <TopBarFreelancer />
                  <ExperienceEducation />
                </Suspense>
              ),
            },
            {
              path: FREELANCER_PATH.REVIEW,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <TopBarFreelancer />
                  <Review />
                </Suspense>
              ),
            },
            {
              path: FREELANCER_PATH.CURRENT_PROJECT,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <TopBarFreelancer />
                  <CurrentProject />
                </Suspense>
              ),
            },
            {
              path: FREELANCER_PATH.PAYMENT_HISTORY,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <TopBarFreelancer />
                  <PaymentHistory />
                </Suspense>
              ),
            },
          ],
        },
      ],
    },
    {
      path: FREELANCER_PATH.LAYOUT,
      element: <Setting />,
      children: [
        {
          element: <RequireAuth allowedRoles={[ROLES.FREELANCER]} />,
          children: [
            {
              path: FREELANCER_PATH.FREELANCER_PROJECT,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <TopBarFreelancer />
                  <ListProjectRecruiter />
                </Suspense>
              ),
            },
            {
              path: FREELANCER_PATH.FREELANCER_CREATE_PROJECT,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <TopBarFreelancer />
                  <CreateProject />
                </Suspense>
              ),
            },
            {
              path: FREELANCER_PATH.FREELANCER_UPDATE_PROJECT,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <TopBarFreelancer />
                  <UpdateProject />
                </Suspense>
              ),
            },
            {
              path: FREELANCER_PATH.ACHIEVEMENT,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <TopBarFreelancer />
                  <Achievement />
                </Suspense>
              ),
            },
            {
              path: FREELANCER_PATH.ADD_ACHIEVEMENT,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <TopBarFreelancer />
                  <AddAchievement />
                </Suspense>
              ),
            },
            {
              path: FREELANCER_PATH.UPDATE_ACHIEVEMENT,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <TopBarFreelancer />
                  <UpdateAchievement />
                </Suspense>
              ),
            },
          ],
        },
      ],
    },
    {
      path: RECRUITER_PATH.LAYOUT,
      element: <LayOutRecruiter />,
      children: [
        {
          element: <RequireAuth allowedRoles={ROLES.RECRUITER} />,
          children: [
            {
              path: RECRUITER_PATH.HOME_RECRUITER,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <HomeRecruiter />
                </Suspense>
              ),
            },
            {
              path: RECRUITER_PATH.BUY_PROJECT,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <BuyProject />
                </Suspense>
              ),
            },
            {
              path: RECRUITER_PATH.REVIEW,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <Review />
                </Suspense>
              ),
            },
            {
              path: RECRUITER_PATH.LIST_PROJECT_RECRUITER,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <ListProjectRecruiter />
                </Suspense>
              ),
            },
            {
              path: RECRUITER_PATH.CREATE_NEW_PROJECT,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <CreateProject />
                </Suspense>
              ),
            },
            {
              path: RECRUITER_PATH.UPDATE_PROJECT,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <UpdateProject />
                </Suspense>
              ),
            },
            {
              path: RECRUITER_PATH.CHANGE_PASSWORD,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <ChangePassword />
                </Suspense>
              ),
            },
            {
              path: RECRUITER_PATH.PROFILE_SETTING,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <ProfileSetting />
                </Suspense>
              ),
            },
            {
              path: RECRUITER_PATH.PAYMENT_HISTORY,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <PaymentHistory />
                </Suspense>
              ),
            },
          ],
        },
      ],
    },
    {
      path: ADMIN_PATH.LAYOUT,
      element: <LayOutAdmin />,
      children: [
        {
          element: <RequireAuth allowedRoles={ROLES.ADMIN} />,
          children: [
            {
              path: ADMIN_PATH.HOME_ADMIN,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <HomeAdmin />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PATH.CHANGE_PASSWORD,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <ChangePassword />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PATH.LIST_USERS,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <ListUsers />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PATH.REPORT_LIST,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <ListReport />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PATH.PROJECT_LIST,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <ListProject />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PATH.VIEW_BLOG,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <ViewBlog />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PATH.CATEGORY_LIST,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <ListCategory />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PATH.SKILL_LIST,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <ListSkill />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PATH.PAYMENT_HISTORY,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <ListPaymentHistory />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PATH.CREATE_BLOG,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <CreateBlog />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PATH.UPDATE_BLOG,
              element: (
                <Suspense fallback={<LoadingComponent loading={true} />}>
                  <UpdateBlog />
                </Suspense>
              ),
            },
          ],
        },
      ],
    },
  ]);
  return router;
}
