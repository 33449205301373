import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Divider,
  Grid,
  Autocomplete,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import 'react-toastify/dist/ReactToastify.css';
import authApi from '../../../services/authApi';
import { useNavigate } from 'react-router-dom';
import skillApi from '../../../services/skillAPI';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import GoogleIcon from '@mui/icons-material/Google';

const steps = ['Chọn vai trò', 'Họ tên', 'Chi tiết', 'Review'];

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <ContactEmergencyIcon />,
    4: <ConfirmationNumberIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const Register = ({
  showPassword,
  handleClickShowPassword,
  setShowLogin,
  style,
}) => {
  const [step, setStep] = useState(0);
  const [roles, setRoles] = useState([]);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    skills: [],
    companyName: '',
    taxCode: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    skills: '',
    companyName: '',
    taxCode: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errorText, setErrorText] = useState('');
  const [errorPassword, setErrorPassword] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleNext = () => {
    if (validateStep()) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectSkill = (event, value) => {
    if (value.length <= 5) {
      setFormData({ ...formData, skills: value });
    }
  };
  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const accessToken = tokenResponse.access_token;
        await authApi.loginWithGoogle(accessToken, dispatch, navigate);
      } catch (error) {
        console.error('Google login error:', error);
        if (error.response && error.response.status === 415) {
          setErrorText('Unsupported media type.');
        } else {
          setErrorText('Đã có lỗi xảy ra khi đăng nhập bằng Google.');
        }
        setErrorPassword(true);
      }
    },
    onError: (error) => {
      console.error('Google login error:', error);
      setErrorText('Đăng nhập bằng Google không thành công.');
      setErrorPassword(true);
    },
  });

  const handleRoleSelect = (selectedRole) => {
    setRoles([selectedRole.charAt(0).toUpperCase() + selectedRole.slice(1)]);
    handleNext();
  };

  const validateStep = () => {
    let isValid = true;
    const newErrors = {};

    if (step === 1) {
      if (!formData.firstName) {
        isValid = false;
        newErrors.firstName = 'Thông tin họ bắt buộc';
      }
      if (!formData.lastName) {
        isValid = false;
        newErrors.lastName = 'Thông tin tên bắt buộc';
      }
    } else if (step === 2) {
      if (!formData.email) {
        isValid = false;
        newErrors.email = 'Email đang trống';
      } else {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
          isValid = false;
          newErrors.email = 'Email sai định dạng';
        }
      }
      if (!formData.password) {
        isValid = false;
        newErrors.password = 'Mật khẩu đang trống';
      }
      if (formData.password.length < 6 || formData.password.length > 32) {
        isValid = false;
        newErrors.password =
          'Mật khẩu phải nhiều hơn 6 kí tự và ít hơn 32 kí tự';
      }
      if (formData)
        if (!formData.confirmPassword) {
          isValid = false;
          newErrors.confirmPassword = 'Xác nhận mật khẩu đang trống';
        }
      if (roles.includes('Freelancer') && formData.skills.length === 0) {
        isValid = false;
        newErrors.skills = 'Kĩ năng cá nhân đang trống';
      }
      if (roles.includes('Recruiter')) {
        if (!formData.companyName) {
          isValid = false;
          newErrors.companyName = 'Tên công ty đang trống';
        }
        if (!formData.taxCode) {
          isValid = false;
          newErrors.taxCode = 'Mã số thuế đang trống';
        }
      }
      if (formData.password !== formData.confirmPassword) {
        isValid = false;
        newErrors.confirmPassword = 'Mật khẩu không trùng khớp';
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateStep()) {
      const data = {
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
        name: `${formData.firstName} ${formData.lastName}`,
        taxCode: formData.taxCode || '',
        isCompany: roles.includes('Recruiter'),
        roles: roles,
        skill: roles.includes('Freelancer') ? formData.skills : [],
      };
      try {
        const response = await authApi.register(data, navigate);
        if (response) {
          toast.success('Đăng ký thành công!');
          setShowLogin(true);
        }
      } catch (error) {
        toast.error('Đăng ký thất bại. Hãy thử lại sau.');
      }
    }
  };

  const [allSkills, setAllSkills] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let res = await skillApi.GetAllSkill();
      setAllSkills(res);
    };
    getData();
  }, []);

  const renderTextField = (
    label,
    name,
    type,
    value,
    handleChange,
    error,
    helperText
  ) => {
    return (
      <TextField
        fullWidth
        label={label}
        name={name}
        type={type}
        value={value}
        onChange={handleChange}
        sx={{
          '& .MuiInputLabel-root': {
            color: 'white',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: 'white',
          },
          '& .MuiInputBase-input': {
            color: 'white',
          },
          mt: 2,
        }}
        error={!!error}
        helperText={helperText}
      />
    );
  };

  return (
    <Box sx={style}>
      <Box sx={{ textAlign: 'center' }} mb={2}>
        <Typography sx={{ color: 'white', fontSize: '2em' }} variant="h1">
          ĐĂNG KÝ
        </Typography>
      </Box>
      <Divider sx={{ borderColor: 'white' }} />
      <Box mt={3}>
        <Stepper
          activeStep={step}
          alternativeLabel
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => (
            <Step
              key={label}
              sx={{
                '& .MuiStepLabel-label': {
                  color: 'white',
                },
                '& .MuiStepLabel-label.Mui-active, & .MuiStepLabel-label.Mui-completed':
                  {
                    color: '#b33072',
                  },
              }}
            >
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box elevation={3} sx={{ p: 2, mt: 2 }}>
          {step === 0 && (
            <Box mt={3}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleRoleSelect('freelancer')}
                  >
                    Tôi muốn trở thành freelancer
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRoleSelect('recruiter')}
                  >
                    Tôi muốn tuyển dụng
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
          {step === 1 && (
            <>
              {renderTextField(
                'Họ',
                'firstName',
                'text',
                formData.firstName,
                handleChange,
                errors.firstName,
                errors.firstName
              )}
              {renderTextField(
                'Tên',
                'lastName',
                'text',
                formData.lastName,
                handleChange,
                errors.lastName,
                errors.lastName
              )}
            </>
          )}
          {step === 2 && (
            <>
              {roles.includes('Freelancer') && (
                <Tooltip title="You can only select up to 5 skills">
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    limitTags={2}
                    options={allSkills.map((skill) => skill.skillName)}
                    value={formData.skills}
                    onChange={handleSelectSkill}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Kĩ năng"
                        placeholder="Kĩ năng"
                        sx={{ mt: 2 }}
                        error={!!errors.skills}
                        helperText={errors.skills}
                      />
                    )}
                    sx={{
                      '& .MuiFormLabel-root, & .MuiSvgIcon-root, & .MuiChip-label, & .MuiAutocomplete-tag, & .MuiInputBase-input':
                        {
                          color: 'white',
                        },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '10px',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                      },
                    }}
                  />
                </Tooltip>
              )}
              {roles.includes('Recruiter') && (
                <>
                  {renderTextField(
                    'Tên công ty',
                    'companyName',
                    'text',
                    formData.companyName,
                    handleChange,
                    errors.companyName,
                    errors.companyName
                  )}
                  {renderTextField(
                    'Mã số thuế',
                    'taxCode',
                    'text',
                    formData.taxCode,
                    handleChange,
                    errors.taxCode,
                    errors.taxCode
                  )}
                </>
              )}
              {renderTextField(
                'Email',
                'email',
                'email',
                formData.email,
                handleChange,
                errors.email,
                errors.email
              )}
              <TextField
                fullWidth
                label="Mật khẩu"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={handleChange}
                sx={{
                  '& .MuiInputLabel-root': {
                    color: 'white',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '& .MuiFormLabel-root.Mui-focused': {
                    color: 'white',
                  },
                  '& .MuiInputBase-input': {
                    color: 'white',
                  },
                  mt: 2,
                }}
                error={!!errors.password}
                helperText={errors.password}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={handleClickShowPassword}
                      size="small"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  ),
                }}
              />
              <TextField
                fullWidth
                label="Xác nhận mật khẩu"
                name="confirmPassword"
                type={showPassword ? 'text' : 'password'}
                value={formData.confirmPassword}
                onChange={handleChange}
                sx={{
                  '& .MuiInputLabel-root': {
                    color: 'white',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '& .MuiFormLabel-root.Mui-focused': {
                    color: 'white',
                  },
                  '& .MuiInputBase-input': {
                    color: 'white',
                  },
                  mt: 2,
                }}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={handleClickShowPassword}
                      size="small"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </>
          )}
          {step === 3 && (
            <>
              <Typography
                variant="h6"
                fontSize={18}
                mb={2}
                color="white"
                gutterBottom
              >
                <i>
                  Hãy xem lại các thông tin bạn vừa tạo trước khi xác nhận đăng
                  ký.
                </i>
              </Typography>
              <Typography variant="body1" color="white" gutterBottom>
                Vai trò: {roles.join(', ')}
              </Typography>
              <Typography variant="body1" color="white" gutterBottom>
                Họ tên: {formData.firstName} {formData.lastName}
              </Typography>
              <Typography variant="body1" color="white" gutterBottom>
                Email: {formData.email}
              </Typography>
              {roles.includes('Freelancer') && (
                <Typography variant="body1" color="white" gutterBottom>
                  Kĩ năng: {formData.skills.join(', ')}
                </Typography>
              )}
              {roles.includes('Recruiter') && (
                <>
                  <Typography variant="body1" color="white" gutterBottom>
                    Tên công ty: {formData.companyName}
                  </Typography>
                  <Typography variant="body1" color="white" gutterBottom>
                    Mã số thuế: {formData.taxCode}
                  </Typography>
                </>
              )}
              <Typography mt={3} color="#eeff0e" gutterBottom>
                <i>
                  Lưu ý: thông tin email của bạn sẽ không thể thay đổi sau này
                  với chính sách 1 người 1 tài khoản
                </i>
              </Typography>
            </>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button
              variant="contained"
              onClick={handleBack}
              disabled={step === 0 ? true : false}
            >
              Quay lại
            </Button>
            {step < steps.length - 1 && (
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={step === 0 ? true : false}
              >
                Tiếp
              </Button>
            )}
            {step === steps.length - 1 && (
              <Button variant="contained" onClick={handleSubmit}>
                Đăng ký
              </Button>
            )}
          </Box>
        </Box>
        <Divider />
        <Box id="signInButton" mt={2}>
          <Button
            fullWidth
            size="large"
            sx={{
              bgcolor: 'rgb(99, 102, 241)',
              p: '11px 24px',
              borderRadius: '12px',
              textTransform: 'none',
              fontSize: '17px',
            }}
            onClick={() => loginWithGoogle()}
            variant="contained"
          >
            <GoogleIcon sx={{ marginRight: '10px' }} />
            Đăng ký bằng Google
          </Button>
        </Box>
        <Typography>{errorText ? errorText : errorPassword}</Typography>
        <Box mt={2}>
          <Typography
            variant="body1"
            component="span"
            style={{ color: 'white' }}
          >
            Đã có tài khoản?{' '}
            <Typography
              variant="body1"
              component="span"
              onClick={() => setShowLogin(true)}
              style={{ cursor: 'pointer', color: 'rgb(99, 102, 241)' }}
            >
              Đăng nhập
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
