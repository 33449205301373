export const PUBLIC_PATH = {
  LOGIN: '/login',
  HOME: '/',
  REGISTER: '/register',
  BLOG: '/blog',
  BLOG_DETAIL: '/blog-detail/:blogId',
  SEARCH_PROJECT: '/search/:searchKey',
  LIST_PROJECT: '/search',
  SEARCH_CATEGORY_PROJECT: '/category/:idCate',
  DETAIL: '/detail/:projectId',
  RESET: '/reset-password',
  USER_PROFILE: '/profile/:userId',
  PAYMENT: '/payment',
  PAYMENT_DETAILS: '/payment-details',
  NOT_FOUND: '*',
  HOW_IT_WORKS: '/how-it-works',
  ABOUT_US: '/about-us',
  LOCKED_LIST: '/locked-list',
  TERMS: '/terms-condition',
};

export const FREELANCER_PATH = {
  PROFILE: '/profile',
  PROFILE_SETTING: '/profile-setting',
  CHANGE_PASSWORD: '/change-password',
  EXPERIENCE_EDUCATION: '/experience-education',
  ACHIEVEMENT: '/achievements',
  ADD_ACHIEVEMENT: '/create-achievements',
  UPDATE_ACHIEVEMENT: '/update-achievements/:id',
  CURRENT_PROJECT: '/current-project',
  SETTING: '/setting',
  CHAT: '/chat/:conversationId/:userId',
  FAVORITE_LIST: '/favorite-list',
  FREELANCER_PROJECT: '/freelancer-project/:status',
  FREELANCER_CREATE_PROJECT: '/freelancer-create-project',
  FREELANCER_UPDATE_PROJECT: '/freelancer-update-project/:projectId',
  PAYMENT_HISTORY: '/payment-history',
  REVIEW: '/review',
};

export const RECRUITER_PATH = {
  LAYOUT: '/',
  HOME_RECRUITER: '/recruiter',
  LIST_PROJECT_RECRUITER: '/list-project-recruiter/:status',
  CREATE_NEW_PROJECT: '/create-new-project',
  BUY_PROJECT: '/buy-project',
  UPDATE_PROJECT: '/update-project/:projectId',
  CHANGE_PASSWORD: '/recruiter/change-password',
  PROFILE_SETTING: '/recruiter/profile-setting',
  PAYMENT_HISTORY: '/recruiter/payment-history',
  REVIEW: '/recruiter/review',
};

export const ADMIN_PATH = {
  // LAYOUT: '/',
  USER_PROFILE: '/profile/:userId',
  HOME_ADMIN: '/admin',
  LIST_USERS: '/users-list',
  REPORT_LIST: '/report-list',
  PROJECT_LIST: '/project-list/:status',
  CREATE_BLOG: '/create-blog',
  UPDATE_BLOG: '/update-blog/:blogId',
  VIEW_BLOG: '/view-blog',
  CATEGORY_LIST: '/category-list',
  ROLES: '/roles',
  CHANGE_PASSWORD: '/admin/change-password',
  SKILL_LIST: '/skill-list',
  PAYMENT_HISTORY: '/admin/payment-history',
};
