import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FilledInput,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import categoryApi from '../../../services/categoryApi';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import projectApi from '../../../services/projectApi';
import { useNavigate } from 'react-router-dom';
import 'ckeditor5/ckeditor5.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import TypographyHeader from '../../../components/Typography/TypographyHeader';
import TypographyTitle from '../../../components/Typography/TypographyTitle';
import LoadingComponent from '../../../components/LoadingComponent';
import profileApi from '../../../services/profileApi';
import { useTranslation } from 'react-i18next';

const CreateProject = () => {
  const [isCheck, setIsCheck] = useState(false);
  const [checkDescription, setCheckDescription] = useState(false);

  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState(false);
  const [helperTextName, setHelperTextName] = useState('');

  const [description, setDescription] = useState('');
  const [helperTextDescription, setHelperTextDescription] = useState('');
  const [helperTextDescriptionCheck, setHelperTextDescriptionCheck] =
    useState('');

  const [listCategory, setListCategory] = useState([]);

  const [category, setCategory] = useState('');
  const [errorCategory, setErrorCategory] = useState(false);
  const [helperTextCategory, setHelperTextCategory] = useState('');

  const [budgetMin, setBudgetMin] = useState(0);
  const [errorBudgetMin, setErrorBudgetMin] = useState(false);
  const [helperTextBudgetMin, setHelperTextBudgetMin] = useState('');

  const [budgetMax, setBudgetMax] = useState(0);
  const [errorBudgetMax, setErrorBudgetMax] = useState(false);
  const [helperTextBudgetMax, setHelperTextBudgetMax] = useState('');

  const [duration, setDuration] = useState(0);

  const [errorDuration, setErrorDuration] = useState(false);
  const [helperTextDuration, setHelperTextDuration] = useState('');

  const [listSkill, setListSkill] = useState([]);
  const [listSkillSelected, setListSkillSelected] = useState([]);
  const [errorListSkillSelected, setErrorListSkillSelected] = useState(false);
  const [helperTextListSkillSelected, setHelperTextListSkillSelected] =
    useState('');

  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.auth.login?.currentUser);
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const { t } = useTranslation('project');

  useEffect(() => {
    if (currentUser) {
      const getData = async () => {
        const res = await profileApi.getUserProfile();
        setProfile(res);
      };
      getData();
    }
  }, [currentUser]);

  useEffect(() => {
    const getCategory = async () => {
      const res = await categoryApi.GetAllCategory();
      setListCategory(res);
    };
    getCategory();
  }, []);

  useEffect(() => {
    setListSkillSelected([]);
    if (category !== '') {
      const getSkill = async () => {
        const res = await categoryApi.GetByCategoryId(category);
        setListSkill(res?.items);
      };
      getSkill();
    }
  }, [category]);

  const handleDescriptionChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
    setHelperTextDescriptionCheck('');
    setCheckDescription(true);
  };

  const handleSubmit = async () => {
    let hasError = false;
    if (name.length <= 10 || name.length >= 100) {
      setErrorName(true);
      setHelperTextName(`${t('title_validate')}`);
      hasError = true;
    } else {
      setErrorName(false);
      setHelperTextName('');
    }

    if (description.length <= 50 || description.length >= 2000) {
      setHelperTextDescription(`${t('description_validate')}`);
      hasError = true;
    } else {
      setHelperTextDescription('');
    }

    if (category === '') {
      setErrorCategory(true);
      setHelperTextCategory(`${t('category_validate')}`);
      hasError = true;
    } else {
      setErrorCategory(false);
      setHelperTextCategory('');
    }

    if (budgetMin <= 0 || budgetMin === '' || budgetMin >= 50000000) {
      setErrorBudgetMin(true);
      setHelperTextBudgetMin(`${t('budget_validate')}`);
      hasError = true;
    } else {
      setErrorBudgetMin(false);
      setHelperTextBudgetMin('');
    }

    if (
      budgetMax <= 0 ||
      budgetMax === '' ||
      budgetMax <= budgetMin ||
      budgetMax >= 50000000
    ) {
      setErrorBudgetMax(true);
      setHelperTextBudgetMax(`${t('budget_max_validate')}`);
      hasError = true;
    } else {
      setErrorBudgetMax(false);
      setHelperTextBudgetMax('');
    }

    if (
      listSkillSelected.length === 0 ||
      listSkillSelected === null ||
      listSkillSelected.length > 5
    ) {
      setErrorListSkillSelected(true);
      setHelperTextListSkillSelected(`${t('skill_validate')}`);
      hasError = true;
    } else {
      setErrorListSkillSelected(false);
      setHelperTextListSkillSelected('');
    }

    if (duration <= 0 || duration === '') {
      setErrorDuration(true);
      setHelperTextDuration(`${t('duration_validate')}`);
      hasError = true;
    } else {
      setErrorDuration(false);
      setHelperTextDuration('');
    }

    if (hasError === true) {
      return;
    }

    try {
      setLoading(true);
      let data = {
        title: name.trim(),
        description: description.trim(),
        minBudget: budgetMin,
        maxBudget: budgetMax,
        categoryId: category,
        duration: duration,
        createdBy: currentUser?.userId,
        skill: listSkillSelected,
      };
      await projectApi.AddProject(currentUser, data, navigate);
      setLoading(false);
    } catch (err) {
      toast.error(`${t('create_project_error')}`);
    }
  };

  const handleCheck = async () => {
    setHelperTextDescriptionCheck('');
    setHelperTextDescription('');

    try {
      if (description.length <= 50 || description.length >= 2000) {
        setHelperTextDescription(`${t('description_validate')}`);
      } else {
        setIsCheck(true);
        setLoading(true);
        const res = await categoryApi.CheckDescription(description);
        if (res?.data === false) {
          setHelperTextDescriptionCheck(`${t('test_success')}`);
          setCheckDescription(false);
        } else {
          setHelperTextDescription(res);
          setCheckDescription(true);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderSubmit = () => {
    if (isCheck === true && checkDescription === false) {
      return (
        <Button variant="contained" onClick={(e) => handleSubmit()}>
          {t('create_project')}
        </Button>
      );
    } else {
      return (
        <Button disabled variant="contained" onClick={(e) => handleSubmit()}>
          {t('create_project')}
        </Button>
      );
    }
  };
  return (
    <Box sx={style}>
      {loading && <LoadingComponent loading={loading} />}

      <Paper sx={{ bgcolor: '#F8F8FF' }}>
        <Box m={3} p={5}>
          <Box textAlign="center" mb={3}>
            <TypographyHeader title={t('create_project')} />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Typography>
              {t('create_project_times')}: {profile?.amoutProject} {t('times')}
            </Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <TypographyTitle title={t('title')} marginT={3} />
            <Box mt={2}>
              <TextField
                fullWidth
                placeholder={t('title_placeholder')}
                value={name}
                error={errorName}
                helperText={helperTextName}
                sx={{
                  bgcolor: '#FFFFFF',
                }}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
          </Box>
          <Box mt={2}>
            <TypographyTitle title={t('description_project')} />
            <Box mt={2}>
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={handleDescriptionChange}
              />
              <Typography color="red">{helperTextDescription}</Typography>
              {helperTextDescriptionCheck && (
                <Typography color="#40bf00">
                  {helperTextDescriptionCheck}
                </Typography>
              )}
            </Box>
          </Box>

          <Box mt={2} display="flex" gap={3}>
            <TypographyTitle title={t('test_ai')} />
            <Typography color="red">{t('require')}</Typography>
            <Button variant="contained" onClick={(e) => handleCheck()}>
              {t('test')}
            </Button>
          </Box>

          <Box mt={2}>
            <TypographyTitle title={t('category_title')} />
            <Box mt={2}>
              <Select
                fullWidth
                sx={{
                  bgcolor: '#FFFFFF',
                }}
                error={errorCategory}
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {listCategory?.length !== 0 &&
                  listCategory.map((item, index) => (
                    <MenuItem key={index} value={item?.id}>
                      {item?.categoryName}
                    </MenuItem>
                  ))}
              </Select>
              <Typography color="red">{helperTextCategory}</Typography>
            </Box>
          </Box>

          <Box mt={3}>
            <TypographyTitle title={t('skill_title')} />
            <Typography mt={2} fontSize="15px">
              {' '}
              {t('skill_require')}
            </Typography>
            <Typography mt={2} fontSize="15px">
              {' '}
              {t('skill_description')}
            </Typography>
            <Box mt={2}>
              <Autocomplete
                sx={{
                  bgcolor: '#FFFFFF',
                }}
                multiple
                options={listSkill}
                getOptionLabel={(option) => option?.skillName}
                filterSelectedOptions
                value={listSkill.filter((skill) =>
                  listSkillSelected.includes(skill.skillName)
                )} // Explicitly set value
                onChange={(event, value) =>
                  setListSkillSelected(value.map((item) => item?.skillName))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errorListSkillSelected}
                    helperText={helperTextListSkillSelected}
                    placeholder={t('skill')}
                  />
                )}
              />
            </Box>
          </Box>

          <Box mt={2}>
            <TypographyTitle title={t('budget_title')} />
            <Typography mt={3} fontSize="15px">
              {t('budget_description')}
            </Typography>
            <Box mt={2} display="flex">
              <Box>
                <TypographyTitle title={t('budget_min')} />

                <FilledInput
                  sx={{
                    bgcolor: '#FFFFFF',
                  }}
                  value={budgetMin}
                  type="number"
                  id="filled-adornment-weight"
                  error={errorBudgetMin}
                  endAdornment={
                    <InputAdornment position="end">VND</InputAdornment>
                  }
                  aria-describedby="filled-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setBudgetMin(parseInt(e.target.value, 10));
                    }
                  }}
                />
                <Typography color="red">{helperTextBudgetMin}</Typography>
              </Box>
              <Box ml={3}>
                <TypographyTitle title={t('budget_max')} />
                <FilledInput
                  sx={{
                    bgcolor: '#FFFFFF',
                  }}
                  type="number"
                  value={budgetMax}
                  error={errorBudgetMax}
                  id="filled-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end">VND</InputAdornment>
                  }
                  aria-describedby="filled-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setBudgetMax(parseInt(e.target.value, 10));
                    }
                  }}
                />
                <Typography color="red">{helperTextBudgetMax}</Typography>
              </Box>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography mt={3} fontSize="20px" fontWeight="bold">
              {' '}
              {t('duration_title')}
            </Typography>
            <Typography mt={3} fontSize="15px">
              {t('duration_description')}
            </Typography>
            <Box mt={2}>
              <FilledInput
                sx={{
                  bgcolor: '#FFFFFF',
                }}
                type="number"
                value={duration}
                id="filled-adornment-weight"
                error={errorDuration}
                endAdornment={
                  <InputAdornment position="end">{t('day')} </InputAdornment>
                }
                aria-describedby="filled-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value >= 0) {
                    setDuration(value);
                  }
                }}
              />
              <Typography color="red">{helperTextDuration}</Typography>
            </Box>
          </Box>
          <Box mt={3} display="flex" justifyContent="flex-end">
            {renderSubmit()}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateProject;
const style = {
  p: 4,
  overflow: 'auto',
  maxHeight: window.innerHeight - 80,
};
