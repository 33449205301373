import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Modal,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import profileApi from '../../../services/profileApi';
import { useSelector } from 'react-redux';
import TypographyTitle from '../../../components/Typography/TypographyTitle';
import LoadingComponent from '../../../components/LoadingComponent';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const Achievement = () => {
  const currentUser = useSelector((state) => state.auth.login?.currentUser);
  const [list, setList] = useState([]);
  const [portfolio, setPortfolio] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('profile');

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setPortfolio(null);
  };
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let res = await profileApi.GetAllPortfolio(currentUser?.userId);
      setList(res);
      setLoading(false);
    };
    getData();
  }, []);

  const handleOpenDetail = async (id) => {
    try {
      let res = await profileApi.GetPortfolioById(id);
      setPortfolio(res);
      setOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await profileApi.DeletePortfolio(id);
      toast.success(`${t('remove_success')}`);
      removeItem(id);
      setPortfolio(null);
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const removeItem = (id) => {
    setList((prev) => prev.filter((item) => item.id !== id));
  };

  const handleUpdate = async (id) => {
    navigate(`/update-achievements/${id}`);
  };

  return (
    <Box p={3} m={3} borderRadius={5} border="1px solid #ccc">
      {loading && <LoadingComponent loading={loading} />}
      <Box
        display="flex"
        mb={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography sx={{ fontSize: '2em' }} gutterBottom>
          {t('achievement')}
        </Typography>
        <Button
          variant="contained"
          onClick={(e) => navigate('/create-achievements')}
          sx={{ mt: 2 }}
        >
          {t('add_achievement')}
        </Button>
      </Box>
      <Divider mt={2} mb={2} />
      <Grid container spacing={2} mt={3}>
        {list?.length > 0 ? (
          list.map((item) => (
            <Grid item xs={12} sm={6} key={item.fileName}>
              <Card>
                <CardMedia
                  component="img"
                  image={item.fileName}
                  alt={item.title}
                  loading="lazy"
                  sx={{ height: 200 }}
                  onClick={(e) => handleOpenDetail(item?.id)}
                />
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {item.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography pl={4}>{t('no_content')} </Typography>
        )}
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style} gap={2}>
          <Box flex={1} borderRadius={5} border="1px solid #ccc">
            <img src={portfolio?.fileName} />
          </Box>
          <Box flex={0.8}>
            <TypographyTitle title={portfolio?.title} />
            <Typography mt={2}> {portfolio?.description}</Typography>
            <Box display="flex" mt={3} gap={2}>
              <Button
                variant="outlined"
                color="error"
                onClick={(e) => handleDelete(portfolio?.id)}
              >
                {t('remove')}
              </Button>
              <Button
                variant="outlined"
                color="warning"
                onClick={(e) => handleUpdate(portfolio?.id)}
              >
                {t('edit')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
const style = {
  overflow: 'auto',
  maxHeight: window.innerHeight - 80,
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export default Achievement;
