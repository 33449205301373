import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const userApi = {
  GetLockedUsers: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/Users/Locked`);
      return response?.data;
    } catch (error) {
      throw error;
    }
  },
};

export default userApi;
