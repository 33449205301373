import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const FeaturesSection = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const { t } = useTranslation('home');
  const features = [
    {
      icon: '📚',
      title: t('upto_skill'),
      description: t('upto_skill_description'),
    },
    {
      icon: '💸',
      title: t('transparent_payment'),
      description: t('transparent_payment_description'),
    },
    {
      icon: '⚡',
      title: t('flexible_time'),
      description: t('flexible_time_description'),
    },
    {
      icon: '🌐',
      title: t('support_time'),
      description: t('support_time_description'),
    },
  ];

  return (
    <Box className="py-12 bg-white text-left">
      <Box className="max-w-screen-2xl mx-auto">
        <Typography
          className="max-w-screen-md md:mb-10 text-3xl md:text-5xl font-medium tracking-tighter text-center md:text-left leading-tight md:leading-normal"
          sx={{ color: 'var(--text-color)' }}
        >
          {t('feature_section_title')}
        </Typography>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2500}
          keyBoardControl={true}
          customTransition="transform 0.7s ease"
          transitionDuration={700}
          containerClass="carousel-container"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          arrows={false}
          showDots={false}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {features.map((feature, index) => (
            <Box
              key={index}
              className="flex flex-col items-center md:items-start p-4"
            >
              <div className="text-5xl mb-4">{feature.icon}</div>
              <Typography
                variant="h6"
                className="text-2xl font-semibold mb-3 text-center md:text-left pl-4"
              >
                {feature.title}
              </Typography>
              <Typography
                variant="body1"
                className="text-gray-600 text-center md:text-left pl-4"
              >
                {feature.description}
              </Typography>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default FeaturesSection;
