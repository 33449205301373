import React from 'react';
import {
  Box,
  Card,
  Typography,
  Avatar,
  Button,
  Grid,
  Divider,
} from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';

const PaymentNotification = () => {
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard');
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
      <Card sx={{ maxWidth: 800, width: '100%', p: 2 }}>
        <Typography fontSize="20px" gutterBottom>
          Chi tiết đơn hàng
        </Typography>
        <Divider />
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src="https://via.placeholder.com/200"
                alt="QR Code"
                style={{ maxWidth: '100%' }}
              />
            </Box>
            <Box display="flex" mt={2} gap={2} justifyContent="center">
              <img
                src="https://via.placeholder.com/50"
                alt="QR Code"
                style={{ maxWidth: '100%' }}
              />
              <img
                src="https://via.placeholder.com/50"
                alt="QR Code"
                style={{ maxWidth: '100%' }}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              textAlign="center"
              mt={2}
            >
              <Button
                variant="outlined"
                color="success"
                onClick={() => alert('Cancelled')}
              >
                Hủy
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <Avatar
                alt="Bank Logo"
                src="https://via.placeholder.com/40"
                sx={{ mr: 2 }}
              />
              <Typography variant="h6">Ngân hàng TMCP Quân đội</Typography>
            </Box>

            <Typography variant="body1">Chủ tài khoản:</Typography>
            <Typography fontWeight="bold">LE VAN DUC</Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
            >
              <Box>
                <Typography variant="body1">Số tài khoản:</Typography>
                <Typography variant="body1" fontWeight="bold">
                  VQR00024ktpa
                </Typography>
              </Box>
              <Button
                size="small"
                onClick={() => handleCopy('VQR00024ktpa')}
                sx={{ ml: 2 }}
              >
                <CopyIcon fontSize="small" />
                Sao chép
              </Button>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
            >
              <Box>
                <Typography variant="body1">Số tiền:</Typography>
                <Typography variant="body1" fontWeight="bold">
                  25,000 vnđ
                </Typography>
              </Box>
              <Button
                size="small"
                onClick={() => handleCopy('25,000 vnđ')}
                sx={{ ml: 2 }}
              >
                <CopyIcon fontSize="small" />
                Sao chép
              </Button>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
            >
              <Box>
                <Typography variant="body1">Nội dung:</Typography>
                <Typography variant="body1" fontWeight="bold">
                  DuThaus025000
                </Typography>
              </Box>
              <Button
                size="small"
                onClick={() => handleCopy('DuThaus025000')}
                sx={{ ml: 2 }}
              >
                <CopyIcon fontSize="small" />
                Sao chép
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default PaymentNotification;
