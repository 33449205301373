import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Slide,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
} from '@mui/material';
import {
  LocalOffer,
  ShoppingCart,
  Loyalty,
  Edit,
  Discount,
  AttachMoney,
  Star,
  Percent,
  WorkOutline,
  NoteAdd,
} from '@mui/icons-material';
import { styled } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import paymentApi from '../../services/paymentApi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import WarningPayment from '../../assets/payment_warning.png';

const BidBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  textAlign: 'center',
  borderRadius: '12px',
  boxShadow: theme.shadows[4],
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[6],
  },
}));

const BidButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: '8px',
}));

const ResponsiveGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const PromotionBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
  borderRadius: '12px',
  boxShadow: theme.shadows[4],
  backgroundColor: theme.palette.background.default,
}));

const PromotionItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderRadius: '8px',
  transition: 'background-color 0.2s',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Payment = () => {
  const regExNumber = /^[0-9]*$/;
  const currentUser = useSelector((state) => state.auth.login?.currentUser);
  const navigate = useNavigate();
  const [loadingStates, setLoadingStates] = useState({});
  const [customBidDialogOpen, setCustomBidDialogOpen] = useState(false);
  const [customBidValue, setCustomBidValue] = useState('');
  const [customProjectDialogOpen, setCustomProjectDialogOpen] = useState(false);
  const [customProjectValue, setCustomProjectValue] = useState('');
  const [error, setError] = useState('');

  const handleBuyBids = async (bid) => {
    setLoadingStates((prev) => ({ ...prev, [bid]: true }));
    try {
      const response = await paymentApi.createPayment(bid);
      window.open(response.checkoutUrl, '_blank');
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [bid]: false }));
    }
  };

  const handleBuyProjects = async (project) => {
    setLoadingStates((prev) => ({ ...prev, [`project-${project}`]: true }));
    try {
      const response = await paymentApi.createPaymentProject(project);
      window.open(response.checkoutUrl, '_blank');
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [`project-${project}`]: false }));
    }
  };

  const handleCustomBidDialogOpen = () => setCustomBidDialogOpen(true);
  const handleCustomBidDialogClose = () => {
    setCustomBidDialogOpen(false);
    setCustomBidValue('');
    setError('');
  };

  const handleCustomBidConfirm = async () => {
    const customBid = parseInt(customBidValue, 10);
    if (regExNumber.test(customBidValue) === false) {
      setError('Số lượng không chứa kí tự đặc biệt');
      return;
    }
    if (isNaN(customBid) || customBid <= 0) {
      setError('Hãy nhập số lượng mua hợp lệ.');
      return;
    }
    if (customBid > 100) {
      setError('Bạn không thể mua nhiều hơn 100 lượt đấu thầu 1 lần.');
      return;
    }
    setCustomBidDialogOpen(false);
    handleBuyBids(customBid);
  };

  const handleCustomProjectDialogOpen = () => setCustomProjectDialogOpen(true);
  const handleCustomProjectDialogClose = () => {
    setCustomProjectDialogOpen(false);
    setCustomProjectValue('');
    setError('');
  };

  const handleCustomProjectConfirm = async () => {
    const customProject = parseInt(customProjectValue, 10);
    if (regExNumber.test(customProject) === false) {
      setError('Số lượng không chứa kí tự đặc biệt');
      return;
    }
    if (isNaN(customProject) || customProject <= 0) {
      setError('Hãy nhập số lượng mua hợp lệ.');
      return;
    }
    if (customProject > 100) {
      setError('Bạn không thể mua nhiều hơn 100 lượt đăng bài 1 lần.');
      return;
    }
    setCustomProjectDialogOpen(false);
    handleBuyProjects(customProject);
  };

  return (
    <Box p={4}>
      <ResponsiveGrid
        container
        spacing={4}
        justifyContent="left"
        sx={{ mb: 4 }}
      >
        <Grid item xs={12} md={4}>
          <Card
            style={{
              backgroundColor: '#FFF8E1',
            }}
          >
            <CardHeader title="Lưu ý:" subheader="từ Admin" />
            <CardMedia
              component="img"
              image={WarningPayment}
              alt="warning_payment"
            />
            <CardContent>
              <Typography>
                Để tránh xảy ra lỗi trong quá trình giao dịch, vui lòng không
                thay đổi nội dung chuyển khoản và số tiền, nếu bạn tự ý thay
                đổi, vui lòng liên hệ chúng tôi để được hỗ trợ nếu có lỗi phát
                sinh.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </ResponsiveGrid>
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 'bold',
          color: '#333333',
          mb: 4,
        }}
      >
        Mua thêm lượt đấu thầu
      </Typography>
      <ResponsiveGrid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={3}>
          <BidBox>
            <LocalOffer fontSize="large" color="primary" />
            <Typography variant="h6" mt={2}>
              Mua thêm 5 lượt đấu thầu
            </Typography>
            <BidButton
              variant="contained"
              color="primary"
              loading={loadingStates[5] || false}
              onClick={() => {
                currentUser ? handleBuyBids(5) : navigate('/login');
              }}
            >
              Mua ngay
            </BidButton>
          </BidBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <BidBox>
            <ShoppingCart fontSize="large" color="secondary" />
            <Typography variant="h6" mt={2}>
              Mua thêm 10 lượt đấu thầu
            </Typography>
            <BidButton
              variant="contained"
              color="secondary"
              loading={loadingStates[10] || false}
              onClick={() => {
                currentUser ? handleBuyBids(10) : navigate('/login');
              }}
            >
              Mua ngay
            </BidButton>
          </BidBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <BidBox>
            <Loyalty fontSize="large" color="success" />
            <Typography variant="h6" mt={2}>
              Mua thêm 15 lượt đấu thầu
            </Typography>
            <BidButton
              variant="contained"
              color="success"
              loading={loadingStates[15] || false}
              onClick={() => {
                currentUser ? handleBuyBids(15) : navigate('/login');
              }}
            >
              Mua ngay
            </BidButton>
          </BidBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <BidBox>
            <Edit fontSize="large" color="warning" />
            <Typography variant="h6" mt={2}>
              Mua lượt đấu thầu tùy chỉnh
            </Typography>
            <BidButton
              variant="contained"
              color="warning"
              onClick={handleCustomBidDialogOpen}
            >
              Tùy chỉnh lượng mua
            </BidButton>
          </BidBox>
        </Grid>
      </ResponsiveGrid>

      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 'bold',
          color: '#333333',
          mb: 4,
          mt: 4,
        }}
      >
        Mua thêm lượt đăng dự án
      </Typography>
      <ResponsiveGrid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={3}>
          <BidBox>
            <WorkOutline fontSize="large" color="primary" />
            <Typography variant="h6" mt={2}>
              Mua thêm 5 lượt đăng dự án
            </Typography>
            <BidButton
              variant="contained"
              color="primary"
              loading={loadingStates['project-5'] || false}
              onClick={() => {
                currentUser ? handleBuyProjects(5) : navigate('/login');
              }}
            >
              Mua ngay
            </BidButton>
          </BidBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <BidBox>
            <NoteAdd fontSize="large" color="secondary" />
            <Typography variant="h6" mt={2}>
              Mua thêm 10 lượt đăng dự án
            </Typography>
            <BidButton
              variant="contained"
              color="secondary"
              loading={loadingStates['project-10'] || false}
              onClick={() => {
                currentUser ? handleBuyProjects(10) : navigate('/login');
              }}
            >
              Mua ngay
            </BidButton>
          </BidBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <BidBox>
            <Star fontSize="large" color="success" />
            <Typography variant="h6" mt={2}>
              Mua thêm 15 lượt đăng dự án
            </Typography>
            <BidButton
              variant="contained"
              color="success"
              loading={loadingStates['project-15'] || false}
              onClick={() => {
                currentUser ? handleBuyProjects(15) : navigate('/login');
              }}
            >
              Mua ngay
            </BidButton>
          </BidBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <BidBox>
            <Percent fontSize="large" color="warning" />
            <Typography variant="h6" mt={2}>
              Mua lượt đăng dự án tùy chỉnh
            </Typography>
            <BidButton
              variant="contained"
              color="warning"
              onClick={handleCustomProjectDialogOpen}
            >
              Tùy chỉnh lượng mua
            </BidButton>
          </BidBox>
        </Grid>
      </ResponsiveGrid>

      <PromotionBox>
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#333333',
            mb: 2,
          }}
        >
          Khuyến mãi đặc biệt
        </Typography>
        <PromotionItem>
          <AttachMoney fontSize="large" color="success" />
          <Typography variant="subtitle1" ml={2}>
            Mua trên 10, 20, 30 các mặt hàng giảm ngay lần lượt 10%, 20%, 30%
          </Typography>
        </PromotionItem>
        <PromotionItem>
          <Discount fontSize="large" color="primary" />
          <Typography variant="subtitle1" ml={2}>
            Mua trên 50 số lượng 1 mặt hàng, tặng thêm 10 lượt miễn phí
          </Typography>
        </PromotionItem>
      </PromotionBox>

      <Dialog
        open={customBidDialogOpen}
        onClose={handleCustomBidDialogClose}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Tùy chỉnh số lượng đấu thầu</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Số lượng đấu thầu"
            type="number"
            fullWidth
            variant="outlined"
            value={customBidValue}
            onChange={(e) => {
              const value = e.target.value;
              if (value >= 0) {
                setCustomBidValue(value);
              }
            }}
            error={Boolean(error)}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCustomBidDialogClose} color="secondary">
            Hủy
          </Button>
          <Button onClick={handleCustomBidConfirm} color="primary">
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={customProjectDialogOpen}
        onClose={handleCustomProjectDialogClose}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Tùy chỉnh số lượng đăng dự án</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Số lượng đăng dự án"
            type="number"
            fullWidth
            variant="outlined"
            value={customProjectValue}
            onChange={(e) => {
              const value = e.target.value;
              if (value >= 0) {
                setCustomProjectValue(value);
              }
            }}
            error={Boolean(error)}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCustomProjectDialogClose} color="secondary">
            Hủy
          </Button>
          <Button onClick={handleCustomProjectConfirm} color="primary">
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Payment;
