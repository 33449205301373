import { Box, Typography } from '@mui/material';
import React from 'react';

const Header = ({ title, subtitle }) => {
  return (
    <Box>
      <Typography
        sx={{ fontSize: '1.5rem', fontWeight: '600' }}
        variant="h4"
        gutterBottom
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          fontSize="15px"
          fontWeight="bold"
          color="#33CCFF"
          gutterBottom
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default Header;
