import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Pagination,
  Rating,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import projectApi from '../../../services/projectApi';
import { truncateText } from '../../../utils/truncateText';
import profileApi from '../../../services/profileApi';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const labels = {
  1: 'Rất tệ',
  2: 'Tệ',
  3: 'Bình thường',
  4: 'Khá tốt',
  5: 'Xuất sắc',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const Review = () => {
  const [listReview, setListReview] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [userRatedId, setuserRatedId] = useState(null);
  const [newRating, setNewRating] = useState(0);
  const [newComment, setNewComment] = useState('');
  const [hover, setHover] = useState(-1);
  const [submitting, setSubmitting] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const { t } = useTranslation('profile');

  const handleClickOpen = (userRatedId, projectId) => {
    setuserRatedId(userRatedId);
    setProjectId(projectId);
    setOpen(true);
  };

  const handleClose = () => {
    setuserRatedId(null);
    setProjectId(null);
    setOpen(false);
  };

  useEffect(() => {
    const getData = async () => {
      let pageSize = 4;
      let res = await projectApi.GetUserRate(page, pageSize);
      setListReview(res?.items);
      setTotalPages(Math.ceil(res?.totalItemsCount / pageSize));
    };
    getData();
  }, [reload, page]);

  const handleRatingSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      let data = {
        comment: newComment,
        star: newRating,
        rateToUserId: userRatedId,
        projectId: projectId,
      };
      await profileApi.submitRating(data);
      toast.success(`${t('rate_success')}`);
      setReload((prev) => !prev);
      setNewRating(0);
      setNewComment('');
      handleClose();
    } catch (error) {
      toast.error(error.response.data);
    } finally {
      setSubmitting(false);
    }
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box
      p={3}
      m={3}
      border="1px solid #ccc"
      borderRadius={5}
      className="bg-white"
    >
      <Typography sx={{ fontSize: '2em', mb: 2 }} gutterBottom>
        {t('rating')}
      </Typography>
      <Grid container spacing={2}>
        {listReview && listReview.length === 0 && (
          <Typography sx={{ fontSize: '1em', m: 2 }} gutterBottom>
            {t('no_content')}
          </Typography>
        )}
        {listReview &&
          listReview.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                    cursor: 'pointer',
                  },
                }}
                //   onClick={() => handleDetail(project?.projectId)}
              >
                <CardHeader
                  title={
                    <Typography
                      variant="h1"
                      sx={{ fontSize: '1.5em' }}
                      gutterBottom
                    >
                      {truncateText(item?.projectName, 40)}
                    </Typography>
                  }
                />
                <CardContent
                  sx={{ flexGrow: 1, paddingTop: 0, paddingBottom: 0 }}
                >
                  <Typography variant="body1" component="div">
                    <strong>{t('partner')}:</strong> {item?.userRatedName}
                  </Typography>

                  <Typography variant="body1" component="div">
                    <strong>{t('done_date')}: </strong> {item?.doneDate}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickOpen(item?.userRatedId, item?.projectId);
                    }}
                    sx={{ ml: 'auto' }}
                  >
                    {t('rating')}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      <Box mt={2} display="flex" justifyContent="center" alignItems="center">
        <Pagination
          color="primary"
          count={totalPages}
          page={page}
          onChange={handleChange}
        />
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent sx={{ textAlign: 'center', padding: '2em' }}>
          <Typography sx={{ fontSize: '2em', mb: 2 }} gutterBottom>
            {t('your_rating')}
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <form onSubmit={handleRatingSubmit}>
            <Box className="flex flex-row">
              <Rating
                name="hover-feedback"
                value={newRating}
                getLabelText={getLabelText}
                onChange={(event, newValue) => setNewRating(newValue)}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
              />
              {newRating !== null && (
                <Box sx={{ ml: 2 }}>
                  {labels[hover !== -1 ? hover : newRating]}
                </Box>
              )}
            </Box>
            <TextField
              label={t('comment')}
              multiline
              rows={4}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              fullWidth
              sx={{ marginY: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitting}
            >
              {t('rating')}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Review;
