import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import CustomAvatar from '../../../components/CustomAvatar';
import { useNavigate } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const AppBarChat = ({ user }) => {
  const navigate = useNavigate();
  const handleProfile = () => {
    if (user?.userId !== 32) {
      navigate(`/profile/${user?.userId}`);
    }
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'white',
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          ></IconButton>
          {user?.avatar === null || user?.avatar === '' ? (
            <CustomAvatar name={user?.name} />
          ) : (
            <Box sx={{ position: 'relative', display: 'inline-block' }}>
              <Avatar src={user?.avatar} sx={{ mr: 2 }} />
              {user?.isOnline === true && (
                <FiberManualRecordIcon
                  color="success"
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0, // Changed to right for bottom-right positioning
                    width: '20px', // Increased size
                    height: '20px', // Increased size
                  }}
                />
              )}
            </Box>
          )}
          <Box ml={2}>
            <Typography
              variant="h6"
              onClick={(e) => handleProfile()}
              component="div"
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
                flexGrow: 1,
                color: 'black',
              }}
            >
              {user?.name}
            </Typography>
            {user?.isOnline === true && (
              <Typography color="green" fontSize="12px">
                {' '}
                Đang hoạt động{' '}
              </Typography>
            )}
          </Box>

          {/* <Button color="inherit">Lựa chọn khác </Button> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default AppBarChat;
