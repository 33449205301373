import {
  Box,
  Button,
  Divider,
  FilledInput,
  InputAdornment,
  LinearProgress,
  Modal,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import projectApi from '../../../services/projectApi';
import biddingApi from '../../../services/biddingApi';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ProjectDetail from './ProjectDetail';
import ListBidding from './ListBidding';
import Header from '../../Recruiter/LayOutRecruiter/Header';
import LoadingComponent from '../../../components/LoadingComponent';
import TypographyTitle from '../../../components/Typography/TypographyTitle';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { useTranslation } from 'react-i18next';
const Detail = () => {
  const currentUser = useSelector((state) => state.auth.login?.currentUser);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [value, setValue] = useState('1');
  const [detail, setDetail] = useState(null);

  const [budget, setBudget] = useState(0);
  const [errorBudget, seterrorBudget] = useState(false);
  const [helperTextBudget, setHelperTextBudget] = useState('');
  const [comment, setComment] = useState('');
  const [errorcomment, seterrorcomment] = useState(false);
  const [helperTextcomment, setHelperTextcomment] = useState('');
  const [duration, setDuration] = useState(0);
  const [errorduration, seterrorduration] = useState(false);
  const [helperTextduration, setHelperTextduration] = useState('');

  const [budgetUpdate, setBudgetUpdate] = useState(0);
  const [errorBudgetUpdate, seterrorBudgetUpdate] = useState(false);
  const [commentUpdate, setCommentUpdate] = useState('');
  const [errorcommentUpdate, seterrorcommentUpdate] = useState(false);
  const [durationUpdate, setDurationUpdate] = useState(0);
  const [errordurationUpdate, seterrordurationUpdate] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const { t } = useTranslation('project_detail');

  useEffect(() => {
    if (currentUser !== null && value === '1') {
      const getUserBidding = async () => {
        setLoading(true);
        try {
          let res = await biddingApi.GetBidByProjectLoggedUser(projectId);
          if (res?.status === 204) {
            setMyBidding(null);
          } else {
            setBudgetUpdate(res?.budget);
            setDurationUpdate(res?.duration);
            setCommentUpdate(res?.proposal);
            setMyBidding(res);
          }
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };
      getUserBidding();
    }
  }, [projectId, currentUser, value]);
  const [listBidding, setListBidding] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [openBidding, setOpenBidding] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [myBidding, setMyBidding] = useState(null);

  const [biddingId, setBiddingId] = useState(null);

  const handleOpen = () => {
    if (currentUser == null) {
      const currentUrl = window.location.href;
      localStorage.setItem('currentUrl', currentUrl);
      navigate('/login');
    }
    setOpen(true);
  };
  const handleOpenBidding = (biddingId) => {
    setOpenBidding(true);
    setBiddingId(biddingId);
  };

  const handleClose = () => setOpen(false);
  const handleCloseUpdate = () => setOpenUpdate(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getProjectDetail = async () => {
      setLoading(true);
      try {
        let res = await projectApi.GetProjectDetailsById(projectId, navigate);
        setDetail(res);
      } catch (error) {
        toast.error(`${t('error')}`);
      } finally {
        setLoading(false);
      }
    };
    getProjectDetail();
  }, [projectId, reload]);

  useEffect(() => {
    const getAllBidding = async () => {
      if (value === '2') {
        setLoading(true);
        try {
          let res = await biddingApi.GetBiddingListByProjectId(
            projectId,
            page,
            5
          );
          setListBidding(res?.data);
          setTotalPage(Math.ceil(res?.data?.totalItemsCount / 5));
        } catch (error) {
          toast.error(`${t('error')}`);
        } finally {
          setLoading(false);
        }
      }
    };
    getAllBidding();
  }, [value, projectId, page]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let isError = false;
    if (budget === 0 || budget >= 50000000 || budget === '' || budget === '0') {
      seterrorBudget(true);
      isError = true;
      setHelperTextBudget(`${t('budget_error')}`);
    } else {
      seterrorBudget(false);
      setHelperTextBudget('');
    }
    if (
      duration <= 0 ||
      duration === '' ||
      duration === '0' ||
      duration >= 100
    ) {
      seterrorduration(true);
      isError = true;
      setHelperTextduration(`${t('duration_error')}`);
    } else {
      seterrorduration(false);
      setHelperTextduration('');
    }
    if (comment === '') {
      seterrorcomment(true);
      isError = true;
      setHelperTextcomment(`${t('not_null')}`);
    } else {
      seterrorcomment(false);
      setHelperTextcomment('');
    }

    if (isError) {
      setLoading(false);
      return;
    }

    let data = {
      projectId: projectId,
      proposal: comment,
      duration: duration,
      budget: budget,
    };
    try {
      await biddingApi.AddBidding(data, navigate);
      window.location.reload();
      setOpen(false);
    } catch (error) {
      toast.error(`${t('bid_error')}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    let isError = false;
    if (
      budgetUpdate === 0 ||
      budgetUpdate >= 50000000 ||
      budgetUpdate === '' ||
      budgetUpdate === '0'
    ) {
      seterrorBudgetUpdate(true);
      isError = true;
      setHelperTextBudget(`${t('budget_error')}`);
    } else {
      seterrorBudgetUpdate(false);
      setHelperTextBudget('');
    }
    if (
      durationUpdate <= 0 ||
      durationUpdate === '' ||
      durationUpdate === '0' ||
      durationUpdate >= 100
    ) {
      seterrordurationUpdate(true);
      isError = true;
      setHelperTextduration(`${t('duration_error')}`);
    } else {
      seterrordurationUpdate(false);
      setHelperTextduration('');
    }
    if (commentUpdate === '') {
      seterrorcommentUpdate(true);
      isError = true;
      setHelperTextcomment(`${t('not_null')}`);
    } else {
      seterrorcommentUpdate(false);
      setHelperTextcomment('');
    }

    if (isError) {
      return;
    }
    setLoading(true);

    let data = {
      id: myBidding?.id,
      proposal: commentUpdate,
      duration: durationUpdate,
      budget: budgetUpdate,
    };
    try {
      await biddingApi.UpdateBidding(data);
      setMyBidding((pre) => ({
        ...pre,
        proposal: commentUpdate,
        duration: durationUpdate,
        budget: budgetUpdate,
      }));
      setOpenUpdate(false);
    } catch (error) {
      toast.error(`${t('bid_error')}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleCloseBidding = () => {
    setOpenBidding(false);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      await projectApi.DeleteProject(currentUser, projectId, navigate);
      setLoading(false);
    } catch (error) {
      toast.error(`${t('delete_error')}`);
    }
  };
  const UpdateStatus = async (id) => {
    setLoading(true);
    try {
      await projectApi.ReupProject(id);
      navigate('/list-project-recruiter/1');
      setLoading(false);
    } catch (error) {
      toast.error(`${t('update_error')}`);
      setLoading(false);
    }
  };

  const handleAccept = async () => {
    setLoading(true);
    if (biddingId == null || biddingId === undefined) {
      return;
    }
    try {
      await biddingApi.AcceptBidding(currentUser, biddingId, navigate);
      toast.success(`${t('accept_bid')}`);
      setLoading(false);
      setBiddingId(null);
    } catch (error) {
      toast.error(`${t('accept_error')}`);
      setLoading(false);
    }
    setOpenBidding(false);
  };
  const handleOpenUpdate = () => {
    setOpenUpdate(true);
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  return (
    <>
      <Box m={5}>
        {loading && <LoadingComponent loading={loading} />}

        <Box mb={3}>
          {value === '1' && (
            <Header
              title={t('description_projects')}
              subtitle={t('description_project')}
            />
          )}
          {value === '2' && (
            <Header title={t('list_biddings')} subtitle={t('list_bidding')} />
          )}
        </Box>

        <Box sx={{ width: '100%' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange}>
                <Tab label={t('des')} sx={{ fontSize: '12px' }} value="1" />
                <Tab label={t('bid')} sx={{ fontSize: '12px' }} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              {detail == null ? (
                <LinearProgress />
              ) : (
                <ProjectDetail
                  detail={detail}
                  navigate={navigate}
                  // handleDelete={handleDelete}
                  currentUser={currentUser}
                  projectId={projectId}
                  handleOpen={handleOpen}
                  setOpenDelete={setOpenDelete}
                  myBidding={myBidding}
                  handleOpenUpdate={handleOpenUpdate}
                  UpdateStatus={UpdateStatus}
                  setReload={setReload}
                />
              )}
            </TabPanel>
            <TabPanel value="2">
              {listBidding == null ? (
                <LinearProgress />
              ) : (
                <ListBidding
                  listBidding={listBidding}
                  currentUser={currentUser}
                  createdBy={detail?.createdBy}
                  handleOpenBidding={handleOpenBidding}
                  detail={detail}
                  page={page}
                  totalPage={totalPage}
                  handlePageChange={handlePageChange}
                />
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
      {/* Add =============================== */}
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box sx={style}>
          <Box p={2}>
            <Typography fontSize="18px" fontWeight="bold">
              {t('place_bid')}
            </Typography>
          </Box>
          <Divider />
          <Box pt={3} pl={3}>
            <Typography fontSize="15px">
              {t('place_bid_description')}
            </Typography>
          </Box>
          <Box m={5}>
            <Box display="flex" gap={1}>
              <Box flex="1">
                <Typography fontWeight="bold"> {t('bid_amount')}</Typography>
                <FilledInput
                  value={budget}
                  type="number"
                  error={errorBudget}
                  id="filled-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end">VND</InputAdornment>
                  }
                  aria-describedby="filled-weight-helper-text"
                  inputProps={{ 'aria-label': 'weight' }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setBudget(value);
                    }
                  }}
                />
                <Typography color="red">{helperTextBudget}</Typography>
              </Box>
              <Box flex="1">
                <Typography fontWeight="bold"> {t('time')}</Typography>
                <FilledInput
                  type="number"
                  value={duration}
                  error={errorduration}
                  id="filled-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end">{t('day')}</InputAdornment>
                  }
                  aria-describedby="filled-weight-helper-text"
                  inputProps={{ 'aria-label': 'weight' }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setDuration(value);
                    }
                  }}
                />
                <Typography color="red">{helperTextduration}</Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography fontWeight="bold"> {t('comment')}</Typography>
              <TextField
                multiline
                maxRows={3}
                fullWidth
                error={errorcomment}
                helperText={helperTextcomment}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" p={2}>
            <Button
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
            >
              {t('bid')}
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Delete ============================ */}
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box sx={styleDelete}>
          <DeleteOutlineOutlinedIcon color="error" sx={{ fontSize: 64 }} />
          <TypographyTitle title="Xác nhận" />

          <Typography sx={{ mt: 2, mb: 4 }}>{t('confirm_delete')}</Typography>
          <Button
            variant="outlined"
            color="error"
            onClick={(e) => handleConfirmDelete()}
            sx={{ mr: 2 }}
          >
            {t('yes')}
          </Button>
          <Button variant="outlined" onClick={handleCloseDelete}>
            {t('no')}
          </Button>
        </Box>
      </Modal>
      {/* Xác nhận Bidding  */}
      <Modal
        open={openBidding}
        onClose={handleCloseBidding}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box sx={styleDelete}>
          <HandshakeOutlinedIcon color="success" sx={{ fontSize: 64 }} />
          <TypographyTitle title="Xác nhận" />

          <Typography sx={{ mt: 2, mb: 4 }}>{t('confirm_bidding')}</Typography>
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleAccept()}
            sx={{ mr: 2 }}
          >
            {t('yes')}
          </Button>
          <Button variant="outlined" onClick={handleCloseBidding}>
            {t('no')}
          </Button>
        </Box>
      </Modal>
      {/* Update ============================= */}
      <Modal
        open={openUpdate}
        onClose={handleCloseUpdate}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box sx={style}>
          <Box p={2}>
            <Typography fontSize="18px" fontWeight="bold">
              {t('place_bid')}
            </Typography>
          </Box>
          <Divider />
          <Box pt={3} pl={3}>
            <Typography fontSize="15px">
              {t('place_bid_description')}
            </Typography>
          </Box>
          <Box m={5}>
            <Box display="flex" gap={1}>
              <Box flex="1">
                <Typography fontWeight="bold">{t('bid_amount')}</Typography>
                <FilledInput
                  value={budgetUpdate}
                  type="number"
                  error={errorBudgetUpdate}
                  id="filled-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end">VND</InputAdornment>
                  }
                  aria-describedby="filled-weight-helper-text"
                  inputProps={{ 'aria-label': 'weight' }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setBudgetUpdate(value);
                    }
                  }}
                />
                <Typography color="red">{helperTextBudget}</Typography>
              </Box>
              <Box flex="1">
                <Typography fontWeight="bold">{t('time')}</Typography>
                <FilledInput
                  type="number"
                  value={durationUpdate}
                  error={errordurationUpdate}
                  id="filled-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end"> {t('day')}</InputAdornment>
                  }
                  aria-describedby="filled-weight-helper-text"
                  inputProps={{ 'aria-label': 'weight' }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setDurationUpdate(value);
                    }
                  }}
                />
                <Typography color="red">{helperTextduration}</Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography fontWeight="bold"> {t('comment')} </Typography>
              <TextField
                fullWidth
                multiline
                maxRows={3}
                error={errorcommentUpdate}
                helperText={helperTextcomment}
                value={commentUpdate}
                onChange={(e) => setCommentUpdate(e.target.value)}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" p={2}>
            <Button
              variant="contained"
              disabled={loading}
              onClick={(e) => handleSubmitUpdate(e)}
            >
              {t('edit')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};

const styleDelete = {
  position: 'absolute',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '8px',
  textAlign: 'center',
};

export default Detail;
