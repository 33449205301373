import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Slide,
  TextField,
  Button,
  Typography,
  InputAdornment,
} from '@mui/material';
import { toast } from 'react-toastify';
import authApi from '../../../../services/authApi';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const SlideTransition = React.forwardRef(function SlideTransition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const PhoneNumberVerificationModal = ({
  verifyType,
  openVerify,
  onCloseVerify,
}) => {
  const [step, setStep] = useState(1);
  const [stepEmail, setStepEmail] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [timer, setTimer] = useState(30);
  const [secureToken, setSecureToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(
    'Hiện tại chúng tôi chỉ hỗ trợ nhà mạng Viettel do hạn chếkĩ thuật. Chúng tôi sẽ cập nhật thêm các nhà mạng khác trong tương lai. Mong bạn thông cảm'
  );
  const { t } = useTranslation('project');

  useEffect(() => {
    let interval;
    if (step === 2 || stepEmail === 2) {
      interval = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [step, stepEmail]);

  const handleGetVerifyCode = async () => {
    setLoading(true);
    try {
      if (verifyType === 'phone') {
        await authApi.verifyPhone(`+84${phoneNumber}`);
        setVerifyCode('');
        setStep(2);
      } else {
        await authApi.verifyEmail();
        setVerifyCode('');
        setStepEmail(2);
      }
      setLoading(false);
      setTimer(30);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data);
    }
  };

  const handleVerifyPhoneNumber = async () => {
    try {
      if (verifyType === 'phone') {
        await authApi.verifyPhoneCode({
          code: verifyCode,
          phoneNumber: `+84${phoneNumber}`,
        });
        toast.success('Xác thực số điện thoại thành công');
        onCloseVerify();
      } else {
        const response = await authApi.verifyEmailCode({ code: verifyCode });
        setSecureToken(response.secureToken);
        setStepEmail(3);
        toast.success('Xác thực email thành công');
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleResendCode = async () => {
    setTimer(30);
    setLoadingText(true);
    try {
      if (verifyType === 'phone') {
        await authApi.verifyPhone(`+84${phoneNumber}`);
      } else {
        await authApi.verifyEmail();
      }
      setLoadingText(false);
      toast.success('Verification code resent');
    } catch (error) {
      toast.error(error.response.data);
    }
  };

  const handleConfirmVerifyEmail = async () => {
    try {
      await authApi.confirmVerifyEmail({ secureToken });
      onCloseVerify();
    } catch (error) {
      toast.error(error.response.data);
    }
  };

  return (
    <Dialog
      open={openVerify}
      onClose={onCloseVerify}
      TransitionComponent={SlideTransition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          marginTop: '5%',
        },
      }}
    >
      <DialogContent
        className="flex flex-col items-center"
        sx={{
          width: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '10px',
          p: 4,
          overflow: 'auto',
        }}
      >
        {verifyType === 'phone' && step === 1 && (
          <div className="flex flex-col space-y-4">
            <Typography variant="h6">Nhập số điện thoại để xác minh</Typography>
            <div className="flex items-center space-x-2">
              <TextField
                error={error}
                variant="outlined"
                value={phoneNumber}
                type="number"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value >= 0) {
                    setPhoneNumber(value);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+84</InputAdornment>
                  ),
                }}
                fullWidth
                helperText={
                  <span>
                    <i>{errorText}</i>
                  </span>
                }
              />
            </div>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={loading}
              onClick={() => {
                const regexPhoneNumber = /(84|0[3|9])+([0-9]{8})\b/g;
                if (!regexPhoneNumber.test(phoneNumber)) {
                  setError(true);
                  setErrorText('Hãy nhập đúng định dạng số điện thoại Viettel');
                } else {
                  setError(false);
                  handleGetVerifyCode();
                }
              }}
            >
              Lấy mã xác minh
            </LoadingButton>
          </div>
        )}
        {verifyType === 'email' && stepEmail === 1 && (
          <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-2">
              <div className="flex flex-col space-y-4">
                <Typography>
                  <span>
                    <i>
                      Chúng tôi đã nhận thông tin email cần xác thực. Hãy nhấn
                      vào nút Lấy mã xác minh bên dưới để nhận mã xác minh
                    </i>
                  </span>
                </Typography>
              </div>
            </div>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={loading}
              onClick={handleGetVerifyCode}
            >
              Lấy mã xác minh
            </LoadingButton>
          </div>
        )}
        {verifyType === 'phone' && step === 2 && (
          <div className="flex flex-col space-y-4">
            <Typography variant="h6">
              Nhập mã xác minh đã gửi đến số điện thoại của bạn
            </Typography>
            <TextField
              variant="outlined"
              value={verifyCode}
              onChange={(e) => setVerifyCode(e.target.value)}
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleVerifyPhoneNumber}
            >
              Xác thực
            </Button>
            <Typography>Mã sẽ hết hạn sau 3 phút</Typography>
            {timer > 0 ? (
              loadingText === false ? (
                <Typography>
                  Vui lòng đợi sau {timer} giây nếu bạn chưa nhận được mã xác
                  minh
                </Typography>
              ) : (
                <div className="newtons-cradle">
                  <div className="newtons-cradle__dot"></div>
                  <div className="newtons-cradle__dot"></div>
                  <div className="newtons-cradle__dot"></div>
                  <div className="newtons-cradle__dot"></div>
                </div>
              )
            ) : (
              <Typography
                className="text-blue-500 cursor-pointer"
                onClick={handleResendCode}
              >
                Gửi lại mã xác minh
              </Typography>
            )}
          </div>
        )}
        {verifyType === 'email' && stepEmail === 2 && (
          <div className="flex flex-col space-y-4">
            <Typography variant="h6">
              Nhập mã xác minh đã gửi đến email của bạn
            </Typography>
            <TextField
              variant="outlined"
              value={verifyCode}
              onChange={(e) => setVerifyCode(e.target.value)}
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleVerifyPhoneNumber}
            >
              Xác thực
            </Button>
            <Typography>Mã sẽ hết hạn sau 3 phút</Typography>
            {timer > 0 ? (
              loadingText === false ? (
                <Typography>
                  Vui lòng đợi sau {timer} giây nếu bạn chưa nhận được mã xác
                  minh
                </Typography>
              ) : (
                <div className="newtons-cradle">
                  <div className="newtons-cradle__dot"></div>
                  <div className="newtons-cradle__dot"></div>
                  <div className="newtons-cradle__dot"></div>
                  <div className="newtons-cradle__dot"></div>
                </div>
              )
            ) : (
              <Typography
                className="text-blue-500 cursor-pointer"
                onClick={handleResendCode}
              >
                Gửi lại mã xác minh
              </Typography>
            )}
          </div>
        )}
        {stepEmail === 3 && (
          <div className="flex flex-col space-y-4">
            <Typography variant="h6">
              Cảm ơn bạn đã xác nhận email. Hãy nhấn nút đã xác nhận để chúng
              tôi xác nhận email của bạn là độc nhất và sẽ không được sử dụng ở
              trên bất kì tài khoản nào khác
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmVerifyEmail}
            >
              Đã xác nhận
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PhoneNumberVerificationModal;
