import React, { useState } from 'react';
import {
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Link,
} from '@mui/material';
import { Element, scroller } from 'react-scroll';
import HowItWorksImage from '../../../assets/how-it-works-hero.jpg';

const steps = [
  {
    label: 'Tạo hồ sơ',
    description: `Cập nhật hồ sơ cá nhân của bạn với những thông tin chuyên nghiệp. Đồng thời xác minh các thông tin cá nhân để gây dựng uy tín cho tài khoản.`,
  },
  {
    label: 'Đấu thầu',
    description: `Tìm kiếm các dự án phù hợp với kỹ năng cá nhân. Đăng ký nhận công việc hay dự án bằng cách đưa ra các mức giá bạn mong muốn nhận được để hoàn thành công việc cùng lời chào hấp dẫn`,
  },
  {
    label: 'Nhận việc',
    description: `Khi được chấp nhận, bạn sẽ nhận được thông báo, người đăng việc sẽ nhắn tin trực tiếp cho bạn trên website và bạn có thể bắt đầu công việc`,
  },
  {
    label: 'Hoàn thành công việc',
    description: `Sau khi hoàn thành công việc hay dự án, nếu công việc đòi hỏi bạn chứng minh kết quả công việc, hãy vào mục Dự án của bạn trên thanh điều hướng trên và xác nhận bạn đã hoàn thành công việc. Sau đó bạn sẽ nhận được thông báo người đăng việc có chấp thuận hay không`,
  },
  {
    label: 'Nhận thù lao',
    description: `Khi người đăng việc đã chấp thuận kết quả công việc của bạn, hãy nhắn tin trực tiếp với nhau để bàn về cách nhận thù lao mà các bạn mong muốn`,
  },
];

const HowItWorks = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleScroll = () => {
    scroller.scrollTo('workProcess', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <div className="max-width-container">
      <div className="relative md:bg-[#67beb0]">
        <div className="flex flex-wrap items-center justify-end">
          <div className="md:w-1/2 bg-green w-full md:absolute top-0 bottom-0 left-0 max-w-screen">
            <img
              className="object-cover w-full h-full"
              src={HowItWorksImage}
              alt="How it works"
            />
          </div>
          <div className="md:relative w-full md:w-auto z-10 md:my-20 xl:p-20 md:p-12 py-12 bg-white text-black left-auto right-40">
            <div className="md:max-w-md">
              <Typography className="text-[4.11em] font-semibold">
                Hướng dẫn
              </Typography>
              <Typography>
                Chúng tôi luôn ở đây sẵn sàng hỗ trợ bạn trên con đường trở
                thành freelancer. Cùng chúng tôi tìm hiểu quy trình làm việc
                trên website
              </Typography>
              <div className="flex flex-wrap sm:mt-8 mt-5 -m-1">
                <Button
                  variant="contained"
                  onClick={handleScroll}
                  className="bg-black"
                >
                  Quy trình nhận việc
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Element name="workProcess" className="mt-20">
        <section className="py-8 bg-gray-100 m-auto max-w-7xl">
          <div className="max-w-4xl mx-auto">
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                  <StepContent>
                    <Typography>{step.description}</Typography>
                    <div className="mt-2">
                      <div>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          className="bg-black mr-2"
                        >
                          {index === steps.length - 1
                            ? 'Hoàn thành'
                            : 'Tiếp tục'}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          className="mr-2"
                        >
                          Quay lại
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} className="p-5">
                <Typography>
                  Chúc mừng! Bạn đã hiểu cơ bản về cách website chúng tôi hoạt
                  động. Hãy bắt đầu{' '}
                  <span>
                    <Link href="/search">tìm kiếm công việc</Link>
                  </span>{' '}
                  ngay thôi nào.
                </Typography>
                <Button
                  onClick={handleReset}
                  className="bg-black mt-5"
                  sx={{ color: 'white' }}
                >
                  Bắt đầu lại từ đầu
                </Button>
              </Paper>
            )}
          </div>
        </section>
      </Element>
    </div>
  );
};

export default HowItWorks;
