import {
  Box,
  Button,
  Divider,
  Paper,
  Typography,
  Tooltip,
  FilledInput,
  InputAdornment,
  IconButton,
  TextField,
  Snackbar,
  Alert,
  Rating,
} from '@mui/material';
import React, { useState } from 'react';
import { ROLES } from '../../../constaints/role';
import TypographyTitle from '../../../components/Typography/TypographyTitle';
import { formatDate, formatDatee } from '../../../utils/formatDate';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import ReportModal from '../Profile/component/ReportModal';
import reportApi from '../../../services/reportApi';
import { toast } from 'react-toastify';
import TypographyHeader from '../../../components/Typography/TypographyHeader';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { formatCurrency } from '../../../utils/formatCurrency';
import BlogDescription from '../../../components/BlogDescription';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import FavoriteBorderTwoToneIcon from '@mui/icons-material/FavoriteBorderTwoTone';
import projectApi from '../../../services/projectApi';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import PaymentIcon from '@mui/icons-material/Payment';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

const ProjectDetail = ({
  detail,
  navigate,
  setOpenDelete,
  currentUser,
  handleOpenUpdate,
  projectId,
  myBidding,
  handleOpen,
  UpdateStatus,
  setReload,
}) => {
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const { t } = useTranslation('project_detail');

  const handleReport = async (reportData) => {
    await reportApi.createReport(reportData);
    toast.error(`${t('report')}`);
  };

  const handleDeleteFavorite = async (userId, projectId) => {
    try {
      const response = await projectApi.DeleteFavorite({ userId, projectId });
      setSnackbar({
        open: true,
        message: response.message,
        severity: 'success',
      });
      setReload((prev) => !prev);
    } catch (error) {
      setSnackbar({
        open: true,
        message: `${t('favorite_error_remove')}`,
        severity: 'error',
      });
      console.error(error);
    }
  };

  const handleAddFavorite = async (userId, projectId) => {
    try {
      const response = await projectApi.AddFavorite({ userId, projectId });
      setSnackbar({
        open: true,
        message: response.message,
        severity: 'success',
      });
      setReload((prev) => !prev);
    } catch (error) {
      setSnackbar({
        open: true,
        message: `${t('favorite_error_add')}`,
        severity: 'error',
      });
      console.error(error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };
  const handleUpdateNavigate = (projectId) => {
    if (currentUser?.role === ROLES.FREELANCER) {
      navigate(`/freelancer-update-project/${projectId}`);
    }
    if (currentUser?.role === ROLES.RECRUITER) {
      navigate(`/update-project/${projectId}`);
    }
  };
  return (
    <Box display="flex" mt={4}>
      <Box flex="3">
        <Paper sx={{ bgcolor: '#FFFFFF', boxShadow: 3, borderRadius: 2 }}>
          <Box p={4} display="flex" justifyContent="space-between">
            <Box>
              <Box display="flex" alignItems="center">
                <TypographyTitle title={detail?.title} color="blue" />
                <Box
                  sx={{
                    display: 'inline-block',
                    padding: '4px 8px',
                    backgroundColor: detail?.projectStatus?.statusColor,
                    borderRadius: '10px',
                    border: '1px solid #ccc',
                    ml: 2,
                  }}
                >
                  <Typography fontSize="10px">
                    {detail?.projectStatus?.statusName}
                  </Typography>
                </Box>
              </Box>
              <Box className="mt-2 inline-block rounded-2xl bg-gray-300 p-2">
                <Typography fontSize="15px">
                  {detail?.category?.categoryName}
                </Typography>
              </Box>
            </Box>
            <Box className="flex-col items-center">
              <Typography className="mr-4 font-bold text-sm">
                {t('budget')} : {formatCurrency(detail?.minBudget)} -{' '}
                {formatCurrency(detail?.maxBudget)}
              </Typography>
              <Typography className="mr-4 font-bold text-sm">
                {t('time')} : {detail?.duration} {t('day')}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box p={4}>
            <Typography fontWeight="bold">{t('infomation')}</Typography>
            <Box mt={2}>
              <BlogDescription description={detail?.description} />
            </Box>
          </Box>
          <Box pl={4}>
            <Typography fontWeight="bold">{t('skill_required')}</Typography>
            <Box display="flex" flexWrap="wrap">
              {detail?.skill?.map((item, index) => (
                <Box
                  key={index}
                  sx={{ '&:hover': { backgroundColor: '#f0f8ff' } }}
                  className="mt-2 ml-2 inline-block rounded-3xl border border-sky-500 p-2"
                >
                  <Typography fontSize="15px">{item}</Typography>
                </Box>
              ))}
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={4}
            pb={0}
          >
            <Typography fontSize="12px">
              {t('time_create')} : {formatDate(detail?.createdDate)}
            </Typography>
            <Box display="flex" alignItems="center">
              {currentUser?.userId !== detail?.createdBy && (
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() => setIsReportModalOpen(true)}
                  className="text-blue-600 cursor-pointer"
                >
                  <Tooltip title={t('report_project')}>
                    <FlagCircleIcon />
                  </Tooltip>
                  <Typography ml={1} fontSize="12px">
                    {t('report_project')}
                  </Typography>
                </Box>
              )}
              <Box display="flex" sx={{ ml: 2 }}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    detail?.isFavorite
                      ? handleDeleteFavorite(currentUser?.userId, detail?.id)
                      : handleAddFavorite(currentUser?.userId, detail?.id);
                  }}
                >
                  {detail?.isFavorite ? (
                    <FavoriteTwoToneIcon sx={{ color: '#fad702' }} />
                  ) : (
                    <FavoriteBorderTwoToneIcon />
                  )}
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box p={4} pb={0}>
            <Box
              p={2}
              border="1px solid"
              borderRadius="4px"
              style={{
                backgroundColor:
                  detail?.statusId === 5
                    ? 'var(--background-warning-color)'
                    : '#FFF8E1',
                borderColor:
                  detail?.statusId === 5 ? 'var(--secondary-color)' : 'orange',
              }}
            >
              <Typography
                fontWeight="bold"
                style={{
                  color:
                    detail?.statusId === 5
                      ? 'var(--secondary-color)'
                      : 'orange',
                }}
              >
                {detail?.statusId === 5
                  ? `${t('report_reason')}`
                  : `${t('note')}`}
              </Typography>
              <Typography fontSize="14px">
                {detail?.statusId === 5
                  ? detail?.rejectReason
                  : `${t('note_content')}`}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" p={4} gap={2}>
            {currentUser?.userId === detail?.createdBy && (
              <>
                {detail?.statusId === 5 && (
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={() => UpdateStatus(detail?.id)}
                  >
                    <FileUploadOutlinedIcon />
                    <Typography> {t('reup')}</Typography>
                  </Button>
                )}

                {(detail?.statusId === 1 || detail?.statusId === 5) && (
                  <>
                    <Button
                      variant="outlined"
                      onClick={() => handleUpdateNavigate(detail?.id)}
                    >
                      <EditOutlinedIcon />
                      <Typography>{t('edit')} </Typography>
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={(e) => setOpenDelete(true)}
                    >
                      <DeleteOutlineOutlinedIcon />
                      <Typography> {t('remove')} </Typography>
                    </Button>
                  </>
                )}
              </>
            )}
            {myBidding === null &&
              detail?.statusId === 2 &&
              currentUser?.role !== ROLES.RECRUITER &&
              currentUser?.role !== ROLES.ADMIN &&
              currentUser?.userId !== detail?.createdBy && (
                <Button variant="outlined" onClick={handleOpen}>
                  {t('bid')}
                </Button>
              )}
          </Box>
        </Paper>
        {myBidding !== null && detail?.statusId === 2 && (
          <>
            <Paper
              sx={{ bgcolor: '#F8F8FF', borderRadius: '5px', mt: 2, p: 5 }}
            >
              <Box display="flex" justifyContent="space-between">
                <TypographyHeader title={t('bid_info')} />
                <Tooltip title={t('edit')}>
                  <IconButton
                    type="button"
                    sx={{
                      bgcolor: '#FFFF99',
                      border: '1px solid #444444',
                    }}
                    onClick={(e) => handleOpenUpdate(e)}
                    p={1}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box display="flex" mt={3} justifyContent="space-between">
                <Box>
                  <TypographyTitle title={t('budget_wish')} />
                  <FilledInput
                    sx={{
                      bgcolor: '#FFFFFF',
                      mt: 2,
                    }}
                    value={myBidding?.budget}
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">VND</InputAdornment>
                    }
                    inputProps={{
                      'aria-label': 'weight',
                      readOnly: true,
                    }}
                  />
                </Box>
                <Box>
                  <TypographyTitle title={t('time_wish')} />
                  <FilledInput
                    sx={{
                      bgcolor: '#FFFFFF',
                      mt: 2,
                    }}
                    value={myBidding?.duration}
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">{t('day')}</InputAdornment>
                    }
                    inputProps={{
                      'aria-label': 'weight',
                      readOnly: true,
                    }}
                  />
                </Box>
              </Box>
              <Box mt={3}>
                <TypographyTitle title={t('comment')} />
                <TextField
                  fullWidth
                  sx={{
                    bgcolor: '#FFFFFF',
                    mt: 2,
                  }}
                  inputProps={{
                    readOnly: true,
                  }}
                  value={myBidding?.proposal}
                />
              </Box>
            </Paper>
          </>
        )}
      </Box>
      <Box flex="1" ml={4} display="flex" flexDirection="column" gap={2}>
        <Paper sx={{ p: 2 }}>
          <TypographyTitle title={t('recruiter_info')} />
          <Divider sx={{ m: 2 }} />
          <Typography mt={2}>
            <ApartmentOutlinedIcon color="primary" sx={{ mr: 1 }} />
            {t('recruiter')} : {detail?.appUser2?.name}
          </Typography>
          <Box display="flex" mt={2} gap={2}>
            <Tooltip title={t('rate')}>
              <Box display="flex" gap={1}>
                <AccountCircleOutlinedIcon color="primary" />
                <Rating
                  name="read-only"
                  value={detail?.appUser2?.avgRate}
                  readOnly
                />
                <Typography>{detail?.appUser2?.avgRate}</Typography>
              </Box>
            </Tooltip>
            <Tooltip title={t('time_rate')}>
              <Box display="flex" gap={1}>
                <HowToVoteIcon color="primary" />
                <Typography>{detail?.appUser2?.totalRate}</Typography>
              </Box>
            </Tooltip>
          </Box>

          {detail?.appUser2?.city && (
            <Typography mt={2}>
              <LocationOnIcon color="primary" sx={{ mr: 1 }} />
              {t('address')} : {detail?.appUser2?.city}, {''}
              {detail?.appUser2?.country}
            </Typography>
          )}
          <Typography mt={2}>
            <AccessTimeIcon color="primary" sx={{ mr: 1 }} />
            {t('time_join')} : {formatDatee(detail?.appUser2?.createdDate)}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography mt={2}>
                <PaymentIcon
                  sx={{ mr: 1 }}
                  color={
                    detail?.appUser2?.isPaid === true ? 'success' : 'error'
                  }
                />
                {t('payment')}
              </Typography>
              <Typography mt={2}>
                <PhoneAndroidIcon
                  sx={{ mr: 1 }}
                  color={
                    detail?.appUser2?.phoneConfirmed === true
                      ? 'success'
                      : 'error'
                  }
                />
                {t('phone')}
              </Typography>
              <Typography mt={2}>
                <EmailIcon
                  sx={{ mr: 1 }}
                  color={
                    detail?.appUser2?.emailConfirmed === true
                      ? 'success'
                      : 'error'
                  }
                />
                {t('email')}
              </Typography>
            </Box>
            <Box>
              <Typography mt={2}>
                {detail?.appUser2?.isPaid === true ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <CancelIcon color="error" />
                )}
              </Typography>
              <Typography mt={2}>
                {detail?.appUser2?.phoneConfirmed === true ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <CancelIcon color="error" />
                )}
              </Typography>
              <Typography mt={2}>
                {detail?.appUser2?.emailConfirmed === true ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <CancelIcon color="error" />
                )}
              </Typography>
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ p: 2 }}>
          <TypographyTitle title={t('good_bid')} />
          <Divider sx={{ m: 2 }} />
          <Box
            display="flex"
            mb={2}
            alignContent="center"
            justifyContent="center"
          >
            <img
              src="https://www.f-cdn.com/assets/main/en/assets/project-view-page/icons-big/highlight.svg"
              alt="bidding"
            />
          </Box>
          <Typography>{t('good_bid_1')}</Typography>
          <Typography mt={2}>{t('good_bid_2')}</Typography>
          <Typography mt={2}>
            <FiberManualRecordIcon fontSize="8px" sx={{ mr: 1 }} />
            {t('good_bid_3')}
          </Typography>
          <Typography mt={2}>
            <FiberManualRecordIcon fontSize="8px" sx={{ mr: 1 }} />
            {t('good_bid_4')}
          </Typography>
          <Typography mt={2}>
            <FiberManualRecordIcon fontSize="8px" sx={{ mr: 1 }} />
            {t('good_bid_5')}
          </Typography>
          <Typography mt={2}>
            <FiberManualRecordIcon fontSize="8px" sx={{ mr: 1 }} />
            {t('good_bid_6')}
          </Typography>

          <Typography mt={2}>{t('good_bid_7')}</Typography>
        </Paper>

        <ReportModal
          open={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onReport={handleReport}
          type="project"
          projectId={projectId}
        />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant="filled"
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default ProjectDetail;
