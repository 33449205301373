import { Box } from '@mui/material';
import React from 'react';
import TopBar from './TopBarFreelancer';
import Footer from '../../../components/Footer';

const LayOutFreelancer = () => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Box flexShrink={0}>
        <TopBar />
      </Box>
      <Box flexGrow={1}></Box>
      <Box flexShrink={0}>
        <Footer />
      </Box>
    </Box>
  );
};

export default LayOutFreelancer;
