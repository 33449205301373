import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import categoryApi from '../../../services/categoryApi';

const DeleteCategory = ({ onClose, category, restored, setReloadCategory }) => {
  const handleDelete = async () => {
    try {
      if (restored === true) {
        await categoryApi.RestoreDeleted(category.id);
        toast.success('Đã khôi phục lại danh mục');
      } else {
        await categoryApi.DeleteCategory(category.id);
        toast.success('Đã xóa danh mục thành công');
      }
      setReloadCategory((prev) => !prev);
      onClose();
    } catch (error) {
      toast.error('Đã xảy ra lỗi khi thay đổi danh mục');
      console.error(error);
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ fontSize: '1.5rem' }} mb={2}>
        {restored ? 'Khôi phục' : 'Xóa'} danh mục
      </Typography>
      <Typography mb={2}>
        Bạn có chắc chắn {restored ? 'khôi phục' : 'xóa'} danh mục "
        {category.categoryName}"?
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Button variant="contained" color="secondary" onClick={handleDelete}>
          {restored ? 'Khôi phục' : 'Xóa'}
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Hủy bỏ
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteCategory;
