import { Box, Button, IconButton, TextField, Tooltip } from '@mui/material';
import React from 'react';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { imageDb } from '../../../firebase/firebaseConfig';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
const SendMessage = ({
  handleSendMessage,
  message,
  setMessage,
  setImage,
  image,
}) => {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevents the default form submission behavior
      handleSendMessage(message);
    }
  };
  const handleRemoveImage = () => {
    setImage('');
  };
  const handleUpload = (e) => {
    if (e) {
      if (
        e.name.endsWith('.jpg') ||
        e.name.endsWith('.png') ||
        e.name.endsWith('.webp') ||
        e.name.endsWith('.jpeg')
      ) {
        const imgRef = ref(imageDb, `file/${v4()}`);
        uploadBytes(imgRef, e)
          .then((value) => getDownloadURL(value.ref))
          .then((url) => {
            setImage(url);
          })
          .catch((error) => {
            console.error(error);
            toast.error('Upload failed');
          });
      } else {
        toast.error('* File phải là ảnh');
      }
    } else {
      toast.error('Chưa file nào được chọn');
    }
  };

  return (
    <Box
      component="footer"
      pl={2}
      pr={2}
      display="flex"
      flexDirection="column"
      mt={1}
      width="100%"
    >
      {image !== '' && (
        <Box
          border="1px solid #ccc"
          p={1}
          ml={4}
          mr={4}
          display="flex"
          alignItems="center"
          position="relative"
        >
          <img
            src={image}
            alt="Selected"
            style={{ height: '40px', marginRight: 8 }}
          />
          <IconButton
            size="large"
            onClick={(e) => handleRemoveImage()}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              transform: 'translate(50%, -50%)',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <Box display="flex" alignItems="center" mb={1}>
        <Tooltip title="Chọn ảnh">
          <IconButton component="label" size="small">
            <AddPhotoAlternateIcon />
            <input
              type="file"
              hidden
              onChange={(e) => handleUpload(e.target.files[0])}
            />
          </IconButton>
        </Tooltip>
        <Box flexGrow={1} display="flex" alignItems="center">
          <TextField
            fullWidth
            placeholder="Aa...."
            value={message}
            size="small"
            multiline
            maxRows={4}
            onKeyDown={handleKeyPress}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Box>
        <Button
          variant="contained"
          onClick={() => handleSendMessage(message)}
          style={{ marginLeft: 8 }}
        >
          <SendIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default SendMessage;
