import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Popover,
  Toolbar,
  Typography,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { logOutSuccess } from '../../../redux/authSlice';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import profileApi from '../../../services/profileApi';
import LanguageSelector from '../../../components/language-selector';
import { UseChatState } from '../../../providers/ConnectContext';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import LogoText from '../../../assets/Logo_Text_GJ.png';

import notificationApi from '../../../services/notificationApi';
import ListUser from '../../Common/Chat/ListUser';
import chatApi from '../../../services/chatApi';
import CustomAvatar from '../../../components/CustomAvatar';
import { styled } from '@mui/system';
import { ROLES } from '../../../constaints/role';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CustomLogin = styled(Button)(({ theme }) => ({
  backgroundColor: 'var(--primary-color)',
  color: 'var(--primary-color)',
  border: '1px solid var(--background-color)',
  borderRadius: '30px',
  padding: '10px 20px',
  fontSize: '16px',
  fontWeight: 'bold',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  letterSpacing: '0.05rem',
  transition: 'background-color 0.3s, transform 0.3s, color 0.4s',
  '& span': {
    position: 'relative',
    color: 'var(--text-color)',
    zIndex: 10,
    transition: 'color 0.4s',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '120%',
    height: '150%',
    zIndex: 0,
    background: '#fff',
    transform: 'skew(30deg)',
    transition: 'transform 0.4s cubic-bezier(0.3, 1, 0.8, 1)',
  },
  '&:hover::before': {
    transform: 'translate3d(100%, 0, 0)',
  },
  '&:hover span': {
    color: 'var(--primary-color)',
  },
  '&:hover': {
    backgroundColor: 'var(--text-color)',
    border: '1px solid var(--background-color)',
    color: 'var(--primary-color)',
  },
  '&:active': {
    transform: 'scale(1)',
  },
  '&:disabled': {
    backgroundColor: 'var(--background-warning-color)',
    cursor: 'not-allowed',
    opacity: 0.7,
  },
}));

const TopBarFreelancer = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const currentUser = useSelector((state) => state.auth.login?.currentUser);
  const [profile, setProfile] = useState();
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [topMenuAnchorEl, setTopMenuAnchorEl] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [anchorElMessage, setAnchorElMessage] = useState(null);
  const { t } = useTranslation('topbar');

  const hanldCloseAll = () => {
    setAnchorElUser(null);
    setAnchorEl(null);
    setMenuAnchorEl(null);
    setTopMenuAnchorEl(null);
    setAnchorElMessage(null);
    setSelectedNotification(null);
  };

  const {
    connection,
    connectionFirst,
    userConnection,
    numberOfMessage,
    setNumberOfMessage,
    listNotification,
    setListNotification,
    numberOfNotification,
    setNumberOfNotification,
    setChatSelect,
  } = UseChatState();

  useEffect(() => {
    if (currentUser) {
      const getData = async () => {
        const res = await profileApi.getUserProfile();
        if (res?.lockoutEnabled === false) {
          toast.error('Tài khoản của bạn đã bị khoá');
          handleLogOut();
        }
        setProfile(res);
      };
      getData();
    }
  }, [currentUser]);

  useEffect(() => {
    const getNotification = async () => {
      if (currentUser !== null) {
        try {
          let res = await notificationApi.GetAllNotification(
            currentUser?.userId
          );
          setListNotification(res);
        } catch (error) {
          console.error(error);
        }
      }
    };
    getNotification();
  }, [currentUser,anchorEl]);

  useEffect(() => {
    if (currentUser) {
      listNotification.forEach((notification) => {
        if (notification?.notificationType === 666) {
          toast.error('Tài khoản của bạn đã bị khoá');
          handleLogOut();
        }
      });
    }
  }, [currentUser, listNotification]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleProfile = () => {
    hanldCloseAll();
    navigate('/profile');
  };

  const handleProject = () => {
    navigate('/current-project');
  };

  const handleSetting = () => {
    hanldCloseAll();
    navigate('/profile-setting');
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = async () => {
    try {
      dispatch(logOutSuccess());
      localStorage.clear();
      if (connection !== null) {
        await connection?.stop();
      }
      if (connectionFirst !== null) {
        await connectionFirst?.stop();
      }
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  };
  const handlePayment = () => {
    hanldCloseAll();
    navigate('/payment');
  };
  const handleSearch = () => {
    hanldCloseAll();
    navigate(`/search/${search}`);
  };

  const handleNotificationClick = (event) => {
    setNumberOfNotification(0);
    setAnchorEl(event.currentTarget);
  };

  const handleMessageClick = (event) => {
    setNumberOfMessage(0);
    setAnchorElMessage(event.currentTarget);
  };

  const handleMessageClose = () => {
    setAnchorElMessage(null);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event, notification) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedNotification(notification);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedNotification(null);
  };

  const handleMenuOptionClick = async (option) => {
    if (option === 'markAsRead') {
      await notificationApi.MarkToRead(selectedNotification?.notificationId);
      updateNotificationStatus(selectedNotification?.notificationId);
    } else if (option === 'delete') {
      await notificationApi.DeleteNotification(
        selectedNotification?.notificationId
      );
      removeNotificationStatus(selectedNotification?.notificationId);
    }
    handleMenuClose();
  };

  const handleTopMenuOpen = (event) => {
    setTopMenuAnchorEl(event.currentTarget);
  };

  const handleTopMenuClose = () => {
    setTopMenuAnchorEl(null);
  };

  const handleTopMenuOptionClick = async (option) => {
    if (option === 'markAllAsRead') {
      await notificationApi.MarkToReadAll(currentUser?.userId);
      updateNotificationStatusAll();
    } else if (option === 'deleteAll') {
      await notificationApi.DeleteAllNotification(currentUser?.userId);
      removeNotificationStatusAll();
    }
    hanldCloseAll();
  };

  const handleCheck = async (link, notificationId, isRead) => {
    if (isRead === 0) {
      await notificationApi.MarkToRead(notificationId);
      updateNotificationStatus(notificationId);
      navigate(link);
    } else {
      navigate(link);
    }
    hanldCloseAll();
  };

  const getNotificationColor = (isRead) => {
    return isRead === 1 ? '#F8F8FF' : '#C6E2FF';
  };

  const updateNotificationStatusAll = () => {
    setListNotification((prevNotifications) =>
      prevNotifications.map((notification) => ({
        ...notification,
        isRead: 1,
      }))
    );
  };

  const updateNotificationStatus = (notificationId) => {
    setListNotification((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.notificationId === notificationId
          ? { ...notification, isRead: 1 }
          : notification
      )
    );
  };

  const removeNotificationStatus = (notificationId) => {
    setListNotification((prevNotifications) =>
      prevNotifications.filter(
        (notification) => notification.notificationId !== notificationId
      )
    );
  };

  const removeNotificationStatusAll = () => {
    setListNotification([]);
  };

  const hanldeSelectChat = async (conversationId, userId, senderId, isRead) => {
    setChatSelect(conversationId);
    if (senderId !== currentUser?.userId && isRead === 0) {
      await chatApi.markToRead(conversationId);
    }
    hanldCloseAll();
    navigate(`/chat/${conversationId}/${userId}`);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleCreateProject = () => {
    if (currentUser?.role === ROLES.FREELANCER) {
      navigate('/freelancer-create-project');
    }
    if (currentUser?.role === ROLES.RECRUITER) {
      navigate('/create-new-project');
    }
  };
  const handleHome = () => {
    if (currentUser?.role === ROLES.FREELANCER || currentUser === null) {
      navigate('/');
    } else if (currentUser?.role === ROLES.RECRUITER) {
      navigate('/recruiter');
    } else if (currentUser?.role === ROLES.ADMIN) {
      navigate('/admin');
    }
  };

  return (
    <>
      <AppBar position="sticky" sx={{ bgcolor: '#fff' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
            <Box
              component="a"
              onClick={() => handleHome()}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                mr: 2,
              }}
            >
              <img
                src={LogoText}
                alt="GoodJobs Logo"
                style={{
                  height: '30px',
                }}
              />
            </Box>

            <Box display="flex" flex={1} maxWidth="600px" mx={2}>
              {currentUser?.role !== 'Recruiter' &&
                currentUser?.role !== 'Admin' && (
                  <Box
                    display="flex"
                    flex={1}
                    borderRadius="30px"
                    bgcolor="#EEEEEE"
                  >
                    <InputBase
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={handleKeyPress}
                      placeholder={t('search')}
                      sx={{ ml: 2, mr: 2, flex: 1 }}
                    />
                    <IconButton
                      type="button"
                      onClick={(e) => handleSearch(e)}
                      p={1}
                    >
                      <SearchOutlinedIcon />
                    </IconButton>
                  </Box>
                )}
            </Box>
            {currentUser && currentUser?.role === ROLES.FREELANCER && (
              <>
                <Tooltip title={t('create_project')} arrow>
                  <Button
                    sx={{
                      bgcolor: 'var(--primary-color)',
                      color: 'var(--text-color)',
                      fontSize: '12px',
                      borderRadius: '30px',
                      '&:hover': { bgcolor: '#e9c800' },
                    }}
                    onClick={() => handleCreateProject()}
                    startIcon={<AddIcon />}
                  >
                    {t('create_project')}
                  </Button>
                </Tooltip>
              </>
            )}

            {currentUser ? (
              <Box display="flex" gap={2} alignItems="center">
                <IconButton onClick={handleMessageClick}>
                  <Badge badgeContent={numberOfMessage} color="error">
                    <Tooltip title={t('message')}>
                      <MessageRoundedIcon />
                    </Tooltip>
                  </Badge>
                </IconButton>

                <Popover
                  id="message-popover"
                  open={Boolean(anchorElMessage)}
                  anchorEl={anchorElMessage}
                  onClose={handleMessageClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box
                    p={2}
                    sx={{
                      maxHeight: '400px',
                      overflow: 'auto',
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h6" className="font-bold text-xl">
                        {t('message')}
                      </Typography>
                    </Box>
                    <ListUser
                      listUser={userConnection}
                      hanldeSelectChat={hanldeSelectChat}
                      currentUser={currentUser}
                    />
                  </Box>
                </Popover>
                {currentUser?.role !== ROLES.ADMIN && (
                  <IconButton onClick={handleNotificationClick}>
                    <Badge badgeContent={numberOfNotification} color="error">
                      <Tooltip title={t('notification')}>
                        <NotificationsRoundedIcon />
                      </Tooltip>
                    </Badge>
                  </IconButton>
                )}

                <Popover
                  id="notifications-popover"
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleNotificationClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box
                    p={2}
                    sx={{
                      maxHeight: '400px',
                      overflow: 'auto',
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h6" className="font-bold text-xl">
                        {t('notification')}
                      </Typography>
                      <IconButton edge="end" onClick={handleTopMenuOpen}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={topMenuAnchorEl}
                        open={Boolean(topMenuAnchorEl)}
                        onClose={handleTopMenuClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <MenuItem
                          onClick={() =>
                            handleTopMenuOptionClick('markAllAsRead')
                          }
                        >
                          {t('mark_as_read_all')}
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleTopMenuOptionClick('deleteAll')}
                        >
                          {t('delete_all')}
                        </MenuItem>
                      </Menu>
                    </Box>
                    <List sx={{ width: '250px' }}>
                      {listNotification?.length === 0 ? (
                        <ListItem>
                          <ListItemText secondary={t('no_notification')} />
                        </ListItem>
                      ) : (
                        listNotification.map((item, index) => (
                          <ListItemButton
                            key={index}
                            sx={{
                              backgroundColor: getNotificationColor(
                                item?.isRead
                              ),
                              borderBottom: '1px solid #CCCCCC',
                            }}
                          >
                            <ListItemText
                              primary={
                                <Box>
                                  <Typography>
                                    {item?.sendUserName} {item?.description}
                                  </Typography>
                                  <Typography
                                    style={{ color: 'gray', fontSize: '0.8em' }}
                                  >
                                    {new Date(item?.datetime).toLocaleString()}
                                  </Typography>
                                </Box>
                              }
                              onClick={() =>
                                handleCheck(
                                  item?.link,
                                  item?.notificationId,
                                  item?.isRead
                                )
                              }
                            />
                            <IconButton
                              edge="end"
                              onClick={(event) => handleMenuOpen(event, item)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </ListItemButton>
                        ))
                      )}
                    </List>
                  </Box>
                </Popover>

                <Menu
                  anchorEl={menuAnchorEl}
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={() => handleMenuOptionClick('markAsRead')}>
                    {t('mark_as_read')}
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuOptionClick('delete')}>
                    {t('delete')}
                  </MenuItem>
                </Menu>
                {currentUser?.role === ROLES.FREELANCER && (
                  <IconButton onClick={() => navigate('/favorite-list')}>
                    <Tooltip title={t('favorites')}>
                      <FavoriteRoundedIcon />
                    </Tooltip>
                  </IconButton>
                )}

                <LanguageSelector />
                <Box>
                  <Typography sx={{ fontWeight: 'bold', color: 'black' }}>
                    {profile?.name}
                  </Typography>
                  {currentUser?.role === ROLES.FREELANCER && (
                    <Typography sx={{ fontSize: '10px', color: 'black' }}>
                      {t('times_bids')} : {profile?.amountBid} {t('times')}
                    </Typography>
                  )}
                  {currentUser?.role === ROLES.RECRUITER && (
                    <Typography sx={{ fontSize: '10px', color: 'black' }}>
                      {t('time_project')} : {profile?.amoutProject} {t('times')}
                    </Typography>
                  )}
                </Box>

                <Tooltip title={t('account')}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    {profile?.avatar === null || profile?.avatar === '' ? (
                      <CustomAvatar name={profile?.name} />
                    ) : (
                      <Avatar alt="Profile Picture" src={profile?.avatar} />
                    )}
                  </IconButton>
                </Tooltip>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleProfile}>
                    <ListItemIcon>
                      <AccountCircleOutlinedIcon sx={{ mr: 1 }} />
                    </ListItemIcon>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                      {t('profile')}
                    </Typography>
                  </MenuItem>
                  {currentUser?.role === ROLES.FREELANCER && (
                    <MenuItem onClick={handleProject}>
                      <ListItemIcon>
                        <WorkOutlineRoundedIcon sx={{ mr: 1 }} />
                      </ListItemIcon>
                      <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {t('project')}
                      </Typography>
                    </MenuItem>
                  )}
                  {currentUser?.role === ROLES.FREELANCER && (
                    <MenuItem onClick={handleSetting}>
                      <ListItemIcon>
                        <SettingsOutlinedIcon sx={{ mr: 1 }} />
                      </ListItemIcon>
                      <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {t('setting')}
                      </Typography>
                    </MenuItem>
                  )}
                  <Divider />
                  {currentUser?.role === ROLES.FREELANCER && (
                    <MenuItem onClick={handlePayment}>
                      <ListItemIcon>
                        <StorefrontOutlinedIcon sx={{ mr: 1 }} />
                      </ListItemIcon>
                      <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {t('buy_more')}
                      </Typography>
                    </MenuItem>
                  )}

                  <Divider />

                  <MenuItem onClick={handleLogOut}>
                    <ListItemIcon>
                      <LogoutIcon sx={{ mr: 1 }} />
                    </ListItemIcon>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                      {t('log_out')}
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box display="flex" gap={2}>
                <CustomLogin
                  variant="outlined"
                  disableElevation
                  onClick={() =>
                    navigate('/login', { state: { showLogin: true } })
                  }
                >
                  <span> {t('log_in')} </span>
                </CustomLogin>
                <CustomLogin
                  variant="outlined"
                  disableElevation
                  onClick={() =>
                    navigate('/login', { state: { showLogin: false } })
                  }
                >
                  <span> {t('register')} </span>
                </CustomLogin>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Outlet />
    </>
  );
};

export default TopBarFreelancer;
