import React from 'react';
import { Box } from '@mui/material';
import heroImage from '../assets/hero-image.png';

function HeroSection() {
  return (
    <Box
    // sx={{
    //   margin: '0 auto',
    //   padding: '24px 24px 48px',
    //   marginTop: 5,
    //   maxWidth: '100%',
    //   borderRadius: 4,
    //   marginBottom: 5,
    //   height: '80vh',
    //   backgroundImage: `url(${heroImage})`,
    //   backgroundSize: 'cover',
    //   backgroundPosition: 'center',
    //   display: 'flex',
    //   flexDirection: 'column',
    //   color: 'white',
    //   textAlign: 'center',
    // }}
    >
      <img
        src={heroImage}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '8px',
          objectFit: 'cover',
          scale: '0.9',
        }}
        alt="Avatar"
      />
    </Box>
  );
}

export default HeroSection;
