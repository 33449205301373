import React from 'react';
import {
  Avatar,
  Box,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import CustomAvatar from '../../../components/CustomAvatar';
import { truncateText } from '../../../utils/truncateText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const UserListContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  overflow: 'auto',
  maxHeight: window.innerHeight - 100,
}));

const UserListItem = styled(ListItemButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '10px',
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const UserName = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(0.5),
}));

const MessagePreview = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.9em',
}));

const Timestamp = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: '0.8em',
  marginLeft: 'auto',
}));

const getMessageColor = (isRead, senderId, currentUser) => {
  if (senderId === currentUser?.userId) {
    return '#FFFFFF';
  } else {
    return isRead === 1 ? '#FFFFFF' : '#F0F0F0';
  }
};

const ListUser = ({ listUser, currentUser, hanldeSelectChat }) => {
  const ListMessage = (message) => {
    if (message?.senderId === currentUser?.userId) {
      return (
        <>
          {message?.messageType === 2 ? (
            <MessagePreview>Bạn : Đã gửi 1 ảnh.</MessagePreview>
          ) : (
            <MessagePreview>
              Bạn: {truncateText(message?.messageText, 12)}
            </MessagePreview>
          )}
        </>
      );
    } else {
      return (
        <>
          {message?.messageType === 2 ? (
            <MessagePreview>Đã gửi 1 ảnh.</MessagePreview>
          ) : (
            <MessagePreview>
              {truncateText(message?.messageText, 12)}
            </MessagePreview>
          )}
        </>
      );
    }
  };

  return (
    <UserListContainer>
      {listUser.length === 0 || listUser === null || listUser === undefined ? (
        <Typography fontWeight="bold">Chưa kết nối ai </Typography>
      ) : (
        listUser.map(
          (item, index) =>
            item?.userId !== 72 && (
              <UserListItem
                key={index}
                sx={{
                  backgroundColor: getMessageColor(
                    item?.isRead,
                    item?.senderId,
                    currentUser
                  ),
                }}
                onClick={() =>
                  hanldeSelectChat(
                    item?.conversationId,
                    item?.userId,
                    item?.senderId,
                    item?.isRead
                  )
                }
              >
                {item?.avatar === null || item?.avatar === '' ? (
                  <CustomAvatar name={item?.userName} marginRight={2} />
                ) : (
                  <Box sx={{ position: 'relative', display: 'inline-block' }}>
                    <Avatar
                      src={item?.avatar}
                      alt={item?.userName}
                      sx={{ mr: 2 }}
                    />
                    {item?.isOnline === true && (
                      <FiberManualRecordIcon
                        color="success"
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          right: 0, // Changed to right for bottom-right positioning
                          width: '20px', // Increased size
                          height: '20px', // Increased size
                        }}
                      />
                    )}
                  </Box>
                )}
                <ListItemText
                  primary={
                    <>
                      <Tooltip title={item?.userName}>
                        <UserName>{truncateText(item?.userName, 12)}</UserName>
                      </Tooltip>
                      {ListMessage(item)}
                    </>
                  }
                />
                <Timestamp>
                  {new Date(item?.sendDate).toLocaleString()}
                </Timestamp>
              </UserListItem>
            )
        )
      )}
    </UserListContainer>
  );
};

export default ListUser;
