import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Slide,
  styled,
} from '@mui/material';
import { Star, Percent, WorkOutline, NoteAdd } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import paymentApi from '../../../services/paymentApi';
const ResponsiveGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));
const BidBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  textAlign: 'center',
  borderRadius: '12px',
  boxShadow: theme.shadows[4],
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[6],
  },
}));
const BidButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: '8px',
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const BuyProject = () => {
  const currentUser = useSelector((state) => state.auth.login?.currentUser);
  const navigate = useNavigate();
  const [loadingStates, setLoadingStates] = useState({});
  const [customProjectDialogOpen, setCustomProjectDialogOpen] = useState(false);
  const [customProjectValue, setCustomProjectValue] = useState('');
  const [error, setError] = useState('');

  const handleBuyProjects = async (project) => {
    setLoadingStates((prev) => ({ ...prev, [`project-${project}`]: true }));
    try {
      const response = await paymentApi.createPaymentProject(project);
      window.open(response.checkoutUrl, '_blank');
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [`project-${project}`]: false }));
    }
  };
  const handleCustomProjectDialogOpen = () => setCustomProjectDialogOpen(true);
  const handleCustomProjectDialogClose = () => {
    setCustomProjectDialogOpen(false);
    setCustomProjectValue('');
    setError('');
  };
  const handleCustomProjectConfirm = async () => {
    const customProject = parseInt(customProjectValue, 10);
    if (isNaN(customProject) || customProject <= 0) {
      setError('Hãy nhập số lượng mua hợp lệ.');
      return;
    }
    setCustomProjectDialogOpen(false);
    handleBuyProjects(customProject);
  };

  return (
    <Box m={4}>
      <Typography sx={{ fontSize: '2em' }} mb={4} variant="h4" gutterBottom>
        Mua thêm lượt đăng dự án
      </Typography>
      <ResponsiveGrid container spacing={6} justifyContent="center">
        <Grid item xs={12} sm={6} md={5}>
          <BidBox>
            <WorkOutline fontSize="large" color="primary" />
            <Typography variant="h6" mt={2}>
              Mua thêm 5 lượt đăng dự án
            </Typography>
            <BidButton
              variant="contained"
              color="primary"
              loading={loadingStates['project-5'] || false}
              onClick={() => {
                currentUser ? handleBuyProjects(5) : navigate('/login');
              }}
            >
              Mua ngay
            </BidButton>
          </BidBox>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <BidBox>
            <NoteAdd fontSize="large" color="secondary" />
            <Typography variant="h6" mt={2}>
              Mua thêm 10 lượt đăng dự án
            </Typography>
            <BidButton
              variant="contained"
              color="secondary"
              loading={loadingStates['project-10'] || false}
              onClick={() => {
                currentUser ? handleBuyProjects(10) : navigate('/login');
              }}
            >
              Mua ngay
            </BidButton>
          </BidBox>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <BidBox>
            <Star fontSize="large" color="success" />
            <Typography variant="h6" mt={2}>
              Mua thêm 15 lượt đăng dự án
            </Typography>
            <BidButton
              variant="contained"
              color="success"
              loading={loadingStates['project-15'] || false}
              onClick={() => {
                currentUser ? handleBuyProjects(15) : navigate('/login');
              }}
            >
              Mua ngay
            </BidButton>
          </BidBox>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <BidBox>
            <Percent fontSize="large" color="warning" />
            <Typography variant="h6" mt={2}>
              Mua lượt đăng dự án tùy chỉnh
            </Typography>
            <BidButton
              variant="contained"
              color="warning"
              onClick={handleCustomProjectDialogOpen}
            >
              Tùy chỉnh lượng mua
            </BidButton>
          </BidBox>
        </Grid>
      </ResponsiveGrid>
      <Dialog
        open={customProjectDialogOpen}
        onClose={handleCustomProjectDialogClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Tùy chỉnh số lượng đăng dự án</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Số lượng đăng dự án"
            type="number"
            fullWidth
            variant="outlined"
            value={customProjectValue}
            onChange={(e) => {
              const value = e.target.value;
              if (value >= 0) {
                setCustomProjectValue(value);
              }
            }}
            error={Boolean(error)}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCustomProjectDialogClose} color="secondary">
            Hủy
          </Button>
          <Button
            onClick={handleCustomProjectConfirm}
            color="primary"
            loading={loadingStates['customProject'] || false}
          >
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BuyProject;
