import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Table,
  Typography,
  Button,
  Input,
  Space,
  DatePicker,
  Select,
  Pagination,
  Spin,
  List,
  Divider,
} from 'antd';
import {
  SearchOutlined,
  UserOutlined,
  DollarOutlined,
  CalendarOutlined,
  FileTextOutlined,
  FrownOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import paymentApi from '../../../services/paymentApi';
import { formatDate } from '../../../utils/formatDate';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ListPaymentHistory = () => {
  const [listPayment, setListPayment] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [productType, setProductType] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        let params = {
          PageIndex: page,
          PageSize: rowsPerPage,
          UserName: '',
          Type: productType,
          Email: '',
          FromDate: dateRange[0] ? formatDate(dateRange[0].$d) : '',
          ToDate: dateRange[1] ? formatDate(dateRange[1].$d) : '',
        };

        if (searchQuery.includes('@')) {
          params.Email = searchQuery;
        } else {
          params.UserName = searchQuery;
        }
        let res = await paymentApi.getAdminTransaction(params);
        setListPayment(res.items);
        setTotalRecords(res.totalItemsCount);
      } catch (error) {
        console.error('Error fetching payment history:', error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [page, rowsPerPage, searchQuery, productType, dateRange]);

  const handleViewDetails = (payment) => {
    setSelectedPayment(payment);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedPayment(null);
  };

  const productTypes = ['Dự án', 'Dự thầu']; // No change language for this by KhanhND

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">
        <Spin size="large" />
      </div>
    );
  }

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(userName);
  };

  return (
    <div className="p-6">
      <Space className="mb-4 flex justify-between" align="center">
        <div className="flex">
          <Input
            className="mr-2"
            placeholder="Search by email or user name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            prefix={<SearchOutlined />}
          />
          <Button className="ml-2" type="primary" onClick={handleSearch}>
            Search
          </Button>
        </div>
        <div>
          <RangePicker
            value={dateRange}
            onChange={(dates) => setDateRange(dates)}
            className="mr-2"
          />
          <Select
            value={productType}
            onChange={(value) => setProductType(value)}
            placeholder="Select Product Type"
            style={{ width: 200 }}
            className="ml-2"
          >
            <Option value="">All Types</Option>
            {productTypes.map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </div>
      </Space>

      {listPayment && listPayment.length > 0 ? (
        <>
          <Table
            dataSource={listPayment}
            pagination={false}
            rowKey="id"
            bordered
            className="shadow-md rounded-lg"
          >
            <Table.Column title="Mã đơn mua" dataIndex="orderCode" />
            <Table.Column title="Tên người dùng" dataIndex="userName" />
            <Table.Column title="Email" dataIndex="email" />
            <Table.Column title="Loại hàng" dataIndex="type" />
            <Table.Column
              title="Giá trị"
              dataIndex="totalMoney"
              render={(value) => `${value.toLocaleString('vi-VN')} VND`}
            />
            <Table.Column
              title="Tên tài khoản thanh toán"
              dataIndex="counterAccountName"
            />
            <Table.Column
              title="Hành động"
              render={(payment) => (
                <Button type="link" onClick={() => handleViewDetails(payment)}>
                  Xem chi tiết
                </Button>
              )}
            />
          </Table>

          <Pagination
            total={totalRecords}
            current={page}
            pageSize={rowsPerPage}
            onChange={(newPage, pageSize) => {
              setPage(newPage);
              setRowsPerPage(pageSize);
            }}
            className="mt-4"
          />
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-64 bg-white shadow-md rounded-lg p-6">
          <FrownOutlined className="text-blue-500" style={{ fontSize: 50 }} />
          <Typography.Title level={5} className="mt-4 text-gray-700">
            Chưa có lịch sử thanh toán
          </Typography.Title>
          <Typography.Text className="text-gray-500 mt-2">
            Không có thanh toán khả dụng
          </Typography.Text>
        </div>
      )}

      <Drawer
        title="Chi tiết thanh toán"
        placement="right"
        onClose={handleDrawerClose}
        open={drawerOpen}
        width={400}
      >
        {selectedPayment && (
          <List
            itemLayout="horizontal"
            dataSource={[
              {
                title: 'Mã đơn mua',
                description: selectedPayment.orderCode,
                icon: <FileTextOutlined />,
              },
              {
                title: 'Loại hàng',
                description: selectedPayment.type,
                icon: <SelectOutlined />,
              },
              {
                title: 'Giá trị',
                description: `${selectedPayment.totalMoney.toLocaleString(
                  'vi-VN'
                )} VND`,
                icon: <DollarOutlined />,
              },
              {
                title: 'Tên người dùng',
                description: selectedPayment.userName,
                icon: <UserOutlined />,
              },
              {
                title: 'Thời gian thanh toán',
                description: formatDate(selectedPayment.transactionDateTime),
                icon: <CalendarOutlined />,
              },
            ]}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={item.icon}
                  title={item.title}
                  description={item.description}
                />
              </List.Item>
            )}
          />
        )}

        <Divider />
        <Typography.Text strong>ID người dùng</Typography.Text>
        <Typography.Paragraph>{selectedPayment?.userId}</Typography.Paragraph>
        <Typography.Text strong>Tên tài khoản thanh toán</Typography.Text>
        <Typography.Paragraph>
          {selectedPayment?.counterAccountName}
        </Typography.Paragraph>
        <Typography.Text strong>Email</Typography.Text>
        <Typography.Paragraph>{selectedPayment?.email}</Typography.Paragraph>
        <Typography.Text strong>ID ngân hàng</Typography.Text>
        <Typography.Paragraph>
          {selectedPayment?.counterAccountBankId}
        </Typography.Paragraph>
        <Typography.Text strong>Số tài khoản thanh toán</Typography.Text>
        <Typography.Paragraph>
          {selectedPayment?.counterAccountNumber}
        </Typography.Paragraph>
        <Typography.Text strong>Nội dung thanh toán chi tiết</Typography.Text>
        <Typography.Paragraph>
          {selectedPayment?.description}
        </Typography.Paragraph>
        <Typography.Text strong>Tham chiếu</Typography.Text>
        <Typography.Paragraph>
          {selectedPayment?.reference}
        </Typography.Paragraph>
      </Drawer>
    </div>
  );
};

export default ListPaymentHistory;
