import { createTheme } from '@mui/material';
import {} from '@mui/lab/themeAugmentation';
export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: ['Segoe UI', 'Montserrat', 'Josefin Sans', 'sans-serif'].join(
        ','
      ),
      fontSize: 16,
      fontWeight: '500',
    },
    button: {
      fontFamily: ['Segoe UI', 'Roboto Slab'].join(','),
      textTransform: 'none',
    },
  },
  palette: {
    bgColorPrimary: {
      main: '#f5f7f9',
    },
  },
  button: {},
  components: {
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});
