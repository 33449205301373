import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Pagination,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import projectApi from '../../../services/projectApi';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import LoadingComponent from '../../../components/LoadingComponent';
import { formatDate } from '../../../utils/formatDate';
import { toast } from 'react-toastify';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { truncateText } from '../../../utils/truncateText';
import { ROLES } from '../../../constaints/role';
import { useTranslation } from 'react-i18next';
import chatApi from '../../../services/chatApi';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const style = {
  p: 4,
  overflow: 'auto',
  maxHeight: window.innerHeight - 80,
};

const ListProjectRecruiter = () => {
  const { status } = useParams();
  const currentUser = useSelector((state) => state.auth.login?.currentUser);
  const navigate = useNavigate();
  const [listProject, setListProject] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [statusId, setStatusId] = useState(parseInt(status));
  const [reload, setReload] = useState(false);

  const [open, setOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [projectId, setProjectId] = useState(0);
  const [openReject, setOpenReject] = React.useState(false);
  const { t } = useTranslation('project');

  const handleOpenReject = (id) => {
    setProjectId(id);
    setOpenReject(true);
  };
  const handleCloseReject = () => {
    setOpenReject(false);
    setProjectId(0);
  };
  const handleReject = async () => {
    if (projectId === 0) return;
    try {
      await projectApi.RejectTesting(projectId);
      toast.success(`${t('rejected')}`);
      setReload((prev) => !prev);
      setOpenReject(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let res = await projectApi.GetAllProjectByUserId(
        currentUser?.userId,
        statusId,
        page,
        4
      );
      setListProject(res);
      setTotalPage(Math.ceil(res?.totalItemsCount / 4));
      setLoading(false);
    };
    getData();
  }, [currentUser, page, statusId, reload]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleTabChange = (newStatusId) => {
    setStatusId(newStatusId);
    setPage(1);
  };

  const handleSearch = async () => {
    setLoading(true);
    if (search === '') {
      let res = await projectApi.GetAllProjectByUserId(
        currentUser?.userId,
        statusId,
        page,
        4
      );
      setListProject(res);
      setTotalPage(Math.ceil(res?.totalItemsCount / 4));
    } else {
      let params = {
        Keyword: search,
        StatusId: statusId,
        PageIndex: 1,
        PageSize: 4,
      };
      let res = await projectApi.SearchRecruiter(params);
      setListProject(res);
      setTotalPage(Math.ceil(res?.totalItemsCount / 4));
    }
    setLoading(false);
  };

  const handleDetail = (id) => {
    navigate(`/detail/${id}`);
  };

  const handleClickOpen = (id) => {
    setSelectedProjectId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAsComplete = async () => {
    try {
      await projectApi.MakeDoneProject(selectedProjectId);
      setReload((prev) => !prev);
      toast.success(`${t('complete')}`);
      setOpen(false);
    } catch (error) {
      toast.error(`${t('error')}`);
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleCreateProject = () => {
    if (currentUser?.role === ROLES.FREELANCER) {
      navigate('/freelancer-create-project');
    }
    if (currentUser?.role === ROLES.RECRUITER) {
      navigate('/create-new-project');
    }
  };

  const handleContact = async (id) => {
    let res = await chatApi.CreateNewConversation(currentUser?.userId, id);
    navigate(`/chat/${res}/${id}`);
  };

  return (
    <Box sx={style}>
      {loading && <LoadingComponent loading={loading} />}
      <Typography sx={{ fontSize: '2em', mb: 2 }} gutterBottom>
        {t('project')}
      </Typography>
      <Box display="flex" gap={5} mb={3}>
        <TextField
          sx={{
            width: '50%',
          }}
          size="small"
          id="outlined-basic"
          label={t('search')}
          onChange={handleChange}
          variant="outlined"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          sx={{
            color: '#fff',
            fontSize: '12px',
          }}
        >
          <SearchOutlinedIcon /> {t('search')}
        </Button>
        <Box ml="auto">
          <Tooltip title={t('create_project')} arrow>
            <Button
              sx={{
                bgcolor: '#28a745',
                color: '#fff',
                fontSize: '12px',
                '&:hover': { bgcolor: '#00CC00' },
              }}
              onClick={() => handleCreateProject()}
              startIcon={<AddIcon />}
            >
              {t('create_project')}
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Box className="mb-4">
        <div className="relative w-full flex justify-left">
          <div className="absolute inset-0 flex items-center">
            <span className="w-full border-b-2 border-gray-300"></span>
          </div>
          <div className="relative flex justify-center space-x-1 bg-gray-100 rounded-full p-1 shadow">
            <button
              onClick={() => handleTabChange(1)}
              className={`px-4 py-2 rounded-full transition-all duration-300 ${
                statusId === 1
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 text-black'
              }`}
            >
              <Typography>{t('wait')} </Typography>
            </button>
            <button
              onClick={() => handleTabChange(5)}
              className={`px-4 py-2 rounded-full transition-all duration-300 ${
                statusId === 5
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 text-black'
              }`}
            >
              <Typography> {t('reject')}</Typography>
            </button>
            <button
              onClick={() => handleTabChange(2)}
              className={`px-4 py-2 rounded-full transition-all duration-300 ${
                statusId === 2
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 text-black'
              }`}
            >
              <Typography> {t('open')} </Typography>
            </button>
            <button
              onClick={() => handleTabChange(3)}
              className={`px-4 py-2 rounded-full transition-all duration-300 ${
                statusId === 3
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 text-black'
              }`}
            >
              <Typography> {t('doing_recruiter')} </Typography>
            </button>
            <button
              onClick={() => handleTabChange(9)}
              className={`px-4 py-2 rounded-full transition-all duration-300 ${
                statusId === 9
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 text-black'
              }`}
            >
              <Typography>{t('wait_check')}</Typography>
            </button>
            <button
              onClick={() => handleTabChange(6)}
              className={`px-4 py-2 rounded-full transition-all duration-300 ${
                statusId === 6
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 text-black'
              }`}
            >
              <Typography> {t('compelete')}</Typography>
            </button>
          </div>
        </div>
      </Box>
      {listProject?.items?.length > 0 ? (
        <Box>
          <Grid container spacing={2}>
            {listProject.items.map((project, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => handleDetail(project?.id)}
                >
                  <CardHeader
                    action={
                      <Chip
                        label={project?.projectStatus?.statusName}
                        color="primary"
                      />
                    }
                    title={
                      <Tooltip title={project?.title}>
                        <Typography
                          variant="h1"
                          sx={{ fontSize: '1.5em' }}
                          gutterBottom
                        >
                          {truncateText(project?.title, 30)}
                        </Typography>
                      </Tooltip>
                    }
                  />
                  <CardContent
                    sx={{ flexGrow: 1, paddingTop: 0, paddingBottom: 0 }}
                  >
                    <Typography variant="body1" component="div">
                      <strong>{t('category_project')}:</strong>{' '}
                      {project?.category?.categoryName}
                    </Typography>
                    <Typography variant="body1" component="div">
                      <strong>{t('budget')}:</strong> {project.minBudget} -{' '}
                      {project.maxBudget} VND
                    </Typography>
                    <Typography variant="body1" component="div">
                      <strong>{t('duration')}:</strong> {project.duration}{' '}
                      {t('day')}
                    </Typography>
                    <Typography variant="body1" component="div">
                      <strong>{t('create_date')}:</strong>{' '}
                      {formatDate(project.createdDate)}
                    </Typography>
                    <Typography variant="body1" component="div">
                      <strong>{t('description')}:</strong>{' '}
                      <i> {t('click_detail')}</i>
                    </Typography>
                    {project.statusId === 2 && (
                      <Typography variant="body1" component="div">
                        <strong>{t('total_bid')}:</strong>{' '}
                        <i>{project.totalBids}</i>
                      </Typography>
                    )}
                    {project.statusId === 5 && (
                      <Typography variant="body1" component="div">
                        <strong>{t('reason_reject')}:</strong>{' '}
                        <i>{t('click_detail_reject')}</i>
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions disableSpacing>
                    {project.statusId === 3 && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleContact(project?.partner?.id);
                        }}
                      >
                        {t('contact')}
                      </Button>
                    )}

                    {(project.statusId === 3 || project.statusId === 9) && (
                      <Button
                        variant="outlined"
                        color="warning"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClickOpen(project?.id);
                        }}
                        sx={{ ml: 'auto' }}
                      >
                        {t('accept_complete')}
                      </Button>
                    )}

                    {project.statusId === 9 && (
                      <Button
                        variant="outlined"
                        color="warning"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenReject(project?.id);
                        }}
                        sx={{ ml: 1 }}
                      >
                        {t('reject')}
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box className="flex justify-center mt-4">
            <Pagination
              count={totalPage}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </Box>
      ) : (
        <Typography>{t('no_project')}</Typography>
      )}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ fontSize: '1.875rem' }}>
          {t('accept_completed')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('comfirm')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleMarkAsComplete}
          >
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openReject}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseReject}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ fontSize: '1.875rem' }}>
          {t('accept_no_complete')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('comfirm_no')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleReject()}
          >
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ListProjectRecruiter;
