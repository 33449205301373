import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import profileApi from '../../../services/profileApi';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { t } = useTranslation('profile');

  const initialData = useMemo(
    () => ({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    }),
    []
  );

  const checkIfChanged = useCallback(() => {
    return (
      currentPassword !== initialData.currentPassword ||
      newPassword !== initialData.newPassword ||
      confirmPassword !== initialData.confirmPassword
    );
  }, [currentPassword, newPassword, confirmPassword, initialData]);

  useEffect(() => {
    setIsButtonDisabled(!checkIfChanged());
  }, [checkIfChanged]);

  const handleSave = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error(`${t('password_error')}`);
      return;
    }

    try {
      await profileApi.changePassword(
        currentPassword,
        newPassword,
        confirmPassword
      );
      toast.success(`${t('change_password_success')}`);
    } catch (error) {
      toast.error(`${t('change_password_error')}`);
    }
  };

  return (
    <Box
      p={3}
      m={3}
      borderRadius={5}
      border="1px solid #ccc"
      component="form"
      onSubmit={handleSave}
    >
      <Typography sx={{ fontSize: '2em' }} variant="h4" gutterBottom>
        {t('change_password')}
      </Typography>

      <Box mb={2}>
        <TextField
          label={t('password')}
          variant="outlined"
          fullWidth
          margin="normal"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          type="password"
        />
        <TextField
          label={t('new_password')}
          variant="outlined"
          fullWidth
          margin="normal"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          type="password"
        />
        <TextField
          label={t('confirm_password')}
          variant="outlined"
          fullWidth
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
        />
      </Box>

      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={isButtonDisabled}
        sx={{
          backgroundColor: isButtonDisabled ? 'gray' : 'primary.main',
          '&:hover': {
            backgroundColor: isButtonDisabled ? 'gray' : 'primary.dark',
          },
        }}
      >
        {t('save')}
      </Button>
    </Box>
  );
}

export default ChangePassword;
